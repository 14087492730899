import { useEffect, useState } from 'react';

export default function useLoadedOptions(data = [], initialState = []) {
  const [options, setOptions] = useState(initialState);
  useEffect(() => {
    if (data?.length > 0) {
      setOptions([...initialState, ...data]);
    }
  }, [data, initialState]);
  return options;
}
