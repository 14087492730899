import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CommonChart = ({ width, type, data }) => {
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (data.series && data.series.length > 0) {
      if (data.series[0].data) {
        if (data.series.length === 1) {
          let count = 0;
          for (const item of data.series[0].data) {
            if (item === 0) {
              count++;
            }
          }
          if (count === data.series[0].data.length) {
            setIsShow(false);
          } else {
            setIsShow(true);
          }
        } else {
          let count = 0;
          for (const seriseData of data.series) {
            let emptyCount = 0;
            for (const item of seriseData.data) {
              if (item === 0) {
                emptyCount++;
              }
            }
            if (emptyCount === seriseData.data.length) {
              count++;
            }
          }

          if (count === data.series.length) {
            setIsShow(false);
          } else {
            setIsShow(true);
          }
        }
      } else {
        if (data.series.length > 0) {
          let count = 0;
          for (const item of data.series) {
            if (item === 0) {
              count++;
            }
          }
          if (count === data.series.length) {
            setIsShow(false);
          } else {
            setIsShow(true);
          }
        } else {
          setIsShow(false);
        }
      }
    } else {
      setIsShow(false);
    }
  }, [data]);

  return (
    <React.Fragment>
      {!isShow && (
        <div style={{ width: width, height: 300, padding: '135px 106px' }}>Nothing to display</div>
      )}
      {isShow && type.includes('column_chart') && (
        <div>
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            width={width}
            height="300"
          />
        </div>
      )}

      {isShow && type.includes('pie_chart') && (
        <div
          style={{
            padding: '10px 0px',
            margin: 'auto'
          }}
        >
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="pie"
            width={width}
            height="250"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default CommonChart;
