import { getRequest, makeRequest } from '@utils/api';

export const createCampaign = (campaign, url) =>
  makeRequest('POST', url, {
    campaign
  });

export const updateCampaign = ({ id, ...campaign }, url) =>
  makeRequest('PATCH', url, { 
    campaign
  });
