import { UserDetailsLayout } from '@components/User/ListLayout';
import { Value } from '@components/Form/Fields/EditableValue';
import Section from '@components/Section';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';
import { withRequest } from '@containers/RequestContext';
import styles from './index.module.css';

const CampaignUsersPage = withParams(
  withRequest(({ pageSize = 25, params: { campaignId }, request: { data } }) => (
    <>
      <div className="accordion rounded-xl mt-2">
        <Section
          title={
            <>
              <div>User List</div>
              <div className={styles.userCount}>
                User Count: <b>{data?.user_count || '0'}</b>
              </div>
            </>
          }
          collapsible={false}
          initialIsOpen
          border
        >
          <UserDetailsLayout
            pageSize={pageSize}
            className="h-96"
            campaignId={campaignId}
            noButtons={true}
            to={(user) => `/users/${user?.id}`}
          />
        </Section>
      </div>
    </>
  ))
);

export default withPageTitle(CampaignUsersPage, 'Campaign Users');
