import Form from '@components/Form';
import { campaignSchema } from '@utils/validation/campaign';

const CampaignFormWrapper = ({ children, ...props }) => (
  <Form validationSchema={campaignSchema} {...props}>
    {children}
  </Form>
);

export default CampaignFormWrapper;
