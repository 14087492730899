import { PublicForm } from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import PasswordInput from '@components/Form/Fields/PasswordInput';
import { useAuthDispatch, loginAction } from '@containers/Auth';
import { loginSchema } from '@utils/validation/auth';
import { login } from '@utils/api/auth';
import { TokenError } from '@utils/api/error';

const LoginPage = () => {
  const dispatch = useAuthDispatch(),
    handleSuccess = ({ response }) => dispatch(loginAction(response)),
    handleError = (e, errorModalDispatch) => {
      if (e instanceof TokenError) {
        errorModalDispatch('Invalid email or password');
      } else {
        errorModalDispatch('An unexpected error has occurred');
      }
    };
  return (
    <PublicForm
      buttonText="Login"
      link={{ to: '/users/password/forgot', text: 'Forgot Password?' }}
      text="It's great to see you!"
      subtext="Login here"
      onSubmit={login}
      onSuccess={handleSuccess}
      onError={handleError}
      to={false}
      validationSchema={loginSchema}
    >
      <Input type="email" name="email" label="Email" placeholder="example@example.com" />
      <PasswordInput name="password" label="Password" placeholder="••••••" />
    </PublicForm>
  );
};

export default LoginPage;
