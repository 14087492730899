import { useEffect, useRef } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import useRequest from './useRequest';

export default function usePaginationRequestRouting(url, page_size, query) {
  const { search } = useLocation(),
    queryRef = useRef({
      page_size,
      ...query,
      ...parse(search, { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' })
    }),
    request = useRequest(url, undefined, true);
  useEffect(() => {
    queryRef.current = {
      page_size,
      ...query,
      ...parse(search, { parseNumbers: true, parseBooleans: true, arrayFormat: 'bracket' })
    };
  });
  useEffect(() => {
    request.makeRequest(queryRef.current);
  }, [search]);
  return { ...request, query: queryRef.current };
}
