import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '@components/Loader';
import { useToastAddAction } from '@containers/ToastContext';
import { useAuthDispatch, logoutAction } from '@containers/Auth';
import { useErrorModalDispatch } from '@containers/ErrorModal';
import { RequestError, TokenError } from '@utils/api/error';
import { getStates } from '@utils/api/state';
import LocalStorageService from '@utils/storage';
import { updateBooleanFields } from '../../utils';
const localStorageService = LocalStorageService.getService();

export default function Form({
  validationSchema,
  onSubmit,
  toast,
  onError,
  children,
  className,
  to = '..',
  defaultValues,
  url,
  onSuccess = () => { },
  ...props
}) {
  const navigate = useNavigate(),
    errorModalDispatch = useErrorModalDispatch(),
    addToast = useToastAddAction(),
    authDispatch = useAuthDispatch(),
    form = useForm({
      resolver: validationSchema && yupResolver(validationSchema),
      defaultValues
    }),
    handleSubmit = async (formValues) => {
      try {
        console.log("formValues: ", formValues) //DEBUG
        const response = await onSubmit(formValues, url, defaultValues);
        if (response) {
          onSuccess({ response, formValues, defaultValues });
          if (to) {
            if (typeof to === 'string') {
              navigate(to);
            } else if (typeof to === 'function') {
              to(navigate, response);
            }
          }
          if (toast) {
            if (localStorageService.getAccessToken()) {
              if (formValues?.state_id) {
                const states = await getStates();
                const filteredState = states.find(
                  (state) => state.id === parseInt(formValues.state_id)
                );
                if (filteredState && filteredState.active) {
                  if (typeof toast === 'string') {
                    addToast(toast);
                  } else if (typeof toast === 'function') {
                    toast(addToast, response);
                  }
                }
              }
            } else {
              if (typeof toast === 'string') {
                addToast(toast);
              } else if (typeof toast === 'function') {
                toast(addToast, response);
              }
            }
          }
        }
      } catch (e) {
        if (onError) {
          onError(e, errorModalDispatch);
        } else {
          if (e instanceof RequestError) {
            if (e.fieldErrors) {
              e.fieldErrors.forEach(({ name, type, message }) =>
                form.setError(name, { type, message })
              );
            }
            if (e.message) {
              errorModalDispatch(e.message);
            }
          } else if (e instanceof TokenError) {
            authDispatch(logoutAction());
          } else {
            errorModalDispatch('An unexpected error has occurred');
          }
        }
      }
    };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className={className} {...props}>
        {children}
      </form>
      {form.formState.isSubmitting && <Loader />}
    </FormProvider>
  );
}
