import { Outlet, NavLink } from 'react-router-dom';
import { Scroller } from '@components/State/View';
import RequestProvider from '@containers/RequestContext';
import withParams from '@containers/withParams';
import { withRequest } from '@containers/RequestContext';

export const CampaignDetails = ({campaignId}) => (
  <>
    <section className="breadcrumb mt-5">
      <h1>Campaign Details</h1>
    </section>
    <RequestProvider url={`/campaigns/${campaignId}.json`}>
      <div className="lg:flex lg:-mx-4">
        <div className="w-full lg:px-4">
          <div className="card p-5">
            <div className="tabs">
              <nav className="tab-nav mt-5">
                <NavLink to="profile" className="nav-link h5 uppercase" activeclassname="active">
                  Profile
                </NavLink>
                <NavLink to="users" className="nav-link h5 uppercase">
                  Users
                </NavLink>
              </nav>
              <div className="tab-content mt-2">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RequestProvider>
  </>
);

const CampaignDetailsLayout = withParams(({ params: { campaignId } }) => (
  <Scroller check={campaignId}>
    <CampaignDetails campaignId={campaignId} />
  </Scroller>
));

export default CampaignDetailsLayout;
