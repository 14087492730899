import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import withField from '@components/Form/Fields/withField';
import styles from './index.module.css';

export const DateInputComponent = ({
  onChange,
  placeholderText = 'MM-DD-YYYY',
  value = null,
  ...props
}) => {
  return (
    <div className={styles.container}>
      <DatePicker
        selected={new Date(value)}
        onChange={onChange}
        placeholderText={placeholderText}
        dateFormat="yyyy-MM-dd"
        {...props}
      />
    </div>
  );
};

export const DateInput = ({ name, ...props }) => (
  <Controller name={name} render={({ field }) => <DateInputComponent {...field} {...props} />} />
);
const DateInputField = withField(DateInput);

export default DateInputField;
