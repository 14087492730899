import Input from '@components/Form/Fields/Input';
import withField from '@components/Form/Fields/withField';

export const Value = withField(
  ({ children, hideIfEmpty }) => (
    <div className="py-2">{children || (hideIfEmpty ? '' : 'N/A')}</div>
  ),
  undefined,
  true
);

const EditableValue = ({
  isEditing,
  value,
  hideIfEmpty,
  defaultValue,
  as: Component = Input,
  ...props
}) =>
  isEditing ? (
    <Component defaultValue={defaultValue || value} {...props} />
  ) : (
    <Value {...props} hideIfEmpty={hideIfEmpty}>
      {value}
    </Value>
  );

export default EditableValue;
