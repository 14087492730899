import { getRequest, makeRequest } from '@utils/api';

const multipartHeaders = {
  'Content-type': 'multipart/form-data'
};
export const createBusiness = (business, url) =>
  makeRequest('POST', url, {
    business
  });

export const updateBusiness = ({ id, account_active, legalease_partner, ...business }, url) =>
  makeRequest('PATCH', url, {
    // business: {
    //   ...business,
    //   account_active: account_active === "true" ? true : false
    // }
    business: {
      ...business,
      legalease_partner: legalease_partner === "true" ? true : false
    }
  });

const makeIdArray = ({ id }) => Number(id);

const makeBusinessMarketProperties = (state, business) => {
  const stateIdx = business.covered_states.findIndex((s) => s.id === state.market_state_id);
  var obj = {};
  if (stateIdx !== -1 && state.market_state_active !== 'true') {
    obj.state_ids = business.covered_states
      .filter((s) => s.id !== state.market_state_id)
      .map(makeIdArray);
  } else if (stateIdx === -1 && state.market_state_active === 'true') {
    obj.state_ids = [...business.covered_states.map(makeIdArray), state.market_state_id];
  }

  // const activeCounties = state.counties_attributes.filter(c => c.active === "true"),
  //       inactiveCounties = state.counties_attributes.filter(c => c.active !== "true")
  //
  // obj.selected_county_ids = business.counties_attributes.filter(c => inactiveCounties.findIndex(i => Number(i.id) === c.id) === -1);
  //
  // for (var i = 0; i < activeCounties.length; i++) {
  //   const id = Number(activeCounties[i].id);
  //   if(obj.selected_county_ids.findIndex(c => c.id === id) === -1){
  //     obj.selected_county_ids.push(activeCounties[i])
  //   }
  // }
  // obj.selected_county_ids = obj.selected_county_ids.map(makeIdArray)
  return obj;
};
export const updateBusinessMarkets = (state, url, business) =>
  makeRequest('PATCH', url, {
    business: makeBusinessMarketProperties(state, business)
  });

export const getBusinessNamesRequest = (endpoint) => getRequest(endpoint, 'GET');

export const generateSignupLink = (data, url) => makeRequest('POST', url, data);

export const deactiveSignupLink = (data, url) => makeRequest('PATCH', url, data);

export const importBusinessSignUpLinkData = (data, url) => makeRequest('POST', url, data, multipartHeaders);
export const reinviteUsers = (url) => makeRequest('POST', url);