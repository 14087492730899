import { useEffect, useCallback } from 'react';

export default function KeyListener({ onPress = () => {}, keys = [] }) {
  const handleKeyUp = useCallback((e) => keys.indexOf(e.which) >= 0 && onPress(e), [onPress, keys]);
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);
  return null;
}
