import { debounce } from '@utils';

const TextFilter = ({ value, onChange }) => {
  const handleChange = debounce((e) => {
    onChange(e.target.value);
  }, 500);
  return (
    <label className="ml-1 lg:ml-2 mt-5 lg:mt-0 form-control-addon-within rounded-full border-secondary">
      <input
        type="text"
        className="form-control border-none"
        placeholder="Search"
        onChange={handleChange}
      />
      <button
        type="button"
        className="btn btn-link text-secondary dark:text-gray-700 hover:text-primary dark:hover:text-primary text-xl leading-none la la-search mr-4"
      ></button>
    </label>
  );
};

export default TextFilter;
