import { Outlet, NavLink, Link } from 'react-router-dom';
import { Scroller } from '@components/State/View';
import RequestProvider, { withRequest } from '@containers/RequestContext';
import withParams from '@containers/withParams';

const NonAdminLinks = withRequest(({ request: { data } }) =>
  data && !data.is_admin ? (
    <>
      <NavLink to="incidents" className="nav-link h5 uppercase">
        Incidents
      </NavLink>
    </>
  ) : null
);

const UserDetailsLayout = withParams(({ params: { userId }, businessId }) => (
  <Scroller check={userId}>
    <section className="breadcrumb mt-5">
      <h1>User Details</h1>
    </section>
    <RequestProvider url={`/users/${userId}.json`}>
      <div className="lg:flex lg:-mx-4">
        <div className="w-full lg:px-4">
          <div className="card p-5">
            <div className="tabs">
              <nav className="tab-nav mt-5">
                <NavLink to="profile" className="nav-link h5 uppercase" activeclassname="active">
                  Profile
                </NavLink>
                {!businessId && <NonAdminLinks />}
                <NavLink to="events" className="nav-link h5 uppercase">
                  Events
                </NavLink>
              </nav>
              <div className="tab-content mt-2">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RequestProvider>
  </Scroller>
));

export default UserDetailsLayout;
