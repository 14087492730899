import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import Button from '@components/Button';

import { useAuthState, useAuthDispatch, logoutAction } from '@containers/Auth';
import workerScript from './worker'

const IdleCheckModal = () => {
  const countDown = 300;
  // const countDown = 60;
  const [open, setOpen] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const [timeLeft, setTimeLeft] = useState(countDown);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const user = useAuthState();
  const dispatch = useAuthDispatch();

  const handleTimerExpire = () => {
    sessionStorage.clear();
    localStorage.clear();
    setLoggedOut(true);
  }


  const logoutAndRedirect = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  }

  useEffect(() => {

    let worker = new Worker(workerScript);
    worker.postMessage({ user: user, msg: "From main thread" });
    worker.onmessage = (e) => {

      if (e.data == "setOpen") {
        setOpen(true)
      }
    }

    const handleActivity = () => {
      if (!open) {
        worker.postMessage({ user: user, msg: "Reset" });
      }
    };

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      worker.terminate();
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [open]);

  useEffect(() => {
    let countdownTimer;

    if (open) {
      countdownTimer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const nextTimeLeft = prevTimeLeft - 1;
          if (nextTimeLeft < 1) {
            clearInterval(countdownTimer);
            handleTimerExpire();
            return 0;
          }
          return nextTimeLeft;
        });
      }, 1000);
    } else {
      setTimeLeft(countDown);
      if (document.querySelector('#countDown')) {
        document.querySelector('#countDown').style.visibility = 'hidden'
      }
    }

    return () => clearInterval(countdownTimer);
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogContent style={{ textAlign: "center", paddingBottom: "20px", maxWidth: "450px" }}>
        <div
          style={{
            fontWeight: "bold",
            marginBottom: "20px",
            borderBottom: "4px solid rgba(247, 212, 67, var(--tw-text-opacity))",
          }}
        >
          {
            loggedOut ?
              <h2>Session Expired</h2>
              :
              <h2>Are you still there?</h2>
          }
        </div>
        <h1
          id="countDown"
          style={{
            fontSize: "1.5em",
            fontWeight: "bold",
            display: "block",
            marginBottom: "20px",
            color: "#000000",
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </h1>
        {loggedOut ?
          <DialogContentText>
            Your session has expired.  Please login to continue.
          </DialogContentText>
          :
          <DialogContentText>
            You have been inactive for a few minutes. Would you like to
            continue? Reply within the next 5 minutes or your session will expire.
          </DialogContentText>
        }
      </DialogContent>
      {loggedOut ?
        <DialogActions style={{ justifyContent: "center", marginBottom: "30px" }}>
          <Button type="submit" primary
            onClick={() => {
              setLoggedOut(true);
              window.location.reload();
            }}
            className=""
          >
            Ok
          </Button>

        </DialogActions>
        :
        <DialogActions style={{ justifyContent: "center", marginBottom: "30px" }}>
          <Button type="submit" primary
            onClick={() => {
              setOpen(false);
            }}
            className=""
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              logoutAndRedirect();
            }}
          >
            I am done!
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
};

export default IdleCheckModal;