import { forwardRef, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import { classNames } from '@utils';

const PasswordInputComponent = forwardRef(({ className, type, ...props }, ref) => {
  const [isShowing, setIsShowing] = useState(),
    toggleShowing = () => setIsShowing((prevIsShowing) => !prevIsShowing);
  return (
    <label className="form-control-addon-within">
      <input
        {...props}
        type={isShowing ? 'text' : 'password'}
        ref={ref}
        className="form-control border-none"
      />
      <span className="flex items-center pr-4">
        <button
          type="button"
          className={classNames(
            'btn btn-link la la-eye text-xl leading-none',
            isShowing ? 'text-primary dark:text-primary' : 'text-gray-600 dark:text-gray-600'
          )}
          onClick={toggleShowing}
        ></button>
      </span>
    </label>
  );
});
export const PasswordInput = ({ as: Component = PasswordInputComponent, type, ...props }) => {
  const { register } = useFormContext();
  return <Component {...props} {...register(props.name)} />;
};

export const ControlledPasswordInputComponent = ({
  as: PassedInputComponent = PasswordInputComponent,
  name,
  ...props
}) => (
  <Controller name={name} render={({ field }) => <PassedInputComponent {...field} {...props} />} />
);
export const ControlledPasswordInput = withField(ControlledPasswordInputComponent);

const PasswordInputField = withField(PasswordInput);

export default PasswordInputField;
