import { useState, useCallback, useEffect, useRef } from 'react';
import CampaignList from '@components/Campaign/List';
import { Outlet } from 'react-router-dom';
import FilterRow from '@components/Filter/Row';
import Button from '@components/Button';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';
import { classNames } from '@utils';
import styles from './index.module.css';
import Modal from '@components/Modal';
import Input from '@components/Form/Fields/Input';
import CampaignFormWrapper from '@components/Campaign/Form';
import { createCampaign } from '@utils/api/campaign';
import { useFormContext } from 'react-hook-form';

const SwitchWithFormContext = (props) => {
  const { register } = useFormContext();
  return <input name={props.name} type="checkbox" {...register(props.name)} />;
};
export const AddCampaignModalButton = ({ onSuccess = () => { } }) => {
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    handleSuccess = () => {
      onSuccess();
      handleClose();
    },
    handleTo = (nav, res) => {
      nav(res.id + '/profile');
    };

  return (
    <>
      <Button onClick={handleOpen} primary className="ml-2">
        Add New
      </Button>
      <Modal
        component={CampaignFormWrapper}
        isOpen={isOpen}
        title="Add Campaign"
        url="/campaigns.json"
        onSubmit={createCampaign}
        onSuccess={handleSuccess}
        close={handleClose}
        toast="Campaign has been added"
        to={handleTo}
        form
      >
        <Input name="name" label="Internal Name" placeholder="Name" required />
        <Input name="title" label="Social Media Sharing Title" placeholder="Title" required />
        <Input name="message" label="Social Media Sharing Message" placeholder="Message" required />
        <Input name="promo_code" label="Promotion Code" placeholder="Promo Code" />
        <label className="label block mb-2">
          {'Paid only'}
        </label>
        <label className="switch switch_outlined">
          <SwitchWithFormContext
            name="paid_only"
          />
          <span></span>
        </label>
      </Modal>
    </>
  );
};

const CampaignListPage = ({ pageSize = 25, title = 'Campaigns', url = '/campaigns.json' }) => {
  const request = usePaginationRequest('campaigns', url, pageSize);
  return (
    <>
      <section className="breadcrumb lg:flex items-start">
        {title && <h1>{title}</h1>}
        <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
          <FilterRow>
            <AddCampaignModalButton />
          </FilterRow>
        </div>
      </section>
      <div className={classNames('card p-5 flex-shrink-0 overflow-x-auto', styles.listWrapper)}>
        <CampaignList {...request} />
      </div>
      <Outlet />
    </>
  );
};

export default withPageTitle(CampaignListPage, 'Campaigns');
