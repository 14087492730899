import { useState, useEffect, useRef } from 'react';
import { stringify } from 'query-string';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import UserList from '@components/User/List';
import { UserFormWrapper, UserFields } from '@components/User/Form';
import Loader from '@components/Loader';
import Modal from '@components/Modal';
import Button from '@components/Button';
import Pagination from '@components/Pagination';
import usePaginationRequestRouting from '@containers/usePaginationRequestRouting';
import withPageTitle from '@containers/withPageTitle';
import { exportUsers } from '@utils/api/user';
import { classNames } from '@utils';
import { createUser, createLead } from '@utils/api/user';
import { getStates } from '@utils/api/state';
import { useToastAddAction } from '@containers/ToastContext';
import { useAuthState } from '@containers/Auth';

const makeUserInviteDefaultValues = (businessId, role) => {
  const obj = { business_active: 'true' };
  if (businessId) {
    obj.business_id = businessId;
  }
  if (role) {
    obj.role = role;
  }
  return obj;
};

export const UserInviteModalButton = ({ businessId, role, onSuccess = () => { } }) => {
  const addToast = useToastAddAction();
  const loginUser = useAuthState();
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    handleSuccess = ({ response }) => {
      onSuccess(response);
      handleClose();
    };

  const defaultTo = (nav, res) => {
    if (res) {
      if (res.id) {
        nav(res.id + '');
      } else {
        if (loginUser.is_org_admin) {
          return;
        } else {
          nav('/leads');
        }
      }
    }
  };
  const sendInvite = async (user) => {
    const states = await getStates();
    const filteredState = states.find((state) => state.id === parseInt(user.state_id));
    if (filteredState && !filteredState.active && user.role !== 'Attorney' && user.role != 'Attorney Admin') {
      const res = await createLead(user);
      addToast(
        'Invites are sent to Leads when inviting a user to an unsupported state'
      );
      return true;
    } else {
      const res = await createUser(user);
      return res;
    }
  };
  return (
    <>
      <Button onClick={handleOpen} primary className="ml-2 my-1">
        Invite
      </Button>
      <Modal
        component={UserFormWrapper}
        isOpen={isOpen}
        title="Invite User"
        close={handleClose}
        url="/user/lawyer.json"
        onSubmit={sendInvite}
        onSuccess={handleSuccess}
        defaultValues={makeUserInviteDefaultValues(businessId, role)}
        toast={'User invite has been sent'}
        to={defaultTo}
        form
      >
        <UserFields businessId={businessId} role={role} />
      </Modal>
    </>
  );
};
const roleLabelMap = {
  all: 'All Roles',
  admin: 'Admin',
  lawyer: 'Attorney',
  client: 'Client'
};
const roleArray = ['all', 'admin', 'lawyer', 'client'];

export const ExportDataButton = ({ businessId }) => {
  const [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    handleClick = async () => {
      setLoading(true);
      const csvFile = await exportUsers({ business_id: businessId });
      setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      setLoading(false);
    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
      setFileLink('');
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleClick} type="button" secondary outlined className="ml-2 my-1">
        Export
      </Button>
      <a href={fileLink} ref={linkRef} download="turnsignl-users.csv" className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};
const makeOrderLink = ({ role, page, ...query }, asc, desc, isAsc, isDesc) => {
  const newQuery = { ...query };
  if (isAsc) {
    newQuery.order = [desc];
  } else if (isDesc) {
    newQuery.order = [];
  } else {
    newQuery.order = [asc];
  }
  return `?${stringify(newQuery, { arrayFormat: 'bracket' })}`;
};

const OrderFilter = ({ alphabetical, query, order, label, left, isDefault }) => {
  const [isHovering, setIsHovering] = useState(),
    descParamKey = `${order} desc`,
    isAsc = query.order ? query.order.find((o) => o === order) : isDefault,
    isDesc = query?.order?.find((o) => o === descParamKey);
  return (
    <Link
      to={makeOrderLink(query, order, descParamKey, isAsc, isDesc)}
      className={classNames('flex items-center', !left && 'justify-center')}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <div className="relative">
        {label}
        {(isAsc || isDesc || isHovering) && (
          <span
            className={classNames(
              'ml-1 inline-block la absolute left-full text-lg -top-1.5',
              isDesc
                ? `la-sort-${alphabetical ? 'alpha' : 'amount'}-down`
                : `la-sort-${alphabetical ? 'alpha' : 'amount'}-up`
            )}
          ></span>
        )}
      </div>
    </Link>
  );
};
const UserListContainer = ({ users, query }) => (
  <UserList
    users={users}
    cols={[
      <OrderFilter alphabetical label="Last Name" order="users.last_name" query={query} />,
      <OrderFilter left alphabetical label="First Name" order="users.first_name" query={query} />,
      'Role',
      <OrderFilter alphabetical label="Email" order="users.email" query={query} />,
      'State',
      'Status',
      'Sub-Status',
      'Affiliation'
    ]}
  />
);

const RoleFilter = ({ query: { role, page, ...restOfQuery }, isActive, label, value }) => (
  <Link
    to={`?${stringify(value === 'all' ? restOfQuery : { ...restOfQuery, role: value })}`}
    className={classNames('item px-2 py-1', isActive && 'active')}
  >
    {label}
  </Link>
);

export const UserHeader = ({ title, query }) => (
  <section className="breadcrumb lg:flex items-start">
    {title}
    <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
      <div className="flex mt-5 lg:mt-0">
        <div className="dropdown ml-1 lg:ml-2">
          <Tippy
            content={
              <div className="dropdown-menu">
                {roleArray.map((role) => (
                  <RoleFilter
                    key={role}
                    query={query}
                    label={roleLabelMap[role]}
                    value={role}
                    isActive={query.role ? query.role === role : role === 'all'}
                  />
                ))}
              </div>
            }
            theme="light-border"
            zIndex={25}
            offset={[0, 8]}
            arrow={false}
            placement="bottom-start"
            allowHTML
            interactive
            animation="shift-toward-extreme"
          >
            <Button outlined secondary>
              {roleLabelMap[query.role || 'all']}
              <span className="ml-3 la la-caret-down text-xl leading-none"></span>
            </Button>
          </Tippy>
        </div>
        <UserInviteModalButton />
        <ExportDataButton />
      </div>
    </div>
  </section>
);
const UserListPageLayout = ({ title, query, data, status, error, setPage, ...props }) => (
  <>
    <UserHeader query={query} title={title} />
    <Pagination query={query} status={status} error={error} data={data}>
      <UserListContainer {...props} query={query} users={data?.users} />
    </Pagination>
  </>
);

export const UserListPageContainer = ({ url = '/users.json', ...props }) => {
  const pagination = usePaginationRequestRouting(url, 50);
  return (
    <UserListPageLayout
      {...props}
      query={pagination.query}
      status={pagination.status}
      error={pagination.error}
      data={pagination.data}
      setPage={pagination.setPage}
    />
  );
};

const UserListPage = () => (
  <UserListPageContainer
    title={
      <div>
        <h1>Users</h1>
      </div>
    }
  />
);

export default withPageTitle(UserListPage, 'Users');
