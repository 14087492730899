import { Link } from 'react-router-dom';
import Form from '@components/Form';
import Button from '@components/Button';
import styles from './index.module.css';
import logo from '@assets/images/TurnSignl_TurnSignl-Logo-White.svg';
import { classNames } from '@utils';

const PublicLayout = ({
  as: WrapperComponent = 'div',
  children,
  text,
  subtext,
  className,
  ...props
}) => {
  return (
    <div className="container flex items-center justify-center mt-20 py-10">
      <div className="w-full md:w-1/2 xl:w-1/3">
        {(text || subtext) && (
          <div className="mx-5 md:mx-10">
            {text && <h2 className="uppercase">{text}</h2>}
            {subtext && <h4 className="uppercase">{subtext}</h4>}
          </div>
        )}
        {(subtext ||
          props.defaultValues?.reset_password_token ||
          props.defaultValues?.invitation_token) && (
          <WrapperComponent {...props} className={classNames('card mt-5 p-5 md:p-10', className)}>
            {children}
          </WrapperComponent>
        )}
      </div>
    </div>
  );
};

export const PublicForm = ({ buttonText = 'Submit', children, link, ...props }) => {
  return (
    <PublicLayout {...props} as={Form}>
      {children}
      <div className="flex items-center">
        {link && (
          <Link to={link.to} className="text-sm uppercase">
            {link.text}
          </Link>
        )}
        <Button type="submit" primary className="ml-auto">
          {buttonText}
        </Button>
      </div>
    </PublicLayout>
  );
};

export const PublicHeader = ({ title, text, children, margin }) => (
  <header className={styles.header}>
    <Link to="/">
      <img src={logo} alt="TurnSignl Small Logo" className={styles.logo} />
    </Link>
    <div className={margin ? styles.margin : undefined}>
      <div className={styles.title}>{title}</div>
      {text && <p>{text}</p>}
    </div>

    {children}
  </header>
);

export default PublicLayout;
