import { PublicForm } from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import PasswordInput from '@components/Form/Fields/PasswordInput';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import { passwordSchema, invitePageValidation } from '@utils/validation/auth';
import { acceptInvitation } from '@utils/api/auth';

const InvitationConfirmation = ({ query }) => (
  <PublicForm
    buttonText="Set Password"
    to={(navigate, { role }) => {
      navigate(role === 'admin' ? '/login' : '/get_app');
    }}
    toast={(addToast, { role }) => {
      addToast(
        role === 'admin'
          ? 'Your password has been set, you may now login'
          : 'Your password has been set, please download the mobile app to login'
      );
    }}
    onSubmit={acceptInvitation}
    defaultValues={{ invitation_token: query?.invitation_token }}
    validationSchema={passwordSchema}
  >
    <Input name="invitation_token" className="hidden" />
    <PasswordInput name="password" secondary placeholder="Password" />
    <PasswordInput name="password_confirmation" secondary placeholder="Confirm Password" />
  </PublicForm>
);

export default withQueryStringValidation(InvitationConfirmation, invitePageValidation, '/login');
