import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import Table from '@components/Table';
import { RequestLoader } from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import useAccessorFunction from '@containers/useAccessorFunction';
import usePaginationRequest from '@containers/usePaginationRequest';

const useInfiniteLoaderProps = (rows, status, hasNextPage, nextPage) => {
  const rowCount = hasNextPage ? rows.length + 1 : rows.length,
    isRowLoaded = ({ index }) => !hasNextPage || index < rows.length;

  return {
    infiniteLoaderProps: {
      rowCount,
      loadMoreRows: hasNextPage && status !== 'loading' ? nextPage : () => { },
      isRowLoaded
    },
    listProps: {
      rowCount
    }
  };
};

const InfiniteLoaderComponent = ({ status, rows, page, pageCount, nextPage, ...props }) => {
  const { infiniteLoaderProps, listProps } = useInfiniteLoaderProps(
    rows,
    status,
    page < pageCount,
    nextPage
  );

  return (
    <InfiniteLoader {...infiniteLoaderProps}>
      {(list) => (
        <Table
          {...props}
          {...listProps}
          rows={rows}
          ref={list.registerChild}
          onRowsRendered={list.onRowsRendered}
        />
      )}
    </InfiniteLoader>
  );
};

export const InfiniteLoaderContainer = ({
  data,
  status,
  error,
  page,
  pageCount,
  listAccessor = ({ items }) => items,
  setPage,
  query,
  ...props
}) => {
  const listAccessorFunc = useAccessorFunction(listAccessor);
  if (!data && status === 'loading') {
    return <RequestLoader />;
  }
  if (status === 'error') {
    return <ErrorDisplay error={error} />;
  }

  return (
    <InfiniteLoaderComponent
      {...props}
      page={page}
      pageCount={pageCount}
      rows={listAccessorFunc(data)}
      status={status}
      nextPage={() => {
        setPage(page + 1, query);
      }}
    />
  );
};
export default function InfiniteLoaderExport({ pageSize = 25, url, ...props }) {
  const request = usePaginationRequest(url, pageSize);
  return <InfiniteLoaderContainer {...props} {...request} />;
}
