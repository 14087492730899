import { Link } from 'react-router-dom';
import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import { getFormattedDate, getTime, getDuration } from '@utils';

const incidentTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.started_at) : ''),
    key: 'started_at'
  },
  {
    label: 'Start Time',
    cellDataGetter: (cell) => (cell?.rowData?.started_at ? getTime(cell.rowData.started_at) : ''),
    key: 'start_time',
    disableSort: true
  },
  {
    label: 'End Time',
    cellDataGetter: (cell) => (cell?.rowData?.ended_at ? getTime(cell.rowData.ended_at) : ''),
    key: 'end_time',
    disableSort: true
  },
  {
    label: 'Duration',
    cellDataGetter: (cell) =>
      cell?.rowData?.ended_at ? getDuration(cell.rowData.started_at, cell.rowData.ended_at) : '',
    key: 'duration',
    disableSort: true
  },
  {
    label: 'Type',
    cellDataGetter: (cell) =>
      cell?.rowData
        ? cell.rowData.incident_type === 'traffic_stop'
          ? 'Traffic Stop'
          : cell.rowData.incident_type
        : '',
    key: 'incident_type'
  },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.state?.abbrev ? cell.rowData.state.abbrev : ''),
    key: 'state_id'
  },
  {
    label: 'Attorney Name',
    cellRenderer: (cell) =>
      cell?.rowData?.lawyer_name ? (
        <Link to={`/users/${cell.rowData.lawyer?.user?.id}`}>{cell.rowData.lawyer_name}</Link>
      ) : (
        ''
      ),
    key: 'attorney'
  },
  {
    label: 'Follow-Up',
    cellDataGetter: (cell) => (cell?.rowData ? (cell.rowData.needs_followup ? 'Yes' : 'No') : ''),
    key: 'followup',
    disableSort: true
  }
];
const lawyerIncidentTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.started_at) : ''),
    key: 'started_at'
  },
  {
    label: 'Start Time',
    cellDataGetter: (cell) => (cell?.rowData?.started_at ? getTime(cell.rowData.started_at) : ''),
    key: 'start_time',
    disableSort: true
  },
  {
    label: 'End Time',
    cellDataGetter: (cell) => (cell?.rowData?.ended_at ? getTime(cell.rowData.ended_at) : ''),
    key: 'end_time',
    disableSort: true
  },
  {
    label: 'Duration',
    cellDataGetter: (cell) =>
      cell?.rowData?.ended_at ? getDuration(cell.rowData.started_at, cell.rowData.ended_at) : '',
    key: 'duration',
    disableSort: true
  },
  {
    label: 'Type',
    cellDataGetter: (cell) =>
      cell?.rowData
        ? cell.rowData.incident_type === 'traffic_stop'
          ? 'Traffic Stop'
          : cell.rowData.incident_type
        : '',
    key: 'incident_type'
  },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.state?.abbrev ? cell.rowData.state.abbrev : ''),
    key: 'state_id'
  },
  {
    label: 'Client Name',
    cellRenderer: (cell) =>
      cell?.rowData?.user_name ? (
        <Link to={`/users/${cell.rowData.user_id}`}>{cell.rowData.user_name}</Link>
      ) : (
        ''
      ),
    key: 'attorney'
  },
  {
    label: 'Follow-Up',
    cellDataGetter: (cell) => (cell?.rowData ? (cell.rowData.needs_followup ? 'Yes' : 'No') : ''),
    key: 'followup',
    disableSort: true
  }
];
const clientLawyerTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.started_at) : ''),
    key: 'started_at'
  },
  {
    label: 'Start Time',
    cellDataGetter: (cell) => (cell?.rowData?.started_at ? getTime(cell.rowData.started_at) : ''),
    key: 'start_time',
    disableSort: true
  },
  {
    label: 'End Time',
    cellDataGetter: (cell) => (cell?.rowData?.ended_at ? getTime(cell.rowData.ended_at) : ''),
    key: 'end_time',
    disableSort: true
  },
  {
    label: 'Duration',
    cellDataGetter: (cell) =>
      cell?.rowData?.ended_at ? getDuration(cell.rowData.started_at, cell.rowData.ended_at) : '',
    key: 'duration',
    disableSort: true
  },
  {
    label: 'Type',
    cellDataGetter: (cell) =>
      cell?.rowData
        ? cell.rowData.incident_type === 'traffic_stop'
          ? 'Traffic Stop'
          : cell.rowData.incident_type
        : '',
    key: 'incident_type'
  },
  {
    label: 'Client Name',
    cellDataGetter: (cell) => (cell?.rowData?.user_name ? cell.rowData.user_name : ''),
    cellRenderer: (cell) =>
      cell?.rowData?.user_name ? (
        <Link to={`/users/${cell.rowData.user_id}`}>{cell.rowData.user_name}</Link>
      ) : (
        ''
      ),
    key: 'client',
    disableSort: true
  },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.state?.abbrev ? cell.rowData.state.abbrev : ''),
    key: 'state_id'
  },
  {
    label: 'Attorney Name',
    cellDataGetter: (cell) => (cell?.rowData?.lawyer_name ? cell.rowData.lawyer_name : ''),
    cellRenderer: (cell) =>
      cell?.rowData?.lawyer_name ? (
        <Link to={`/users/${cell.rowData.lawyer?.user?.id}`}>{cell.rowData.lawyer_name}</Link>
      ) : (
        ''
      ),
    key: 'attorney',
    disableSort: true
  },
  {
    label: 'Follow-Up',
    cellDataGetter: (cell) => (cell?.rowData ? (cell.rowData.needs_followup ? 'Yes' : 'No') : ''),
    key: 'followup',
    disableSort: true
  }
];
const IncidentsList = ({
  showLawyerOnly,
  showClientOnly,
  listAccessor = ({ incidents }) => incidents,
  ...props
}) => {
  var cols;
  if (showLawyerOnly) {
    cols = incidentTableCols;
  } else if (showClientOnly) {
    cols = lawyerIncidentTableCols;
  } else {
    cols = clientLawyerTableCols;
  }
  return <InfiniteLoaderContainer {...props} listAccessor={listAccessor} cols={cols} hideButton />;
};

export default IncidentsList;
