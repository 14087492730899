import { useCallback, useEffect } from 'react';
import Shell from '@components/Shell';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import { useToastAddAction } from '@containers/ToastContext';
import { confirmPageValidation } from '@utils/validation/auth';

const ConfirmationPage = ({ query, navigate }) => {
  const addToast = useToastAddAction(),
    startRequest = useCallback(async () => {
      await fetch(
        `${process.env.REACT_APP_API_URL}/users/confirmation.json?confirmation_token=${query?.confirmation_token}`
      );
      navigate('/get_app');
      addToast('Your account has been confirmed, you can now login through our mobile app');
      // const {role} = await fetch(`${process.env.REACT_APP_API_URL}/users/confirmation.json?confirmation_token=${query?.confirmation_token}`)
      // if(role === "admin"){
      //   navigate("/login");
      //   addToast("Your account has been confirmed, you can now login");
      // } else {
      //   navigate("/get_app");
      //   addToast("Your account has been confirmed, you can now login through our mobile app");
      // }
    }, [navigate, addToast, query]);

  useEffect(() => {
    startRequest();
  }, []);
  return <Shell />;
};

export default withQueryStringValidation(ConfirmationPage, confirmPageValidation, '/login');
