import Table from '@components/Table';

const marketTableCols = [
  // { label: 'ID', cellDataGetter: (cell) => (cell?.rowData?.id ? cell.rowData.id : ''), key: 'id' },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.abbrev ? cell.rowData.abbrev : ''),
    key: 'abbrev'
  },
  {
    label: 'Description',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : ''),
    key: 'name'
  },
  {
    label: 'Status',
    cellDataGetter: (cell) =>
      cell?.rowData?.id ? (cell.rowData.active ? 'Active' : 'Inactive') : '',
    key: 'active'
  }
];
const StatesList = ({ states, cols = marketTableCols, ...props }) => (
  <Table {...props} rows={states} cols={cols} />
);

export default StatesList;
