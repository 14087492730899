import { useRef, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Form from '@components/Form';
import InputField, { Input } from '@components/Form/Fields/Input';
import Button from '@components/Button';
import SplitRow from '@components/SplitRow';
import Select from '@components/Form/Fields/Select';
import Radio from '@components/Form/Fields/Radio';
import SearchSelect from '@components/Form/Fields/SearchSelect';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import StateSelect from '@containers/StateSelect';
import ResourceSelect from '@containers/ResourceSelect';
import PracticeSelect from '@containers/PracticeSelect';
import useRequest from '@containers/useRequest';
import { userSchema } from '@utils/validation/user';
import { useAuthState } from '@containers/Auth';

const AttorneyFields = ({ roleName, businessIdName, businesses }) => {
  const [role, business_id] = useWatch({
    name: [roleName, businessIdName]
  }),
    isAttorney = role === 'Attorney',
    isLawFirmOrgAdmin = role === 'Attorney Admin';

  if (isAttorney || isLawFirmOrgAdmin) {
    return (
      <>
        <hr />
        <h3 className="py-5">Attorney Information{isLawFirmOrgAdmin && ' (Optional)'}</h3>
        <PhoneNumberInput label="Phone" name="phone" placeholder="Enter Phone Number" required />
        <PracticeSelect
          label="Practice"
          root="lawyer_attributes"
          placeholder="Select Practice..."
        />
        <StateSelect
          as={SearchSelect}
          label="States"
          root="lawyer_attributes"
          name="state_ids"
          placeholder="Select States..."
          isMulti
          placement="top-start"
        />
      </>
    );
  }
  return null;
};
const StateField = ({ roleName }) => {
  const role = useWatch({
    name: roleName
  });

  if (role && role !== 'Admin') {
    return (
      <StateSelect
        label="State Of Residence"
        name="state_id"
        placeholder="Select State Of Residence..."
      />
    );
  }
  return null;
};
// const ClientFields = ({roleName}) => {
//   const role = useWatch({
//     name: roleName
//   });
//   if(role === "Client"){
//     return(<>
//       <StateSelect label="State" name="state_id" placeholder="Select State..." required />
//       <hr />
//       <h3 className="py-5">Vehicle Information</h3>
//       <AutocapitalizeInput label="Make" name="vehicle_attributes[make]" placeholder="Make" />
//       <AutocapitalizeInput label="Model" name="vehicle_attributes[model]" placeholder="Model" />
//       <InputField label="Year" type="number" name="vehicle_attributes[year]" placeholder="Year" />
//       <AutocapitalizeInput label="Color" name="vehicle_attributes[color]" placeholder="Color" />
//     </>)
//   }
//   return null
// }

export const lawFirmSelector = (response) =>
  response?.business_names?.filter(({ is_law_firm }) => is_law_firm);
export const nonLawFirmSelector = (response) =>
  response?.business_names?.filter(({ is_law_firm }) => !is_law_firm);

const getBusinessesSelectorByRole = (role) => {
  switch (role) {
    case 'Attorney':
    case 'Attorney Admin':
      return (response) => (response?.business_names?.length > 0 ? lawFirmSelector(response) : []);
    default:
      return (response) => (response?.business_names?.length > 0 ? nonLawFirmSelector(response) : []);
  }
};
export const RoleWatcher = () => {
  const { setValue } = useFormContext(),
    role = useWatch({
      name: 'role'
    }),
    prevRole = useRef(role);

  useEffect(() => {
    if (prevRole.current) {
      if (
        role === 'Admin' ||
        ((prevRole.current === 'Attorney Admin' || prevRole.current === 'Attorney') &&
          (role === 'Client' || role === 'Business Admin')) ||
        ((role === 'Attorney Admin' || role === 'Attorney') &&
          (prevRole.current === 'Client' || prevRole.current === 'Business Admin'))
      ) {
        setValue('business_id', null);
      }
    }
    prevRole.current = role;
  }, [role]);
  return null;
};
export const BusinessActiveField = (props) => {
  const business_id = useWatch({
    name: 'business_id'
  });
  if (business_id) {
    return (
      <Radio
        {...props}
        name="business_active"
        label="Business Active"
        radioLabel="Active"
        radioLabelTwo="Inactive"
      />
    );
  }
  return null;
};
const BusinessField = ({ roleName, data, status }) => {
  const role = useWatch({
    name: roleName
  });
  if (role && role !== 'Admin') {
    return (
      <ResourceSelect
        data={status === 'success' ? data : undefined}
        resource={getBusinessesSelectorByRole(role)}
        label="Business"
        name="business_id"
        placeholder="Select Business..."
      />
    );
  }
  return null;
};

export const roleOptions = [
  { value: 'Admin', label: 'Admin - TurnSignl' },
  { value: 'Business Admin', label: 'Admin - Business' },
  { value: 'Attorney Admin', label: 'Admin - Attorney' },
  { value: 'Attorney', label: 'Attorney' },
  { value: 'Client', label: 'Client' }
];

export const lawFirmRoleOptions = [
  { value: 'Attorney Admin', label: 'Admin - Attorney' },
  { value: 'Business Admin', label: 'Admin - Business' },
  { value: 'Attorney', label: 'Attorney' }
];

export const businessRoleOptions = [
  { value: 'Business Admin', label: 'Admin - Business' },
  { value: 'Client', label: 'Client' }
];

const makeRoleOptions = (businessId, role, data) => {
  if (!businessId) {
    const user = useAuthState();
    if (user.is_admin) {
      return roleOptions;
    } else if (user.is_org_admin && !user.is_lawyer) {
      return businessRoleOptions;
    } else if (user.is_lawyer && user.is_org_admin) {
      return lawFirmRoleOptions;
    } else {
      return roleOptions;
    }
  } else {
    if (data) {
      const currentBusiness = data?.business_names?.find((el) => el.id === parseInt(businessId));
      if (currentBusiness.is_law_firm) {
        return lawFirmRoleOptions;
      } else {
        return businessRoleOptions;
      }
    } else {
      const user = useAuthState();
      if (user.is_org_admin && !user.is_lawyer) {
        return businessRoleOptions;
      } else if (user.is_lawyer && user.is_org_admin) {
        return lawFirmRoleOptions;
      }
    }
  }
};
export const UserFields = ({ businessId, role }) => {
  const request_path = businessId ? '/business/names.json?id=' + businessId : '/business/names.json';
  const { status, data } = useRequest(request_path);
  return (
    <>
      {role ? (
        <Input className="hidden" name="role" />
      ) : (
        <Select
          label="Role"
          name="role"
          placeholder="Select Role..."
          options={makeRoleOptions(businessId, role, data)}
          required
        />
      )}
      <SplitRow>
        {businessId ? (
          <>
            <Input className="hidden" name="business_id" />
          </>
        ) : (
          <BusinessField roleName="role" status={status} data={data} required />
        )}
        <BusinessActiveField />
      </SplitRow>
      <SplitRow>
        <AutocapitalizeInput
          label="First Name"
          name="first_name"
          placeholder="Enter First Name"
          required
        />
        <AutocapitalizeInput
          label="Last Name"
          name="last_name"
          placeholder="Enter Last Name"
          required
        />
      </SplitRow>
      <InputField label="Email" type="email" name="email" placeholder="Enter Email" required />
      <StateField roleName="role" />
      <AttorneyFields roleName="role" businessIdName="business_id" businesses={data?.business_names} />
      <RoleWatcher />
    </>
  );
};
export const UserFormWrapper = ({ children, ...props }) => (
  <Form validationSchema={userSchema} {...props}>
    {children}
  </Form>
);
export const UserForm = ({ children, ...props }) => (
  <UserFormWrapper {...props}>
    <UserFields />
    {children}
  </UserFormWrapper>
);
const UserFormPage = ({ form, page, ...props }) => (
  <>
    <section className="breadcrumb">
      <h1>User</h1>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="divider la la-arrow-right"></li>
        <li>
          <Link to="..">Users</Link>
        </li>
        <li className="divider la la-arrow-right"></li>
        <li>Add User</li>
      </ul>
    </section>
    <div className="lg:flex lg:-mx-4">
      <div className="lg:w-1/2 xl:w-3/4 lg:px-4">
        <div className="card p-5">
          <UserForm {...form}>
            <Button primary type="submit">
              Submit
            </Button>
          </UserForm>
        </div>
      </div>
    </div>
  </>
);

export default UserFormPage;
