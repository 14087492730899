import { useCallback, useEffect, useReducer } from 'react';
import { stringify } from 'query-string';
import { getRequest } from '@utils/api';
import useAccessorFunction from '@containers/useAccessorFunction';

const REQUEST = 'REQUEST';
const REQUEST__SUCCESS = 'REQUEST__SUCCESS';
const REQUEST__FAILURE = 'REQUEST__FAILURE';
const UPDATE_DATA = 'UPDATE_DATA';
const RESET = 'RESET';

const initialState = { status: 'loading', data: null, error: '' };

function requestReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        status: 'loading',
        error: ''
      };
    case REQUEST__SUCCESS:
      return {
        status: 'success',
        data: payload,
        error: ''
      };
    case REQUEST__FAILURE:
      return {
        status: 'error',
        data: null,
        error: payload || 'An unexpected error has occurred'
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: payload
      };
    case RESET:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

const makeRequestAction = () => ({ type: REQUEST });
const makeRequestSuccessAction = (payload) => ({ type: REQUEST__SUCCESS, payload });
const makeRequestFailureAction = (payload) => ({ type: REQUEST__FAILURE, payload });
const makeUpdateDataAction = (payload) => ({ type: UPDATE_DATA, payload });
const makeResetAction = () => ({ type: RESET });

export const usePagination =
  (makeRequest, onSetPage = (p) => {}) =>
  (newPage, query = {}) => {
    makeRequest({ ...query, page: newPage });
    onSetPage(newPage);
  };

export const usePaginationResponseData = (
  data,
  pageAccessor = (response) => response?.page,
  pageCountAccessor = (response) => response?.page_count
) => ({
  page: useAccessorFunction(pageAccessor)(data),
  pageCount: useAccessorFunction(pageCountAccessor)(data)
});

export default function usePaginationRequest(
  listAttr,
  url,
  page_size = 50,
  query,
  blockInitialRequest
) {
  const [state, dispatch] = useReducer(requestReducer, initialState),
    makeRequest = async (newQuery = {}) => {
      dispatch(makeRequestAction());
      try {
        const requestQuery = stringify({ page_size, ...query, ...newQuery }),
          response = await getRequest(`${url}${requestQuery.length > 0 ? `?${requestQuery}` : ''}`);
        dispatch(
          makeRequestSuccessAction({
            ...response,
            [listAttr]:
              response.page === 1
                ? response[listAttr]
                : [...state.data[listAttr], ...response[listAttr]]
          })
        );
      } catch (e) {
        dispatch(makeRequestFailureAction(e.message));
      }
    },
    setQuery = (newQuery) => {
      dispatch(makeResetAction());
      makeRequest(newQuery);
    },
    updateData = useCallback((data) => dispatch(makeUpdateDataAction(data)), []),
    { page, pageCount } = usePaginationResponseData(state.data),
    setPage = usePagination(makeRequest);

  useEffect(() => {
    if (!blockInitialRequest) {
      makeRequest();
    }
  }, [url]);
  return { ...state, setQuery, makeRequest, updateData, setPage, page, pageCount };
}
