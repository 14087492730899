import { IncidentListPage } from '@components/Incident/ListPage';
import Table from '@components/Virtualized/Table';
import Section from '@components/Section';
import { withRequest } from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';
import { getFormattedDate, getDuration } from '@utils';

const recentActivitiesTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.set_active_at) : ''),
    key: 'set_active_at'
  },
  {
    label: 'Total Calls',
    cellDataGetter: (cell) =>
      cell?.rowData?.attempted_call_count ? cell.rowData.attempted_call_count : '',
    key: 'attempted_call_count'
  },
  {
    label: 'Received',
    cellDataGetter: (cell) =>
      cell?.rowData?.connected_call_count ? cell.rowData.connected_call_count : '',
    key: 'connected_call_count'
  },
  {
    label: 'Accepted',
    cellDataGetter: (cell) =>
      cell?.rowData?.accepted_call_count ? cell.rowData.accepted_call_count : '',
    key: 'accepted_call_count'
  },
  {
    label: 'Declined',
    cellDataGetter: (cell) =>
      cell?.rowData?.declined_call_count ? cell.rowData.declined_call_count : '',
    key: 'declined_call_count'
  },
  {
    label: 'Missed',
    cellDataGetter: (cell) =>
      cell?.rowData?.attempted_call_count
        ? cell.rowData.connected_call_count
          ? cell.rowData.attempted_call_count - cell.rowData.connected_call_count
          : cell.rowData.attempted_call_count
        : '',
    key: 'missed_call_count'
  },
  {
    label: 'Available Hours',
    cellDataGetter: (cell) =>
      cell?.rowData?.set_active_at
        ? getDuration(
            cell.rowData.set_active_at,
            cell.rowData.set_inactive_at ? cell.rowData.set_inactive_at : new Date().getTime()
          )
        : '',
    key: 'duration'
  }
];
const UserIncidentListPageLayout = withRequest(({ request: { data }, pageSize = 25 }) => (
  <>
    {data.is_lawyer ? (
      <>
        <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
          {data.lawyer.last_activities?.length > 0 && (
            <>
              <h5 className="p-5">Daily Activities</h5>
              <div className="collapse open">
                <div className="p-5 pt-0 h-96">
                  <Table
                    noLink
                    rows={data.lawyer.last_activities}
                    cols={recentActivitiesTableCols}
                  />
                </div>
              </div>
            </>
          )}
          <Section
            title="Call Log"
            initialIsOpen
            collapsible={data.lawyer.last_activities?.length > 0}
            border={data.lawyer.last_activities?.length > 0}
          >
            <IncidentListPage
              query={{ lawyer_id: data.lawyer.id }}
              pageSize={pageSize}
              showClientOnly
              className="h-96"
            />
          </Section>
        </div>
      </>
    ) : (
      <IncidentListPage
        query={{ user_id: data.id }}
        pageSize={pageSize}
        showLawyerOnly
        className="h-96"
      />
    )}
  </>
));

export default withPageTitle(UserIncidentListPageLayout, 'User Calls');
