import { createContext, useContext } from 'react';
import usePaginationRequest from '@containers/usePaginationRequest';

const PaginationRequestContext = createContext();

export function usePaginationRequestState() {
  const context = useContext(PaginationRequestContext);
  if (context === undefined) {
    throw new Error('usePaginationRequestState must be used within a PaginationRequestProvider');
  }
  return context;
}

export function withPaginationRequest(WrappedComponent) {
  return (props) => {
    const request = usePaginationRequestState();
    return <WrappedComponent {...props} request={request} />;
  };
}

export default function PaginationRequestProvider({ children, url, pageSize, query, listAttr }) {
  const request = usePaginationRequest(listAttr, url, pageSize, query);

  return (
    <PaginationRequestContext.Provider value={request}>
      {children}
    </PaginationRequestContext.Provider>
  );
}
