import { createContext, useContext } from 'react';
import useRequest from '@containers/useRequest';
import Shell from '@components/Shell';

const AppInfoStateContext = createContext();
function AppInfoProvider({ children }) {
  const state = useRequest('/api_info');
  return (
    <AppInfoStateContext.Provider value={state.data}>
      {state.status !== 'success' ? <Shell /> : children}
    </AppInfoStateContext.Provider>
  );
}

function useAppInfoState() {
  const context = useContext(AppInfoStateContext);
  if (context === undefined) {
    throw new Error('useAppInfoState must be used within a AppInfoProvider');
  }
  return context;
}

export { AppInfoProvider, useAppInfoState };
