import { useRef, useEffect } from 'react';

export default function useScrollTo(trigger, offset = 60, delay) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      const scrollToRef = () => {
        var elementPosition = ref.current.getBoundingClientRect().top;
        var offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      };
      if (delay) {
        setTimeout(scrollToRef, delay);
      } else {
        scrollToRef();
      }
    }
  }, [trigger, delay, offset]);
  return ref;
}
