import React, { useState } from 'react';
import CommonChart from './chart';

const Card = ({ businessId, width, type, currentSection, isMobile }) => {
  const [cards, setCards] = useState(currentSection.cards);

  return (
    <React.Fragment>
      {cards
        ?.filter((item) => item.isShow === true)
        ?.map((item, index) => (
          <div style={{ width: width }} key={index}>
            {item.type.includes('board') ? (
              !isMobile ? (
                <React.Fragment>
                  {businessId ? (
                    <div className="text-center">
                      {type === 'title' && (
                        <div className="text-base font-extrabold">{item.title}</div>
                      )}
                      {type === 'value' && (
                        <div className="p-3 text-base font-thin">
                          {Intl.NumberFormat().format(parseInt(item.count))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-center">
                      {type === 'title' && (
                        <div className="text-base font-extrabold">{item.title}</div>
                      )}
                      {type === 'value' && (
                        <div className="p-3 text-base font-thin">
                          {Intl.NumberFormat().format(parseInt(item.count))}
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="mt-5">
                    <div className="border-b border-gray-300">
                      <div className="grid bg-transparent justify-center">
                        <div className="text-center">
                          <div className="text-base font-extrabold">{item.title}</div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 border-gray-300">
                      <div className="grid bg-transparent justify-center">
                        <div className="text-center">
                          <div className="p-3 text-base font-thin">
                            {Intl.NumberFormat().format(parseInt(item.count))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            ) : (
              <React.Fragment>
                <div className="inline text-center">
                  <React.Fragment>
                    <div className="text-3xl font-extrabold">{item.label}</div>
                    <div className="text-sm font-normal">{item.description}</div>
                    <div className="font-bold">
                      <CommonChart type={item.type} data={item.data} width={width} />
                    </div>
                  </React.Fragment>
                </div>
              </React.Fragment>
            )}
          </div>
        ))}
    </React.Fragment>
  );
};

export default Card;
