import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Toast from '@components/Toaster/Item';
import styles from './index.module.css';

const animations = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

const Toaster = ({ delay = 8000, animationTime = 300, toasts = [], remove }) => (
  <TransitionGroup className={styles.container}>
    {toasts.map((toast) => (
      <CSSTransition key={toast.id} classNames={animations} timeout={animationTime} unmountOnExit>
        <Toast toast={toast} delay={delay} remove={remove} />
      </CSSTransition>
    ))}
  </TransitionGroup>
);

export default Toaster;
