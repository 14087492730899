import EditableValue, { Value } from '@components/Form/Fields/EditableValue';
import ToggleForm from '@components/Form/ToggleForm';
import Section from '@components/Section';
import CampaignFormWrapper from '@components/Campaign/Form';
import { useRequestState } from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';
import { updateCampaign } from '@utils/api/campaign';
import { classNames } from '@utils/';
import styles from './index.module.css';
import { useFormContext } from 'react-hook-form';

const makeCampaignFormValues = ({
  ...campaign
}) => ({
  ...campaign,
});
const SwitchWithFormContext = (props) => {
  const { register } = useFormContext();
  return <input name={props.name} type="checkbox" {...register(props.name)} />;
};
const SwitchWithoutContext = (props) => {
  return <input disabled name={props.name} type="checkbox" checked={props.value} />;
};
const CampaignPage = ({ onSuccess = () => { } }) => {
  const { data, updateData, makeRequest } = useRequestState(),
    handleSuccess = ({ response }) => {
      onSuccess(response);
      updateData(response);
    };
  return (
    <ToggleForm
      form={CampaignFormWrapper}
      formProps={{
        url: `/campaigns/${data.id}.json`,
        defaultValues: makeCampaignFormValues(data),
        onSubmit: updateCampaign,
        onSuccess: handleSuccess,
        to: false,
        toast: 'Campaign updated'
      }}
    >
      {(isEditing) => (
        <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
          <Section title="Profile Information" collapsible={false} initialIsOpen>
            <div className="lg:flex">
              <div className="lg:w-1/2 lg:pr-2">
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.name}
                      isEditing={isEditing}
                      name="name"
                      label="Internal Name"
                      placeholder="Campaign Name"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:pr-2">
                    <label className="label block mb-2 switch switch_outlined">
                      {'Paid only'}
                      {isEditing ?
                        <SwitchWithFormContext
                          value={data.paid_only}
                          name="paid_only"
                        />
                        :
                        <SwitchWithoutContext
                          value={data.paid_only}
                          name="paid_only"
                        />
                      }
                      <span></span>
                    </label>
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.promo_code}
                      isEditing={isEditing}
                      name="promo_code"
                      label="Promotion Code"
                      placeholder="Promo Code"
                    />
                    {isEditing && <div className={classNames('w-full', styles.red)}>**Changes to this value may result in changes to the short link**</div>}
                  </div>
                  <div className="lg:w-1/2 lg:pr-2">
                    <Value label="Campaign Code">{data.code}</Value>
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:full">
                    <Value label="Short Link">{data.short_url}</Value>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 lg:pr-2 lg:border-l border-gray-300 dark:border-gray-900 pl-5">
                <div className="lg:flex">
                  <div className="w-full lg:pr-2">
                    <EditableValue
                      value={data.title}
                      isEditing={isEditing}
                      name="title"
                      label="Social Media Sharing Title"
                      placeholder="Social Media Title"
                    />
                    {isEditing && <div className={classNames('w-full', styles.red)}>**Changes to this value may result in changes to the short link**</div>}

                  </div>
                </div>
                <div className="lg:flex">
                  <div className="w-full lg:pr-2">
                    <EditableValue
                      value={data.message}
                      isEditing={isEditing}
                      name="message"
                      label="Social Media Sharing Message"
                      placeholder="Social Media Message"
                    />
                    {isEditing && <div className={classNames('w-full', styles.red)}>**Changes to this value may result in changes to the short link**</div>}
                  </div>
                </div>
              </div>
            </div>
          </Section>
        </div>
      )}
    </ToggleForm>
  );
};

export const UserCampaignPage = withPageTitle(CampaignPage, 'Campaign Profile');

const CampaignPageContainer = () => {
  const pagination = usePaginationRequestState(),
    handleSuccess = (response) => {
      pagination.updateData({
        ...pagination.data,
        campaigns: pagination.data.campaigns.map((u) => {
          if (u.id === response.id) return Object.assign({}, u, response);
          return u;
        })
      });
    };
  return <CampaignPage onSuccess={handleSuccess} />;
};

export default withPageTitle(CampaignPageContainer, 'Campaign Profile');