import { string, object, ref } from 'yup';

export const loginSchema = object().shape({
  email: string().email('Invalid email').required('Email is required'),
  password: string().required('Password is required')
});

export const registrationSchema = object().shape({
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required'),
  password: string().required('Password is required'),
  password_confirmation: string()
    .required('Passwords must match')
    .oneOf([ref('password'), null], 'Passwords must match')
});

export const passwordSchema = object().shape({
  password: string().required('Password is required'),
  password_confirmation: string()
    .required('Passwords must match')
    .oneOf([ref('password'), null], 'Passwords must match')
});

export const forgotPasswordSchema = object().shape({
  email: string().email('Invalid email').required('Email is required')
});

export const invitePageValidation = object().shape({
  invitation_token: string().required('Invitation token is required')
});

export const confirmPageValidation = object().shape({
  confirmation_token: string().required('Confirmation token is required')
});

export const updatePasswordPageValidation = object().shape({
  reset_password_token: string().required('Password token is required')
});

export const activationCodeValidation = object().shape({
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required'),
  identifier: string().required('Promo code is required')
});
