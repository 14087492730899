import { classNames } from '@utils';

export const Loader = ({ className }) => <div className={classNames('loader', className)} />;

export const RequestLoader = ({ children }) => (
  <div className="loaderWrapper">
    <Loader />
    {children}
  </div>
);
const LoaderOverlay = () => (
  <div className="loaderContainer">
    <Loader />
  </div>
);

export default LoaderOverlay;
