import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import StateValue from '@containers/StateValue';
import { phoneFormat, getFormattedDate } from '@utils';

const leadTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.created_at) : ''),
    key: 'created_at'
  },
  {
    label: 'Last Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.last_name : ''),
    key: 'last_name'
  },
  {
    label: 'First Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.first_name : ''),
    key: 'first_name'
  },
  {
    label: 'State',
    cellRenderer: (cell) =>
      cell?.rowData?.state_id ? <StateValue id={cell.rowData.state_id} /> : '',
    cellDataGetter: (cell) => (cell?.rowData?.state_id ? cell.rowData.state_id : ''),
    key: 'state_id'
  },
  {
    label: 'Email',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.email : ''),
    key: 'email'
  },
  {
    label: 'Phone',
    cellDataGetter: (cell) => (cell?.rowData?.phone ? phoneFormat(cell.rowData.phone) : ''),
    key: 'phone'
  },
  {
    label: 'Campaign',
    cellDataGetter: (cell) => (cell?.rowData?.campaign_name ? cell.rowData.campaign_name : ''),
    key: 'campaign_name'
  }

];
const LeadsList = ({ listAccessor = ({ leads }) => leads, ...props }) => (
  <InfiniteLoaderContainer {...props} listAccessor={listAccessor} cols={leadTableCols} hideButton />
);

export default LeadsList;
