import { useState } from 'react';

export default function useSort() {
  const [order, setOrder] = useState(),
    sortSplit = order?.[0]?.split(' '),
    sortBy = sortSplit?.[0] ? sortSplit[0] : null;
  return {
    order,
    sortBy,
    sortDirection: sortSplit?.[1] ? (sortSplit[1] === 'desc' ? 'DESC' : 'ASC') : 'ASC',
    sort: (s) => {
      if (s.sortBy === sortBy) {
        if (s.sortDirection === 'DESC') {
          setOrder([`${s.sortBy} desc`]);
        } else {
          setOrder([]);
        }
      } else {
        setOrder([`${s.sortBy}`]);
      }
    }
  };
}
