import { useAppInfoState } from '@containers/AppInfo';
import Select from '@components/Form/Fields/Select';
import useAccessorFunction from '@containers/useAccessorFunction';

const ContractTypeSelect = (props) => {
  const info = useAppInfoState();
  const jsonFunc = useAccessorFunction('lawyer_contract_types'),
    json = jsonFunc(info);
  return (
    <Select
      {...props}
      placeholder={false}
      options={json ? json.map((s) => ({ value: s, label: s })) : []}
    />
  );
}

export default ContractTypeSelect;
