import { createContext, useContext, useState } from 'react';
import Modal from '@components/Modal';

const ErrorModalDispatchContext = createContext();

function ErrorModalProvider({ children }) {
  const [error, setError] = useState();
  return (
    <ErrorModalDispatchContext.Provider value={setError}>
      {children}
      <Modal
        pinTop
        isOpen={error ? true : false}
        title="Error"
        close={() => {
          setError();
        }}
      >
        {error}
      </Modal>
    </ErrorModalDispatchContext.Provider>
  );
}

function useErrorModalDispatch() {
  const context = useContext(ErrorModalDispatchContext);
  if (context === undefined) {
    throw new Error('useErrorModalDispatch must be used within a ErrorModalProvider');
  }
  return context;
}

export { ErrorModalProvider, useErrorModalDispatch };
