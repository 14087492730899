import axios from 'axios';

const LocalStorageService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken({ access_token, refresh_token, created_at, expires_in }) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('access_token_expires', created_at + expires_in);
  }
  function _getAccessToken() {
    return localStorage.getItem('access_token');
  }
  function _getAccessTokenExpiration() {
    return localStorage.getItem('access_token_expires');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _clearToken() {
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token_expires');
  }
  function _getTheme() {
    return localStorage.getItem('theme');
  }
  function _setTheme(theme) {
    localStorage.setItem('theme', theme);
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getTheme: _getTheme,
    setTheme: _setTheme,
    clearToken: _clearToken,
    getAccessTokenExpiration: _getAccessTokenExpiration
  };
})();

export default LocalStorageService;
