import { PublicForm } from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import { forgotPasswordSchema } from '@utils/validation/auth';
import { forgotPassword } from '@utils/api/auth';

const ForgotPassword = () => (
  <PublicForm
    to="/login"
    toast="You should receive a password reset email soon"
    text="Forgot Password?"
    subtext="We'll email you soon"
    buttonText="Send Reset Link"
    onSubmit={forgotPassword}
    validationSchema={forgotPasswordSchema}
  >
    <Input type="email" name="email" label="Email" placeholder="example@example.com" />
  </PublicForm>
);

export default ForgotPassword;
