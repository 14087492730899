import { makeRequest } from '@utils/api';

export const updateSubscription = (subscription, url) =>
  makeRequest('PATCH', url, {
    subscription: {
      confirm_status: 'ACCEPTED',
      ...subscription
    }
  });

export const cancelSubscription = (url) =>
  makeRequest('PATCH', url, {
    subscription: {
      confirm_status: 'CANCELLED'
    }
  });
