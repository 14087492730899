import { useEffect, useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import { getBusinessNamesRequest } from '@utils/api/business';
import { RequestError } from '@utils/api/error';

export const businessNameFilterAllOption = { id: 'all', name: 'All Business Names' };
export const useBusinessNameFilter = (initialState = businessNameFilterAllOption) => {
  return useState(initialState);
};

export default function BusinessNameFilter({ name, setName }) {
  const [options, setOptions] = useState([{ id: 'all', name: 'All Business Names' }]);

  const getBusinessNames = async () => {
    try {
      const res = await getBusinessNamesRequest('/business/names.json');
      setOptions(options.concat(res.business_names));
    } catch (e) {
      throw new RequestError(e.response.status, e.response.data?.errors);
    }
  };

  useEffect(() => {
    getBusinessNames();
  }, []);

  return (
    <div style={{ display: 'none' }}>
      <Dropdown
        options={options}
        value={name}
        setValue={setName}
        valueAccessor="id"
        labelAccessor="name"
      />
    </div>
  );
}
