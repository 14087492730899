import { Outlet } from 'react-router-dom';
import StateList from '@components/State/List';
import { Input } from '@components/Form/Fields/Input';
import Checkbox, { CheckboxComponent } from '@components/Form/Fields/Checkbox';
import ToggleForm from '@components/Form/ToggleForm';
import BusinessFormWrapper from '@components/Business/Form';
import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import { useAppInfoState } from '@containers/AppInfo';
import { RequestLoader } from '@components/Loader';
import { withRequest } from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';
import { updateBusiness } from '@utils/api/business';

const businessMarketTableCols = [
  // { label: 'ID', cellDataGetter: (cell) => (cell?.rowData?.id ? cell.rowData.id : ''), key: 'id' },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.abbrev ? cell.rowData.abbrev : ''),
    key: 'abbrev'
  },
  {
    label: 'Description',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : ''),
    key: 'name'
  },
  {
    label: 'Status',
    cellDataGetter: (cell) => (cell?.rowData?.covered ? 'Active' : 'Inactive'),
    key: 'active'
  }
];

const makeBusinessMarketsValues = ({ covered_states, covered_counties }) => ({
  covered_states,
  covered_counties
});

const BusinessMarkets = withRequest(({ request: { data, updateData, makeRequest } }) => {
  const info = useAppInfoState();
  return (
    <>
      <div className="card p-5 h-96 flex-shrink-0 overflow-x-auto mt-5">
        {info?.states ? (
          <StateList
            cols={businessMarketTableCols}
            states={info.states
              .filter((state) => state.active)
              .map((state) => ({
                covered:
                  data.covered_states &&
                  data.covered_states.findIndex((s) => s.id === state.id) !== -1,
                ...state
              }))}
          />
        ) : (
          <RequestLoader />
        )}
      </div>
      <Outlet />
    </>
  );
});
// const BusinessMarkets = ({title,...props}) => {
//   const data = useAppInfoState();
//   return(<>
//     <section className="breadcrumb lg:flex items-start">
//
//         {title && <h1>{title}</h1>}
//     </section>
//     <div className="card p-5 h-96 flex-shrink-0 overflow-x-auto">
//       {data?.states ? (
//         <StateList states={data.states.filter(state => state.active)} cols={businessMarketTableCols} />
//       ) : (
//         <RequestLoader />
//       )}
//     </div>
//   </>)
// }

export default withPageTitle(BusinessMarkets, 'Business Markets');
