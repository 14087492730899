import axios from 'axios';
import { makeRequest } from '@utils/api';
import handleError from '@utils/api/error';
import { stringifyQuery } from '@utils';

export const exportIncidents = (query) =>
  axios(
    `${process.env.REACT_APP_API_URL}/incidents.csv${query ? `?${stringifyQuery(query)}` : ''}`
  ).then(({ data }) => data, handleError);

export const updateIncident = (id, incident) =>
  makeRequest('PATCH', `/incidents/${id}.json`, {
    incident
  });
