import { makeRequest, getRequest } from '@utils/api';

const multipartHeaders = {
  'Content-type': 'multipart/form-data'
};
export const createImport = (businessId, file) =>
  new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append('business[user_import]', file);
      const result = await makeRequest(
        'POST',
        `/businesses/${businessId}/import.json`,
        formData,
        multipartHeaders
      );
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });

export const updateImport = (businessId) =>
  makeRequest('PATCH', `/businesses/${businessId}/import.json`);

export const getImportStatus = (businessId) => getRequest(`/businesses/${businessId}/import.json`);
