import { PublicForm } from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import PasswordInput from '@components/Form/Fields/PasswordInput';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import StateSelect from '@containers/StateSelect';
import { registrationSchema } from '@utils/validation/auth';
import { registrationRequest } from '@utils/api/auth';

const RegistrationPage = () => (
  <PublicForm
    buttonText="Register"
    to="/download_app"
    text="Create Your Account"
    subtext="Let's roll"
    toast="Your account has been created, please check your email for further instructions"
    onSubmit={registrationRequest}
    defaultValue={{ state_id: 1 }}
    validationSchema={registrationSchema}
  >
    <AutocapitalizeInput name="first_name" label="First Name" placeholder="First Name" />
    <AutocapitalizeInput name="last_name" label="Last Name" placeholder="Last Name" />
    <Input type="email" name="email" label="Email" placeholder="example@example.com" />
    <PhoneNumberInput name="phone" label="Phone Number" placeholder="(555) 555-5555" />
    <StateSelect label="State" placeholder="Select State..." />
    <PasswordInput name="password" label="Password" placeholder="••••••" />
    <PasswordInput name="password_confirmation" label="Confirm Password" placeholder="••••••" />
  </PublicForm>
);

export default RegistrationPage;
