
import bg from '@assets/images/best-buy-bg.png';
import promo from '@assets/images/best-buy-promo.png';
import logo from '@assets/images/logo.png';
import appleStoreIcon from '@assets/images/download-on-the-app-store.png';
import playStoreIcon from '@assets/images/google-play-badge-300x89.png';
import Header from '@components/Layouts/App/Header';
import style from './index.module.css'
import { FaFacebookF, FaTiktok, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { Input } from '@components/Form/Fields/Input';
import { activationCodeValidation } from '@utils/validation/auth';
import { activationCode } from '@utils/api/auth';
import Form from '@components/Form';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const BestBuyPage = () => {
  const { pathname } = useLocation();
  const defaultValue = {
    first_name: '',
    last_name: '',
    email: '',
    identifier: pathname.length > 10 ? pathname.substring(9) : ''
  }
  const [formSubmitted, setFormSubmitted] = useState('');
  return (
    <div className={style.mainContainer}>
      <Header showSocialMedia />
      <div className={style.bgContainer}>
        <div className={style.bgImageContainer}>
          <img src={bg} className={style.bgImage} alt="Bestbuy background" />
          <h2 className={style.bgText}>{'Drive stress-free for 3\nmonths with TurnSignl'}</h2>
          <svg className={style.sectionDividerSvgStroke}>
            <clipPath id="section-divider-64ca6066f1f4880f7cb5fdda" style={{ zIndex: 3 }} clipPathUnits="objectBoundingBox">
              <path className="section-divider-clip" d="M2.012, 0.897 L2.012, 1 l0,0 c-0.252,0 -0.504,0 -1.008,-0.103 l0,0.103l0,0 c-0.252,0 -0.504,0 -1.008,-0.103 l0,0.103l0,0 c-0.252,0 -0.504,0 -1.008,-0.103 l0,0.103 L-1.012, -1 L2.012, -1 z"></path>
            </clipPath>
          </svg>
          <svg className={style.sectionDividerSvgStroke} viewBox="0 0 1 1" preserveAspectRatio="none">
            <path className={style.sectionDividerStroke} d="M2.0105, 0.888 L2.0105, 1 l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112" vector-effect="non-scaling-stroke"></path>
          </svg>
        </div>
        <svg className={style.sectionDividerSvgStroke} viewBox="0 0 1 1" preserveAspectRatio="none">
          <path className={style.sectionDividerStroke} d="M2.0105, 0.888 L2.0105, 1 l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112l0,0 c-0.25175,0 -0.5035,0 -1.007,-0.112 l0,0.112" vector-effect="non-scaling-stroke"></path>
        </svg>
      </div>
      <div className={style.secondContainer}>
        <div className={style.halfContainer}>
          <h2 className={style.blackText}>Redeem your FREE 3-Month TurnSignl subscription&nbsp;</h2>
          <br />
          <br />
          <h3 className={style.blackText}><strong>How does it work?</strong></h3>
          <br />
          <p className={style.answer}>Complete the short form below, then click “Redeem My Offer” to download the TurnSignl App and complete registration. It’s that easy!</p>
          <br />
          <h3 className={style.blackText}><strong>How much will it cost?</strong></h3>
          <br />
          <p className={style.answer}>If you select an annual subscription, the cost is $60/year. If you choose a monthly subscription, the cost is $6.99/month.</p>
          <br />
          <h3 className={style.blackText}><strong>Will I be charged now?</strong></h3>
          <br />
          <p className={style.answer}>No. You will be charged 3 months from your redemption date. (i.e. if you sign up on August 13th, your renewal will be charged on November 13th)</p>
          <br />
          <h3 className={style.blackText}><strong>When does my trial subscription end?</strong></h3>
          <br />
          <p className={style.answer}>This trial subscription is valid for three months from the date of redemption. At the end of this period, you will receive an email notification of your renewal. You can cancel your subscription at any time.</p>
          <br />
          <br />
          <h3 className={style.renewalTitle}><strong><em>AUTOMATIC RENEWAL NOTICE</em></strong></h3>
          <p className={style.renewal}><em>If you do not cancel your subscription before the end of your free trial, TurnSignl will charge your chosen payment card $60 (yearly subscription) or $6.99 (monthly subscription) or the then-current price, tax included. Your subscription automatically renews every year or month, based on subscription, until cancelled. Cancel anytime in the TurnSignl app or by contacting the Support team at </em><a href="mailto:support@turnsignl.com?subject=Cancel%20Subscription"><em className={style.blackText}><u>support@turnsignl.com</u></em></a><em>. See terms at </em><a href="/privacy-policy"><em className={style.blackText}><u>turnsignl.com/privacy-policy</u></em></a><em>.</em></p>
        </div>
        <div className={style.halfContainer}>
          <img src={promo} className={style.promoImage} alt="Bestbuy promo" />
        </div>
      </div>
      <div className={style.secondContainer}>
        <div className={style.halfContainer}>
          {!formSubmitted && <h2 className={style.blackText}>The form below MUST be completed on a mobile device to redeem.</h2>}
          <div className={style.whiteBox}>
            <img src={logo} className={style.logoImage} alt="Bestbuy logo" />
            {formSubmitted ? <div style={{ padding: 24 }}>
              <h2 className={style.centerText}>GET OUR MOBILE APP</h2>
              <br />
              <div className={style.storeHorizontalContainer}>
                <a href={formSubmitted} target='_blank' className={style.storeIcons}>
                  <img src={playStoreIcon} width={'100%'} alt="Bestbuy play store" />
                </a>
                <a href={formSubmitted} target='_blank' className={style.storeIcons}>
                  <img src={appleStoreIcon} width={'100%'} alt="Bestbuy app store" />
                </a>
              </div>
              <br />
            </div>
              :
              <div style={{ padding: 24 }}>
                <h2 className={style.blackText}>Redeem Your Offer</h2>
                <br />
                <br />
                <Form
                  to='/bestBuy'
                  onSubmit={activationCode}
                  validationSchema={activationCodeValidation}
                  onSuccess={({ response }) => { setFormSubmitted(response?.link_url) }}
                  toast=''
                  defaultValues={defaultValue}
                >
                  <div className={style.formHorizontalContainer}>
                    <div className={style.formHorizontalBoxContainer}>
                      <h4 className={style.blackText}>First Name</h4>
                      <Input type="text" name="first_name" placeholder="First name" className={style.input} />
                    </div>
                    <div className={style.formHorizontalSpacer} />
                    <div className={style.formHorizontalBoxContainer}>
                      <h4 className={style.blackText}>Last Name</h4>
                      <Input type="text" name="last_name" placeholder="Last name" className={style.input} />
                    </div>
                  </div>
                  <br />
                  <div className={style.formHorizontalContainer}>
                    <div className={style.formHorizontalBoxContainer}>
                      <h4 className={style.blackText}>Email Address</h4>
                      <Input type="email" name="email" placeholder="example@example.com" className={style.input} />
                    </div>
                    <div className={style.formHorizontalSpacer} />
                    <div className={style.formHorizontalBoxContainer}>
                      <h4 className={style.blackText}>Enter your unique promo code</h4>
                      <Input type="text" name="identifier" placeholder="xxxx-xxxx-xxxx-xxxx" className={style.input} />
                    </div>
                  </div>
                  <br />
                  <button type="submit" className={style.submitButton}><IoMdSend style={{ fontSize: '1rem' }} />SUBMIT</button>
                </Form>
              </div>}
          </div>
        </div>
        <div className={style.formHorizontalSpacer} />
        <div className={style.halfContainer}></div>
      </div>
      {/* <div className={style.mailingButtonContainer}>
      <button className={style.mailingButton}>Join Our Mailing List</button>
    </div> */}
      <div className={style.footerContainer}>
        <h2 className={style.blackText}>TURNSIGNL</h2>
        <br />
        <p className={style.answer} style={{ textAlign: 'center' }}><a className={style.blackText} href="mailto:info@turnsignl.com" tabindex="0"><u>info@turnsignl.com</u></a> | (612) 355-6995</p>
        <br />
        <p className={style.answer} style={{ textAlign: 'center' }}>34 13th Ave NE, Ste 104A |  Minneapolis, MN 55413</p>
        <br />
        <div className={style.socialContainer}>
          <a href='https://www.instagram.com/turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1.3rem' }}><FaInstagram /></a>
          <a href='https://www.facebook.com/TurnSignl/' target='_blank' style={{ color: 'black', fontSize: '1.3rem' }}><FaFacebookF /></a>
          <a href='https://www.linkedin.com/company/turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1.3rem' }}><FaLinkedinIn /></a>
          <a href='https://www.tiktok.com/@turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1.3rem' }}><FaTiktok /></a>
        </div>
      </div>
    </div>
  )
};

export default BestBuyPage //withPageTitle(BestBuyPage, 'Best Buy');;
