import { Outlet } from 'react-router-dom';
import { RequestLoader } from '@components/Loader';
import { withRequest } from '@containers/RequestContext';
import withPageTitle from '@containers/withPageTitle';
import { getTime } from '@utils';
import { getEventsRequest } from '@utils/api/event';
import React, { useEffect, useState } from 'react';
import Table from '@components/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core';

const color = 'rgba(130, 130, 130, var(--tw-text-opacity))';

const dollarUS = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const tableCols = [
  {
    label: 'Event',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : 'N/A'),
    key: 'name'
  },
  {
    label: 'Date',
    cellDataGetter: (cell) =>
      cell?.rowData ? format(new Date(cell.rowData.event_date), 'MM/dd/yyyy') : 'N/A',
    key: 'event_date'
  },
  {
    label: 'Time',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.time : 'N/A'),
    key: 'event_time'
  },
  {
    label: 'Details',
    cellDataGetter: (cell) => (cell?.rowData?.description ? cell.rowData.description : 'N/A'),
    key: 'description',
    disableSort: true
  },
  {
    label: 'Promo Code',
    cellDataGetter: (cell) => (cell?.rowData?.promotion_code ? cell.rowData.promotion_code : 'N/A'),
    key: 'promotion_code',
    disableSort: true
  },
  {
    label: 'Charge',
    cellDataGetter: (cell) =>
      cell?.rowData?.charge ? dollarUS.format(parseFloat(cell.rowData.charge)) : 'N/A',
    key: 'charge',
    disableSort: true
  }
];

const useStyles = makeStyles((theme) => ({
  tablePagination: {},
  tablePaginationCaption: {
    color: color
  },
  tablePaginationSelectIcon: {
    color: color
  },
  tablePaginationSelect: {
    color: color
  },
  tablePaginationActions: {
    '& svg': {
      color: color
    }
  }
}));

const UserEvents = withRequest(({ request: { data }, ...props }) => {
  const [events, setEvents] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getEvents = async () => {
    try {
      const res = await getEventsRequest(
        `/users/${data.id}/events.json?page=${page + 1}&page_size=${rowsPerPage}`
      );
      if (res) {
        setTotalCount(res.page_count * res.page_size);
        const events = [];
        for (const event of res.events) {
          event.time = event.event_date ? getTime(event.event_date) : 'N/A';
          events.push(event);
        }
        setEvents(events);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getEvents();
  }, [page, rowsPerPage]);

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <React.Fragment>
      <div className="card p-5 h-96 flex-shrink-0 mt-5">
        {events ? (
          <React.Fragment>
            <Table {...props} rows={events} cols={tableCols} noLink />
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              labelRowsPerPage="Show Entries:"
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              style={{ position: 'relative', top: 'calc(100% - 20px)' }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              classes={{
                caption: classes.tablePaginationCaption,
                selectIcon: classes.tablePaginationSelectIcon,
                select: classes.tablePaginationSelect,
                actions: classes.tablePaginationActions
              }}
            />
          </React.Fragment>
        ) : (
          <RequestLoader />
        )}
      </div>
      <Outlet />
    </React.Fragment>
  );
});

export default withPageTitle(UserEvents, 'User Events');
