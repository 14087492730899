import { forwardRef } from 'react';
import { classNames } from '@utils';

const Button = forwardRef(
  (
    {
      className,
      primary,
      secondary,
      outlined,
      children,
      onClick,
      as: WrapperComponent = 'button',
      ...props
    },
    ref
  ) => (
    <WrapperComponent
      {...props}
      onClick={props.disabled ? undefined : onClick}
      className={classNames(
        'btn uppercase',
        primary && 'btn_primary',
        secondary && 'btn_secondary',
        outlined && 'btn_outlined',
        className
      )}
      ref={ref}
    >
      {children}
    </WrapperComponent>
  )
);

export default Button;
