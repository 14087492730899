import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import Tippy from '@tippyjs/react';
import Button from '@components/Button';
import { classNames } from '@utils';

const makeArray = (n) => {
  const arr = [];
  for (var i = 1; i <= n; i++) {
    arr.push(i);
  }
  return arr;
};

const PageButton = ({
  page,
  isActive,
  query: { page: oldPage, ...restOfQuery },
  className,
  children
}) => (
  <Link
    to={`?${stringify({ ...restOfQuery, page }, { arrayFormat: 'bracket' })}`}
    className={classNames(
      className ? className : 'btn mr-2 my-1',
      isActive ? 'btn_primary' : 'btn_outlined btn_secondary'
    )}
  >
    {children}
  </Link>
);

const PageSizeButton = ({ pageSize, query: { page, page_size, ...restOfQuery } }) => (
  <Link
    to={`?${stringify({ ...restOfQuery, page_size: pageSize }, { arrayFormat: 'bracket' })}`}
    className={classNames('item px-2 py-1', Number(pageSize) === Number(page_size) && 'active')}
  >
    {pageSize}
  </Link>
);

export default function PaginationButtons({ itemCount, query, pageSize, page, pageCount }) {
  const start = (page - 1) * pageSize,
    end = page >= pageCount ? itemCount : start + pageSize;
  return (
    <div className="card lg:flex">
      <nav className="flex flex-wrap p-5">
        <PageButton page={1} query={query} className="btn mr-2 mb-2 lg:mb-0 btn_primary">
          First
        </PageButton>
        {makeArray(pageCount).map((n) => (
          <PageButton key={n} page={n} query={query} isActive={page === n}>
            {n}
          </PageButton>
        ))}
        <PageButton page={pageCount} query={query} className="btn mr-2 mb-2 lg:mb-0 btn_secondary">
          Last
        </PageButton>
      </nav>
      <div className="flex items-center ml-auto p-5 border-t lg:border-t-0 border-gray-200 dark:border-gray-900 whitespace-nowrap">
        Displaying {start}-{end} of {itemCount} items
      </div>
      <div className="flex items-center p-5 border-t lg:border-t-0 lg:border-l border-gray-200 dark:border-gray-900">
        <span className="mr-2">Show</span>
        <div className="dropdown">
          <Tippy
            content={
              <div className="dropdown-menu">
                <PageSizeButton pageSize={5} query={query} />
                <PageSizeButton pageSize={10} query={query} />
                <PageSizeButton pageSize={15} query={query} />
                <PageSizeButton pageSize={25} query={query} />
                <PageSizeButton pageSize={50} query={query} />
                <PageSizeButton pageSize={75} query={query} />
                <PageSizeButton pageSize={100} query={query} />
              </div>
            }
            theme="light-border"
            zIndex={25}
            offset={[0, 8]}
            arrow={false}
            placement="bottom-start"
            allowHTML
            interactive
            animation="shift-toward-extreme"
          >
            <Button outlined secondary>
              {pageSize}
              <span className="ml-3 la la-caret-down text-xl leading-none"></span>
            </Button>
          </Tippy>
        </div>
        <span className="ml-2">items</span>
      </div>
    </div>
  );
}
