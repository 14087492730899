import { Outlet } from 'react-router-dom';
import { RequestLoader } from '@components/Loader';
import StateList from '@components/State/List';
import RequestProvider, { withRequest } from '@containers/RequestContext';

const StateListContainer = withRequest(({ request: { data } }) =>
  data ? <StateList states={data} /> : <RequestLoader />
);
export const StateListLayout = ({ title, children }) => (
  <>
    <section className="breadcrumb lg:flex items-start">
      {title && <h1>{title}</h1>}
      <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
        <div className="flex mt-5 lg:mt-0"></div>
      </div>
    </section>
    <RequestProvider url="/states.json">
      <div className="card p-5 h-96 flex-shrink-0 overflow-x-auto">
        <StateListContainer />
      </div>
      {children}
    </RequestProvider>
  </>
);

const StateListRouteLayout = () => (
  <StateListLayout title="Market Preview">
    <Outlet />
  </StateListLayout>
);

export default StateListRouteLayout;
