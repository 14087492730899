import React from 'react';
import { useAuthState } from '@containers/Auth';
import BusinessLayout from './BusinessLayout';
import TabView from './TabView';

const DashboardPage = () => {
  const currentUser = useAuthState();

  return (
    <React.Fragment>
      <div className="dashboard">
        {currentUser.is_org_admin && <BusinessLayout businessId={currentUser.business_id} />}
        {currentUser.is_admin && <TabView />}
      </div>
    </React.Fragment>
  );
};

export default DashboardPage;
