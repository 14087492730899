import { useRef, useReducer, useEffect } from 'react';

const TOGGLE = 'TOGGLE';
const OPEN = 'OPEN';
const CLOSE = 'CLOSE';
const initialState = { maxHeight: '0px', isOpen: false };

function accordionReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE:
      return state.isOpen
        ? {
            maxHeight: '0px',
            isOpen: false
          }
        : {
            maxHeight: payload,
            isOpen: true
          };
    case CLOSE:
      return {
        maxHeight: '0px',
        isOpen: false
      };
    case OPEN:
      return {
        maxHeight: payload,
        isOpen: true
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

const toggleAction = (payload = '0px') => ({
  type: TOGGLE,
  payload
});
const openAction = (payload = '0px') => ({
  type: OPEN,
  payload
});
const closeAction = () => ({
  type: CLOSE
});

export default function useAccordion(isOpen = false) {
  const [state, stateDispatch] = useReducer(accordionReducer, { maxHeight: '0px', isOpen }),
    ref = useRef(),
    toggle = () => stateDispatch(toggleAction('none')),
    open = () => stateDispatch(openAction('none')),
    close = () => stateDispatch(closeAction());
  useEffect(() => {
    if (isOpen) {
      open();
    }
  }, []);
  return { state, ref, toggle, open, close };
}
