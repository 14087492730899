import { Scroller } from '@components/State/View';
import { withRequest } from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import Request from '@containers/Request';
import Table from '@components/Table';
import { format } from 'date-fns';
import { downloadFileRequest } from '@utils/api';

const marketTableCols = [
  {
    label: 'Name',
    cellRenderer: (cell) => (<div>{cell.rowData.name}</div>),
    key: 'name'
  },
  {
    label: 'Created At',
    cellRenderer: (cell) => (<div>{format(new Date(cell.rowData.created_at), 'MM/dd/yyyy')}</div>),
    key: 'created_at'
  }
];
const FilesList = ({ data, cols = marketTableCols, businessId }) => {
  return (
    <Table
      cols={cols}
      rows={data.files}
      onRowClick={async (item) => {
        // const url = `${process.env.REACT_APP_API_URL}/businesses/${businessId}/bucket/file?file_name=${item.name}`
        // window.open(url, '_blank').focus();

        const res = await downloadFileRequest('GET', `/businesses/${businessId}/bucket/file?file_name=${item.name}`)
        let url = window.URL.createObjectURL(res);
        let a = document.createElement('a');
        a.href = url;
        a.download = item.name;
        a.click();

      }}
    />
  );
};
const BusinessFilesPageContainer = withParams(
  withRequest(({ params: { businessId } }) => (
    <Scroller check={businessId} delay={400} offset={-300}>
      <Request url={`/businesses/${businessId}/bucket.json`}>
        {(state) => <FilesList data={state} businessId={businessId} />}
      </Request>
    </Scroller>
  ))
);
export default withPageTitle(BusinessFilesPageContainer, 'Business Files');
