import withField from '@components/Form/Fields/withField';
import { useFormContext } from 'react-hook-form';
import { classNames } from '@utils';

export const RadioField = (props) => {
  const { register } = useFormContext();
  return <input {...register(props.name)} {...props} />;
};

export const _Radio = ({
  as: InputComponent = RadioField,
  label,
  className,
  grow = true,
  ...props
}) => (
  <label
    className={classNames(
      'custom-radio w-auto',
      props.disabled && 'checkbox-disabled',
      grow && 'flex-grow',
      className
    )}
  >
    <InputComponent {...props} type="radio" />
    <span></span>
    <span className="whitespace-nowrap">{label}</span>
  </label>
);
export const StatelessRadioComponent = ({
  radioLabel,
  radioLabelTwo,
  value = true,
  checked,
  valueTwo = false,
  as = 'input',
  className,
  ...props
}) => (
  <div className={classNames('lg:flex lg:items-center py-1', className)}>
    <_Radio {...props} as={as} checked={checked} value={value} label={radioLabel} />
    <_Radio
      {...props}
      as={as}
      checked={!checked}
      className="mt-2 lg:ml-2 lg:mt-0"
      value={valueTwo}
      label={radioLabelTwo}
    />
  </div>
);
export const RadioComponent = ({
  radioLabel,
  radioLabelTwo,
  value = true,
  valueTwo = false,
  className,
  ...props
}) => (
  <div className={classNames('lg:flex lg:items-center py-1', className)}>
    <_Radio {...props} value={value} label={radioLabel} />
    <_Radio {...props} className="mt-2 lg:ml-2 lg:mt-0" value={valueTwo} label={radioLabelTwo} />
  </div>
);
export default withField(RadioComponent, undefined, undefined, true);
