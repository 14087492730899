import Form from '@components/Form';
import { businessSchema } from '@utils/validation/business';

const BusinessFormWrapper = ({ children, ...props }) => (
  <Form validationSchema={businessSchema} {...props}>
    {children}
  </Form>
);

export default BusinessFormWrapper;
