import { useState } from 'react';
import { useFormState } from 'react-hook-form';
import Form from '@components/Form';
import Button from '@components/Button';
import { classNames } from '@utils';

const SaveButton = () => {
  const { isDirty } = useFormState();
  return (
    <Button type={isDirty ? 'submit' : 'button'} primary disabled={!isDirty}>
      Save
    </Button>
  );
};

export default function ToggleForm({
  form: FormComponent = Form,
  formProps,
  className,
  children,
  title
}) {
  const [isEditing, setIsEditing] = useState(),
    WrapperComponent = isEditing ? FormComponent : 'div',
    wrapperProps = isEditing
      ? {
          ...formProps,
          onSuccess: (response) => {
            if (formProps.onSuccess) {
              formProps.onSuccess(response);
            }
            setIsEditing();
          }
        }
      : {};
  return (
    <WrapperComponent className={classNames('w-full', className)} {...wrapperProps}>
      <div className="lg:flex items-center justify-between">
        <h2>{title}</h2>
        <div className="lg:flex items-center justify-end">
          {isEditing ? (
            <>
              <SaveButton />
              <Button
                type="button"
                onClick={() => {
                  setIsEditing();
                }}
                className="ml-5"
                secondary
                outlined
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              type="button"
              onClick={() => {
                setIsEditing(true);
              }}
              primary
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      {children(isEditing)}
    </WrapperComponent>
  );
}
