import ToggleForm from '@components/Form/ToggleForm';
import Section from '@components/Section';
import Table from '@components/Table';
import { Scroller } from '@components/State/View';
import Radio, { StatelessRadioComponent, RadioComponent } from '@components/Form/Fields/Radio';
import Checkbox, { CheckboxComponent } from '@components/Form/Fields/Checkbox';
import { Input } from '@components/Form/Fields/Input';
import EditableValue, { Value } from '@components/Form/Fields/EditableValue';
import { withRequest } from '@containers/RequestContext';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import Request from '@containers/Request';
import { updateBusinessMarkets } from '@utils/api/business';
import { businessMarketsSchema } from '@utils/validation/business';
import styles from './index.module.css';

const countyTableCols = [
  // { label: 'ID', cellDataGetter: (cell) => (cell?.rowData?.id ? cell.rowData.id : ''), key: 'id' },
  {
    label: 'County',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : ''),
    key: 'name'
  },
  {
    label: 'Status',
    cellDataGetter: (cell) => (cell?.rowData?.active === 'true' ? 'Active' : 'Inactive'),
    key: 'active'
  }
];
const editableCountyTableCols = [
  // { label: 'ID', cellDataGetter: (cell) => (cell?.rowData?.id ? cell.rowData.id : ''), key: 'id' },
  {
    label: 'County',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : ''),
    key: 'name'
  },
  {
    label: 'Status',
    cellRenderer: (cell) =>
      cell?.rowData?.id ? (
        <>
          <EditableValue
            isEditing
            defaultValue={cell.rowData.active}
            value={
              <StatelessRadioComponent
                as="input"
                checked={cell.rowData.active}
                radioLabel="Active"
                radioLabelTwo="Inactive"
                disabled
              />
            }
            as={RadioComponent}
            name={`counties_attributes[${cell.rowIndex}][active]`}
            radioLabel="Active"
            radioLabelTwo="Inactive"
            className={styles.radio}
          />
          <Input
            name={`counties_attributes[${cell.rowIndex}][id]`}
            defaultValue={cell.rowData.id}
            className="hidden"
          />
        </>
      ) : (
        ''
      ),
    cellDataGetter: (cell) => (cell?.rowData?.active === 'true' ? 'Active' : 'Inactive'),
    key: 'active'
  }
];

const makeBusinessDefaultValues = ({ covered_counties, ...business }, state, isActive) => ({
  ...business,
  // counties_attributes: state.counties.map(county => ({
  //   ...county,
  //   active: covered_counties.findIndex(s => s.id === county.id) !== -1 ? "true" : "false"
  // })),
  market_state_id: state.id,
  market_state_active: isActive ? 'true' : 'false'
});
const BusinessMarketPage = ({ business, state, updateData }) => {
  const isActive = business.covered_states.findIndex((s) => s.id === state.id) !== -1,
    values = makeBusinessDefaultValues(business, state, isActive);
  return (
    <ToggleForm
      className="mt-5"
      title={`${state.name} Market Profile`}
      formProps={{
        url: `/businesses/${business.id}.json`,
        defaultValues: values,
        onSubmit: updateBusinessMarkets,
        onSuccess: ({ response }) => {
          updateData(response);
        },
        to: false,
        toast: 'Business updated',
        validationSchema: businessMarketsSchema
      }}
    >
      {(isEditing) => (
        <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
          <Section title="Profile Details" collapsible={false} initialIsOpen>
            {isEditing && <Input name="market_state_id" className="hidden" />}
            <div className="lg:flex">
              <div className="lg:w-1/3 lg:pr-2 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
                <Value label="Market Name">{state.name}</Value>
              </div>
              <div className="lg:w-1/3 lg:px-4 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
                <Value label="Abbreviation">{state.abbrev}</Value>
              </div>
              <div className="lg:w-1/3 lg:px-4">
                <EditableValue
                  defaultValue={isActive}
                  value={
                    <StatelessRadioComponent
                      as="input"
                      checked={isActive}
                      radioLabel="Active"
                      radioLabelTwo="Inactive"
                      disabled
                    />
                  }
                  label="Status"
                  as={Radio}
                  isEditing={isEditing}
                  name="market_state_active"
                  radioLabel="Active"
                  radioLabelTwo="Inactive"
                />
              </div>
            </div>
          </Section>
          {/*<Section title="County Availability" initialIsOpen border >
            <div className="h-96 flex-shrink-0 overflow-x-auto">
              <Table
                rows={values.counties_attributes}
                cols={isEditing ? editableCountyTableCols : countyTableCols}
                noLink
              />
            </div>
          </Section>*/}
        </div>
      )}
    </ToggleForm>
  );
};
const BusinessMarketPageContainer = withParams(
  withRequest(({ request: { data, updateData, makeRequest }, params: { marketId } }) => (
    <Scroller check={marketId} delay={400} offset={-300}>
      <Request url={`/states/${marketId}`}>
        {(state) => <BusinessMarketPage state={state} business={data} updateData={updateData} />}
      </Request>
    </Scroller>
  ))
);

export default withPageTitle(BusinessMarketPageContainer, 'Business Market');
