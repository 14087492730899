import { classNames } from '@utils/';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminPage from './AdminPage';
import BusinessAdminPage from './BusinessAdminPage';

const TabView = () => {
  const [availableSetting, setAvailableSetting] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/dashboard/businesses') {
      setAvailableSetting(true);
    } else {
      setAvailableSetting(false);
    }
  });

  return (
    <React.Fragment>
      <section className="flex justify-between">
        <h1>Dashboard</h1>
        <div className="tabs mt-4 hidden xl:flex">
          <nav className="tab-nav">
            <NavLink
              to="all"
              className={classNames(
                'nav-link h5 uppercase w-28 text-center',
                !availableSetting ? 'active' : null
              )}
              activeclassname="active"
              onClick={() => setAvailableSetting(false)}
            >
              All
            </NavLink>
            <NavLink
              to="businesses"
              className="nav-link h5 w-28 uppercase text-center"
              onClick={() => setAvailableSetting(true)}
            >
              Businesses
            </NavLink>
          </nav>
        </div>
        <div></div>
      </section>
      <section className="flex justify-center md:flex lg:flex xl:hidden sm:flex">
        <div className="tabs mt-4">
          <nav className="tab-nav">
            <NavLink
              to="all"
              className={classNames(
                'nav-link h5 uppercase w-28 text-center',
                !availableSetting ? 'active' : null
              )}
              onClick={() => setAvailableSetting(false)}
            >
              All
            </NavLink>
            <NavLink
              to="businesses"
              className="nav-link h5 w-28 uppercase text-center"
              onClick={() => setAvailableSetting(true)}
            >
              Businesses
            </NavLink>
          </nav>
        </div>
      </section>

      <React.Fragment>
        <div className={classNames('tabs mt-4', availableSetting ? '' : null)}>
          <div className="tab-content mt-2">
            {availableSetting ? <BusinessAdminPage /> : <AdminPage />}
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default TabView;
