import React from 'react';
import Button from '@components/Button';
import styles from './index.module.css';

export const RefreshButton = (props) => (
  <Button
    onClick={() => {
      window.location.reload(false);
    }}
    {...props}
  >
    Refresh
  </Button>
);
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, info) {
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <h3 className={styles.text}>{this.props.text}</h3>
          <RefreshButton />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
