import { makeRequest } from '@utils/api';
import { getBoolFieldValue } from '@utils';

export const updateState = ({ active, ...state }, url) =>
  makeRequest('PATCH', url, {
    state: {
      ...state,
      active: getBoolFieldValue(active)
    }
  });

export const getStates = () => makeRequest('GET', '/states.json');
