import { useState } from 'react';
import Tippy from '@tippyjs/react';
import Button from '@components/Button';
import { useAppInfoState } from '@containers/AppInfo';
import { classNames } from '@utils';

export const businessSubTypeFilterAllOption = { id: 'all', name: 'All Sub-Types' };
export const useBusinessSubTypeFilter = (initialState = businessSubTypeFilterAllOption) => {
  return useState(initialState);
};

export default function BusinessSubTypeFilter({ subType, setSubType }) {
  const info = useAppInfoState();
  return (
    <div className="dropdown my-1 ml-1 lg:ml-2">
      <Tippy
        content={
          <div className="dropdown-menu">
            <button
              type="button"
              onClick={() => {
                setSubType(businessSubTypeFilterAllOption);
              }}
              className={classNames(
                'item px-2 py-1 w-full',
                subType.id === businessSubTypeFilterAllOption.id && 'active'
              )}
            >
              {businessSubTypeFilterAllOption.name}
            </button>
            {info?.business_types?.length > 0
              ? info.business_types.map((b) => (
                  <button
                    key={b.id}
                    type="button"
                    onClick={() => {
                      setSubType(b);
                    }}
                    className={classNames('item px-2 py-1 w-full', b.id === subType.id && 'active')}
                  >
                    {b.name}
                  </button>
                ))
              : null}
          </div>
        }
        theme="light-border"
        zIndex={25}
        offset={[0, 8]}
        arrow={false}
        placement="bottom-start"
        allowHTML
        interactive
        animation="shift-toward-extreme"
      >
        <Button outlined secondary>
          {subType.name}
          <span className="ml-3 la la-caret-down text-xl leading-none"></span>
        </Button>
      </Tippy>
    </div>
  );
}
