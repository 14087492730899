import { useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import FilterRow from '@components/Filter/Row';
import BusinessFilter, {
  useBusinessFilter,
  businessFilterAllOption
} from '@components/Filter/Business';
import StatusFilter, { useStatusFilter, statusOptions } from '@components/Filter/Status';
import RoleFilter, { useRoleFilter, roleOptions } from '@components/Filter/Role';
import StateFilter, { useStateFilter, stateFilterAllOption } from '@components/Filter/State';
import TextFilter from '@components/Filter/Text';
import { UserInviteModalButton, ExportDataButton } from '@components/User/ListPage';
import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import usePaginationRequest from '@containers/usePaginationRequest';
import PaginationRequestProvider, { withPaginationRequest } from '@containers/PaginationContext';
import useSort from '@containers/useSort';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import { phoneFormat, makeRoleString, getFormattedDate, getTime } from '@utils';
import { useAuthState } from '@containers/Auth';
import { classNames } from '@utils';
import styles from './index.module.css';

const userTableCols = [
  {
    label: 'Last Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.last_name : ''),
    key: 'users.last_name'
  },
  {
    label: 'First Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.first_name : ''),
    key: 'users.first_name'
  },
  {
    label: 'Role',
    cellDataGetter: (cell) => (cell?.rowData ? makeRoleString(cell.rowData) : ''),
    key: 'role',
    disableSort: true
  },
  {
    label: 'Email',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.email : ''),
    key: 'email',
    disableSort: true
  },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.state?.abbrev ? cell.rowData.state.abbrev : ''),
    key: 'users.state_id'
  },
  {
    label: 'User Type',
    cellDataGetter: (cell) => (cell?.rowData?.user_type ? cell.rowData.user_type : ''),
    key: 'users.user_type'
  },
  {
    label: 'Status',
    cellDataGetter: (cell) => cell?.rowData?.status || '',
    key: 'status',
    disableSort: true
  },
  {
    label: 'Sign Up',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.created_at) : ''),
    key: 'created_at'
  },
  {
    label: 'Affiliation',
    cellDataGetter: (cell) =>
      cell?.rowData?.business?.name
        ? cell.rowData.business.name
        : cell?.rowData?.lawyer?.business?.name
          ? cell.rowData.lawyer.business.name
          : '',
    key: 'affiliation',
    disableSort: true
  },
];
const signUpData = [
  {
    label: 'Last Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.last_name : ''),
    key: 'users.last_name'
  },
  {
    label: 'First Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.first_name : ''),
    key: 'users.first_name'
  },
  {
    label: 'Role',
    cellDataGetter: (cell) => (cell?.rowData ? makeRoleString(cell.rowData) : ''),
    key: 'role',
    disableSort: true
  },
  {
    label: 'Email',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.email : ''),
    key: 'email',
    disableSort: true
  },
  {
    label: 'State',
    cellDataGetter: (cell) => (cell?.rowData?.state?.abbrev ? cell.rowData.state.abbrev : ''),
    key: 'users.state_id'
  },
  {
    label: 'User Type',
    cellDataGetter: (cell) => (cell?.rowData?.user_type ? cell.rowData.user_type : ''),
    key: 'users.user_type'
  },
  {
    label: 'Status',
    cellDataGetter: (cell) => cell?.rowData?.status || '',
    key: 'status',
    disableSort: true
  },
  {
    label: 'Sign Up',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.created_at) : ''),
    key: 'created_at'
  },
  {
    label: 'Affiliation',
    cellDataGetter: (cell) =>
      cell?.rowData?.business?.name
        ? cell.rowData.business.name
        : cell?.rowData?.lawyer?.business?.name
          ? cell.rowData.lawyer.business.name
          : '',
    key: 'affiliation',
    disableSort: true
  },
  {
    label: 'Sign Up Data',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.sign_up_data_field : ''),
    key: 'sign_up_data_field'
  }
]

const UserDetails = ({ request, title, businessId, campaignId, noButtons = false, ...props }) => {
  const [role, setRole] = useRoleFilter();
  const [statusFilter, setStatusFilter] = useStatusFilter();
  const [businessFilter, setBusinessFilter] = useBusinessFilter();
  const [stateFilter, setStateFilter] = useStateFilter();
  const [textFilter, setTextFilter] = useState('');
  const { order, ...sort } = useSort();
  const currentUser = useAuthState();
  const getUsers = ({ users }) => {
    if (currentUser.is_org_firm) {
      if (currentUser.business.is_law_firm) {
        return users.filter((item) => item.business.is_law_firm === true);
      }
      return users.filter((item) => item.business.is_law_firm === false);
    }
    return users;
  };
  const makeQuery = useCallback(() => {
    const query = { order };
    if (role.value !== roleOptions[0].value) {
      query.role = role.value;
    }
    if (statusFilter.value !== statusOptions[0].value) {
      query.status = statusFilter.value;
    }
    if (businessId) {
      query.business_id = businessId;
    }
    else if (businessFilter.id !== businessFilterAllOption.id) {
      query.business_id = businessFilter.id;
    }
    if (stateFilter.id !== stateFilterAllOption.id) {
      query.state_id = stateFilter.id;
    }
    if (campaignId) {
      query.campaign_id = campaignId;
    }
    if (textFilter) {
      query.search = textFilter;
    }
    return query;
  }, [role, order, textFilter, businessFilter, statusFilter, stateFilter]);

  const makeQueryRequest = useCallback(() => {
    request.setQuery(makeQuery());
  }, [makeQuery]);

  useEffectAfterMount(makeQueryRequest);
  return (
    <>
      <section className="breadcrumb lg:flex items-start">
        {title && <h1>{title}</h1>}
        <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
          <TextFilter value={textFilter} onChange={setTextFilter} />
          <FilterRow>
            <RoleFilter role={role} setRole={setRole} />
            {!businessId && (
              <BusinessFilter business={businessFilter} setBusiness={setBusinessFilter} />
            )}
            <StatusFilter status={statusFilter} setStatus={setStatusFilter} />
            <StateFilter state={stateFilter} setState={setStateFilter} />
            {!noButtons && <UserInviteModalButton
              businessId={businessId}
              onSuccess={() => {
                request.setQuery(makeQuery());
              }}
            />}
            {!noButtons && <ExportDataButton
              businessId={businessId}
            />}
          </FilterRow>
        </div>
      </section>
      <div className={classNames(styles.hv70 + " card p-5 flex-shrink-0 overflow-x-auto overflow-y-hidden")}>
        <InfiniteLoaderContainer
          {...props}
          {...request}
          query={makeQuery()}
          listAccessor={getUsers}
          cols={businessId ? signUpData : userTableCols}
          {...sort}
        />
      </div>
      <Outlet />
    </>
  );
};

export const UserDetailsLayout = ({ pageSize = 25, url = '/users.json', businessId, campaignId, ...props }) => {
  let query = businessId ? { business_id: businessId } : campaignId ? { campaign_id: campaignId } : '';
  const request = usePaginationRequest(
    'users',
    url,
    pageSize,
    query
  );
  return <UserDetails request={request} businessId={businessId} campaignId={campaignId} {...props} />;
};

export const UserDetailsProviderLayout = withPaginationRequest(UserDetails);

export const UserDetailsProviderLayoutContainer = ({
  pageSize = 25,
  url = '/users.json',
  ...props
}) => (
  <PaginationRequestProvider
    listAttr="users"
    url={url}
    pageSize={pageSize}
    query={props.businessId ? { business_id: props.businessId } : undefined}
  >
    <UserDetailsProviderLayout {...props} />
  </PaginationRequestProvider>
);

const UserDetailsPageContainer = ({ title = 'User Preview', ...props }) => (
  <UserDetailsProviderLayoutContainer title={title} {...props} />
);

export default UserDetailsPageContainer;
