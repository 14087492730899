import { useRef, useEffect } from 'react';

export default function useEffectAfterMount(afterMount) {
  const isMounted = useRef();
  useEffect(() => {
    if (isMounted.current) {
      afterMount();
    } else {
      isMounted.current = true;
    }
  }, [afterMount]);
}
