import { forwardRef } from 'react';
import CurrencyInput from 'react-number-format';
import { Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

const CurrencyInputContainer = forwardRef(
  (
    {
      onChange,
      decimalScale = 2,
      thousandSeparator = true,
      fixedDecimalScale = true,
      prefix = '$',
      ...props
    },
    ref
  ) => (
    <CurrencyInput
      {...props}
      onValueChange={({ value }) => {
        onChange(value);
      }}
      decimalScale={decimalScale}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      getInputRef={ref}
    />
  )
);

const CurrencyInputController = withField(({ name, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => <CurrencyInputContainer {...field} {...props} />}
  />
));

export default CurrencyInputController;
