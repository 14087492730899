import { PublicForm } from '@components/Layouts/Public';
import Input from '@components/Form/Fields/Input';
import PasswordInput from '@components/Form/Fields/PasswordInput';
import withQueryStringValidation from '@containers/withQueryStringValidation';
import { passwordSchema, updatePasswordPageValidation } from '@utils/validation/auth';
import { updatePassword } from '@utils/api/auth';

const UpdatePassword = ({ query }) => (
  <PublicForm
    buttonText="Reset Password"
    to="/login"
    toast="Your password has been set, you may now login"
    onSubmit={updatePassword}
    defaultValues={{ reset_password_token: query?.reset_password_token }}
    validationSchema={passwordSchema}
  >
    <Input name="reset_password_token" className="hidden" />
    <PasswordInput name="password" placeholder="Password" />
    <PasswordInput name="password_confirmation" placeholder="Confirm Password" />
  </PublicForm>
);

export default withQueryStringValidation(UpdatePassword, updatePasswordPageValidation, '/login');
