import PublicLayout from '@components/Layouts/Public';
import styles from './index.module.css';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';

const DownloadPage = () => (
  <PublicLayout text="Download the mobile app" className={styles.badgeWrapper}>
    <a href="https://play.google.com/store/apps/details?id=com.turnsignl.android">
      <img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" />
    </a>
    <a href="https://itunes.apple.com/us/app/id1561625018?mt=8">
      <img src={appStore} width="150" height="60" alt="Get it on Google Play" border="0" />
    </a>
  </PublicLayout>
);

export default DownloadPage;
