import React, { useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import Button from '@components/Button';
import Modal from '@components/Modal';
import SplitRow from '@components/SplitRow';
import Input from '@components/Form/Fields/Input';
import FilterRow from '@components/Filter/Row';
import StateFilter, { useStateFilter, stateFilterAllOption } from '@components/Filter/State';
import TextFilter from '@components/Filter/Text';
import StatusFilter, {
  useStatusFilter,
  statusOptions
} from '@components/Filter/Status/BusinessStatus';
import BusinessTypeFilter, {
  useBusinessTypeFilter,
  businessTypeFilterAllOption
} from '@components/Filter/BusinessType';
import {
  useBusinessSubTypeFilter,
  businessSubTypeFilterAllOption
} from '@components/Filter/BusinessSubType';
import AutocapitalizeInput from '@components/Form/Fields/AutocapitalizeInput';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import BusinessFormWrapper from '@components/Business/Form';
import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import StateValue from '@containers/StateValue';
import StateSelect from '@containers/StateSelect';
import AppInfoSelect from '@containers/AppInfoSelect';
import PaginationRequestProvider, { withPaginationRequest } from '@containers/PaginationContext';
import useSort from '@containers/useSort';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import { createBusiness } from '@utils/api/business';
import BusinessNameFilter, {
  useBusinessNameFilter,
  businessNameFilterAllOption
} from '@components/Filter/BusinessName';
import { classNames } from '@utils';
import styles from './index.module.css';

export const BusinessSubtypeSelect = ({
  businessTypeName = 'business_type_id',
  name = 'business_subtype_id'
}) => {
  const business_type_id = useWatch({
    name: businessTypeName
  }),
    type_id = business_type_id ? Number(business_type_id) : business_type_id;
  return (
    <AppInfoSelect
      name={name}
      resource={({ business_types }) =>
        type_id ? business_types.find(({ id }) => type_id === id).business_subtypes : []
      }
      label="Business Sub-Type"
      placeholder="Business Sub-Type"
    />
  );
};

export const AddBusinessModalButton = ({ onSuccess = () => { } }) => {
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    handleSuccess = () => {
      onSuccess();
      handleClose();
    },
    handleTo = (nav, res) => {
      nav(res.id + '');
    };

  return (
    <>
      <Button onClick={handleOpen} primary className="ml-2">
        Add New
      </Button>
      <Modal
        component={BusinessFormWrapper}
        isOpen={isOpen}
        title="Add Business"
        url="/businesses.json"
        onSubmit={createBusiness}
        onSuccess={handleSuccess}
        close={handleClose}
        toast="Business has been added"
        to={handleTo}
        form
      >
        <SplitRow>
          <AppInfoSelect
            name="business_type_id"
            resource="business_types"
            label="Business Type"
            placeholder="Business Type"
            required
          />
          <BusinessSubtypeSelect
            name="business_subtype_id"
            resource="business_subtypes"
            label="Business Sub-Type"
            placeholder="Business Sub-Type"
            required
          />
        </SplitRow>
        <AutocapitalizeInput label="Name" name="name" placeholder="Name" requried />
        <PhoneNumberInput name="phone" placeholder="Phone" label="Phone" />
        <Input name="address1" label="Address" placeholder="Address 1" requried />
        <Input name="address2" placeholder="Address 2" />
        <SplitRow>
          <Input name="city" placeholder="City" label="City" required />
          <StateSelect name="state_id" placeholder="State" label="State" required />
          <Input
            type="number"
            pattern="[0-9]*"
            min={0}
            inputMode="numeric"
            name="zip_code"
            placeholder="Zip Code"
            label="Zip Code"
            required
          />
        </SplitRow>
      </Modal>
    </>
  );
};

const businessTableCols = [
  {
    label: 'Business Name',
    cellDataGetter: (cell) => cell?.rowData?.name || '',
    key: 'businesses.name'
  },
  {
    label: 'Description',
    cellDataGetter: (cell) => cell?.rowData?.description || '',
    key: 'description',
    disableSort: true
  },
  {
    label: 'Business Type',
    cellDataGetter: (cell) => cell?.rowData?.business_type?.name || '',
    key: 'businesses.business_type_id'
  },
  {
    label: 'Business Sub-Type',
    cellDataGetter: (cell) => cell?.rowData?.business_subtype?.name || '',
    key: 'businesses.business_subtype_id'
  },
  { label: 'City', cellDataGetter: (cell) => cell?.rowData?.city || '', key: 'businesses.city' },
  {
    label: 'State',
    cellRenderer: (cell) =>
      cell?.rowData?.state_id ? <StateValue id={cell.rowData.state_id} /> : '',
    cellDataGetter: (cell) => cell?.rowData?.state_id || '',
    key: 'businesses.state_id'
  },
  {
    label: 'Status',
    cellRenderer: (cell) => (cell?.rowData?.status ? cell.rowData.status : ''),
    cellDataGetter: (cell) => cell?.rowData?.status || '',
    key: 'businesses.status'
  }
];
const makeQuery = (
  textFilter,
  order,
  stateFilter,
  typeFilter,
  subTypeFilter,
  statusFilter,
  businessNameFilter
) => {
  const query = { order };
  if (stateFilter.id !== stateFilterAllOption.id) {
    query.state_ids = [stateFilter.id];
  }
  if (statusFilter.value !== statusOptions[0].value) {
    query.status = statusFilter.value;
  }
  if (typeFilter.id !== businessTypeFilterAllOption.id) {
    query.business_type_ids = [typeFilter.id];
  }
  if (subTypeFilter.id !== businessSubTypeFilterAllOption.id) {
    query.business_subtype_ids = [subTypeFilter.id];
  }
  if (businessNameFilter.id !== businessNameFilterAllOption.id) {
    query.name = [businessNameFilter.name];
  }
  if (textFilter) {
    query.search = textFilter;
  }
  return query;
};
const listAccessor = ({ businesses }) => businesses;
const BusinessDetailsLayout = withPaginationRequest(({ request }) => {
  const [textFilter, setTextFilter] = useState(''),
    [businessNameFilter, setBusinessNameFilter] = useBusinessNameFilter(),
    [stateFilter, setStateFilter] = useStateFilter(),
    [statusFilter, setStatusFilter] = useStatusFilter(),
    [typeFilter, setTypeFilter] = useBusinessTypeFilter(),
    [subTypeFilter, setSubTypeFilter] = useBusinessSubTypeFilter(),
    { order, ...sort } = useSort(),
    makeRequest = useCallback(() => {
      request.setQuery(
        makeQuery(
          textFilter,
          order,
          stateFilter,
          typeFilter,
          subTypeFilter,
          statusFilter,
          businessNameFilter
        )
      );
    }, [
      textFilter,
      order,
      stateFilter,
      typeFilter,
      subTypeFilter,
      statusFilter,
      businessNameFilter
    ]);
  useEffectAfterMount(makeRequest);
  return (
    <>
      <section className="breadcrumb lg:flex items-start">
        <h1>Business Preview</h1>
        <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
          <TextFilter value={textFilter} onChange={setTextFilter} />
          <FilterRow>
            <BusinessNameFilter name={businessNameFilter} setName={setBusinessNameFilter} />
            <BusinessTypeFilter type={typeFilter} setType={setTypeFilter} />
            {/*<BusinessSubTypeFilter subType={subTypeFilter} setSubType={setSubTypeFilter} />*/}
            <StateFilter state={stateFilter} setState={setStateFilter} />
            <StatusFilter status={statusFilter} setStatus={setStatusFilter} />

            <AddBusinessModalButton />
            {/*<Button type="button" secondary outlined className="ml-2">Download</Button>*/}
          </FilterRow>
        </div>
      </section>
      <div className={classNames(styles.hv70 + " card p-5 flex-shrink-0 overflow-x-auto")}>
        <InfiniteLoaderContainer
          {...request}
          query={makeQuery(
            textFilter,
            order,
            stateFilter,
            typeFilter,
            subTypeFilter,
            statusFilter,
            businessNameFilter
          )}
          listAccessor={listAccessor}
          cols={businessTableCols}
          {...sort}
        />
      </div>
      <Outlet />
    </>
  );
});

export const BusinessDetailsLayoutContainer = ({
  pageSize = 25,
  url = '/businesses.json',
  ...props
}) => (
  <PaginationRequestProvider
    listAttr="businesses"
    url={url}
    pageSize={pageSize}
    query={props.businessId ? { business_id: props.businessId } : undefined}
  >
    <BusinessDetailsLayout {...props} />
  </PaginationRequestProvider>
);

export default BusinessDetailsLayoutContainer;
