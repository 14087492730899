import { useState, useEffect, useRef, forwardRef } from 'react';
import { NavLink, Link, useMatch, useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Button from '@components/Button';
import LogoutButton from '@containers/LogoutButton';
import { useAuthState } from '@containers/Auth';
import withIsMobile, { useWidthState } from '@containers/withIsMobile';
import styles from './index.module.css';
import logo from '@assets/images/TurnSignl_TurnSignl-Icon-Primary.svg';
import logoWithText from '@assets/images/logo.png';
import { classNames, makeRoleString } from '@utils';
import LocalStorageService from '@utils/storage';
import { MENU_ADMIN, MENU_ORG_ADMIN } from './menus';
import { FaFacebookF, FaTiktok, FaLinkedinIn, FaInstagram } from "react-icons/fa";

const localStorageService = LocalStorageService.getService();

const isDarkFromStorage = localStorageService.getTheme() === 'dark';
const ThemeSwitch = () => {
  const [isDark, setIsDark] = useState(isDarkFromStorage),
    handleInputChange = (e) => setIsDark(e.target.checked);
  useEffect(() => {
    const root = document.documentElement;
    if (isDark) {
      root.classList.remove('light');
      root.classList.add('dark');
      localStorageService.setTheme('dark');
    } else {
      root.classList.remove('dark');
      root.classList.add('light');
      localStorageService.setTheme('light');
    }
  }, [isDark]);

  return <input type="checkbox" value={isDark} checked={isDark} onChange={handleInputChange} />;
};
const checkFullscreen = () => {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    return true;
  }

  return false;
};

const FullscreenToggle = forwardRef(({ width }, ref) => {
  const [isFullscreen, setIsFullscreen] = useState(),
    isMounted = useRef(),
    handleClick = () => setIsFullscreen((prevIsFullscreen) => !prevIsFullscreen);
  useEffect(() => {
    if (isMounted.current) {
      if (isFullscreen && !checkFullscreen()) {
        const element = document.documentElement;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [isFullscreen]);
  return (
    <button
      type="button"
      onClick={handleClick}
      ref={ref}
      className={classNames(
        'hidden lg:inline-block btn-link ml-3 px-2 text-2xl leading-none la',
        isFullscreen ? 'la-compress-arrows-alt' : 'la-expand-arrows-alt'
      )}
    ></button>
  );
});
const TooltipTippy = ({ children, content }) => (
  <Tippy
    content={content}
    theme="light-border tooltip"
    touch={['hold', 500]}
    offset={[0, 12]}
    interactive
    animation="scale"
  >
    {children}
  </Tippy>
);

const UserDropdown = ({ user }) => (
  <Tippy
    content={
      <div className="custom-dropdown-menu w-64">
        <div className="p-5">
          <h5 className="uppercase">
            {user.first_name} {user.last_name}
          </h5>
          <p>{makeRoleString(user)}</p>
        </div>
        <hr />
        <div className="p-5">
          <LogoutButton className="flex items-center text-gray-700 dark:text-gray-500 hover:text-primary dark:hover:text-primary">
            <span className="la la-power-off text-2xl leading-none mr-2"></span>
            Logout
          </LogoutButton>
        </div>
      </div>
    }
    theme="light-border"
    zIndex={25}
    arrow={false}
    offset={[0, 8]}
    placement="bottom-start"
    allowHTML
    interactive
    animation="shift-toward-extreme"
  >
    <button className="flex items-center ml-4 text-gray-700">
      <span className="avatar">
        {user.first_name.charAt(0)}
        {user.last_name.charAt(0)}
      </span>
    </button>
  </Tippy>
);

const AuthButton = () => {
  const match = useMatch('/login');
  return (
    !match && (
      <Button as={Link} to="/login" primary className="ml-5">
        Login
      </Button>
    )
  );
};
const Header = withIsMobile(({ isMobile, showSocialMedia }) => {
  const user = useAuthState();
  const [isMenuOpen, setIsMenuOpen] = useState(!isMobile);
  const [menu, setMenu] = useState([]);
  const toggleMenu = () => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (user) {
      if (user.is_admin) setMenu(MENU_ADMIN);
      if (user.is_org_admin) setMenu(MENU_ORG_ADMIN);
    }
  }, [user]);

  return (
    <>
      <header className={`top-bar ${showSocialMedia ? styles.headerContainer : ''}`}>
        {user?.is_admin && (
          <button
            type="button"
            className="menu-toggler la la-bars"
            data-toggle="menu"
            onClick={toggleMenu}
          ></button>
        )}

        {showSocialMedia ?
          <span className="brand flex items-center" onClick={() => { window.open("https://turnsignl.com", "_self") }}>
            <img src={logoWithText} className={styles.logoWithText} />
          </span>
          :
          <span className="brand flex items-center">
            <img src={logo} className={styles.logo} />
            {!isMobile && 'TurnSignl'}
          </span>}
        {showSocialMedia ?
          <div className="flex items-center ml-auto">
            <div className={styles.socialContainer}>
              <a href='https://www.instagram.com/turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1rem' }}><FaInstagram /></a>
              <a href='https://www.facebook.com/TurnSignl/' target='_blank' style={{ color: 'black', fontSize: '1rem' }}><FaFacebookF /></a>
              <a href='https://www.linkedin.com/company/turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1rem' }}><FaLinkedinIn /></a>
              <a href='https://www.tiktok.com/@turnsignl/' target='_blank' style={{ color: 'black', fontSize: '1rem' }}><FaTiktok /></a>
            </div>
          </div>
          :
          <div className="flex items-center ml-auto">
            <TooltipTippy content="Toggle Dark Mode">
              <label className="switch switch_outlined">
                <ThemeSwitch />
                <span></span>
              </label>
            </TooltipTippy>
            <TooltipTippy content="Fullscreen">
              <FullscreenToggle />
            </TooltipTippy>

            {user ? (
              <div className="dropdown">
                <UserDropdown user={user} />
              </div>
            ) : (
              <AuthButton />
            )}
          </div>
        }
      </header>
      {(user?.is_admin || user?.is_org_admin) && (
        <aside className={classNames('menu-bar menu-sticky', !isMenuOpen && 'menu-hidden')}>
          <div className="menu-items">
            {menu.map((el, index) => (
              <NavLink to={el.link} className="link" activeclassname="active" key={index}>
                <span className={`icon la ${el.icon}`}></span>
                <span className="title">{el.title}</span>
              </NavLink>
            ))}
          </div>
        </aside>
      )}

      {/* {(user?.is_admin || user?.is_org_admin) && (
        <aside className={classNames('menu-bar menu-sticky', !isMenuOpen && 'menu-hidden')}>
          <div className="menu-items">
            <NavLink to="/users" className="link" activeClassName="active">
              <span className="icon la la-users"></span>
              <span className="title">Users</span>
            </NavLink>
            {user.is_admin && (
              <>
                <NavLink to="/incidents" className="link" activeClassName="active">
                  <span className="icon la la-phone-volume"></span>
                  <span className="title">Incidents</span>
                </NavLink>
                <NavLink to="/attorneys" className="link" activeClassName="active">
                  <span className="icon la la-tv"></span>
                  <span className="title">Attorney Monitor</span>
                </NavLink>
                <NavLink to="/markets" className="link" activeClassName="active">
                  <span className="icon la la-globe"></span>
                  <span className="title">Market Profile</span>
                </NavLink>
                <NavLink to="/campaigns" className="link" activeClassName="active">
                  <span className="icon la la-globe"></span>
                  <span className="title">Campaigns</span>
                </NavLink>
              </>
            )}
            <NavLink
              to={user.is_admin ? '/businesses' : '/business'}
              className="link"
              activeClassName="active"
            >
              <span className="icon la la-building"></span>
              <span className="title">Business Profile</span>
            </NavLink>
            {user.is_admin && (
              <NavLink to="/leads" className="link" activeClassName="active">
                <span className="icon la la-binoculars"></span>
                <span className="title">Leads</span>
              </NavLink>
            )}
          </div>
        </aside>
      )} */}
    </>
  );
}, 640);

export default Header;
