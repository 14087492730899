import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';

export const activeOptions = [
  { value: 'all', label: 'All Statuses' },
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
];
export const useActiveFilter = (initialState = activeOptions[0]) => {
  return useState(initialState);
};
const ActiveFilter = ({ active, setActive }) => (
  <Dropdown options={activeOptions} value={active} setValue={setActive} />
);

export default ActiveFilter;
