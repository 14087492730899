import React, { useEffect } from 'react';
import PublicLayout from '@components/Layouts/Public';
import googlePlay from '@assets/images/google-play-badge-300x89.png';
import appStore from '@assets/images/download-on-the-app-store.png';
import styles from './index.module.css';
import { useAuthState } from '@containers/Auth';

const GetAppPage = ({ logout }) => {
  const loginUser = useAuthState();
  useEffect(() => {
    if (['iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) !== -1) {
     // window.location = 'com.turnsignl://get_app';
    }
    if (/Android/.test(window.navigator.userAgent)) {
      window.location = 'turnsignl://get_app';
    }
  }, []);
  return (
    <React.Fragment>
      {loginUser?.status === 'Locked' ? (
        <PublicLayout text="Account Deactivated"></PublicLayout>
      ) : (
        <PublicLayout
          text="Get Our Mobile App"
          subtext={
            logout
              ? 'You will have to login to the TurnSignl mobile app to continue'
              : 'Your account has been confirmed, login with the TurnSignl mobile app to continue'
          }
          className={styles.badgeWrapper}
        >
          <a href="https://play.google.com/store/apps/details?id=com.turnsignl.android">
            <img src={googlePlay} width="150" height="60" alt="Get it on Google Play" border="0" />
          </a>
          <a href="https://itunes.apple.com/us/app/id1561625018?mt=8">
            <img src={appStore} width="150" height="60" alt="Get it on Google Play" border="0" />
          </a>
        </PublicLayout>
      )}
    </React.Fragment>
  );
};

export default GetAppPage;
