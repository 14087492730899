import { createContext, useContext } from 'react';
import useRequest from '@containers/useRequest';
import { RequestLoader } from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';

const RequestContext = createContext();

export function useRequestState() {
  const context = useContext(RequestContext);
  if (context === undefined) {
    throw new Error('useRequestState must be used within a RequestProvider');
  }
  return context;
}

export function withRequest(WrappedComponent) {
  return (props) => {
    const request = useRequestState();
    return <WrappedComponent {...props} request={request} />;
  };
}

export default function RequestProvider({ children, url }) {
  const request = useRequest(url);
  if (request.status === 'loading') {
    return <RequestLoader />;
  }
  if (request.status === 'error') {
    return <ErrorDisplay error={request.error} />;
  }
  return <RequestContext.Provider value={request}>{children}</RequestContext.Provider>;
}
