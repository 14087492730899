import Tippy from '@tippyjs/react';
import Button from '@components/Button';
import useAccessorFunction from '@containers/useAccessorFunction';
import { classNames } from '@utils';

const Dropdown = ({
  options,
  setValue,
  value,
  valueAccessor = 'value',
  labelAccessor = 'label'
}) => {
  const valueFunc = useAccessorFunction(valueAccessor),
    labelFunc = useAccessorFunction(labelAccessor);

  return (
    <div className="dropdown my-1 ml-1 lg:ml-2">
      <Tippy
        content={
          <div className="dropdown-menu scrollbar">
            {options?.map((r) => (
              <button
                key={valueFunc(r)}
                type="button"
                onClick={() => {
                  setValue(r);
                }}
                className={classNames('item px-2 py-1 w-full', r === value && 'active')}
              >
                {labelFunc(r)}
              </button>
            ))}
          </div>
        }
        theme="light-border"
        zIndex={25}
        offset={[0, 8]}
        arrow={false}
        placement="bottom-start"
        allowHTML
        interactive
        animation="shift-toward-extreme"
      >
        <Button outlined secondary>
          {labelFunc(value)}
          <span className="ml-3 la la-caret-down text-xl leading-none"></span>
        </Button>
      </Tippy>
    </div>
  );
};

export default Dropdown;
