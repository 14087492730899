import React from 'react';
import { Helmet } from 'react-helmet';

export default function withPageTitle(WrappedComponent, title) {
  return (props) => (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
}
