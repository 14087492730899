import Table from '@components/Table';
import { phoneFormat, makeRoleString, getFormattedDate, getTime } from '@utils';

const userTableCols = [
  'Last Name',
  'First Name',
  'Role',
  'Email',
  'State',
  'Status',
  'Affiliation'
];
const UsersListPage = ({ users, cols = userTableCols }) => (
  <Table
    cols={cols}
    rows={users}
    renderRow={(user) => (
      <>
        <div className="td text-center">{user.last_name}</div>
        <div className="td text-center">{user.first_name}</div>
        <div className="td text-center">{makeRoleString(user)}</div>
        <div className="td text-center">{user.email}</div>
        <div className="td text-center">{user.state?.abbrev}</div>
        <div className="td text-center">{user.status}</div>
        <div className="td text-center">
          {user.is_admin ? 'Admin' : user.needs_subscription ? 'Signed Up' : 'Subscribed'}
        </div>
        <div className="td text-center"></div>
      </>
    )}
  />
);

export default UsersListPage;
