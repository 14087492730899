import { useState, useCallback } from 'react';
import { RequestLoader, Loader } from '@components/Loader';
import Button from '@components/Button';
import Section from '@components/Section';
import Modal from '@components/Modal';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import Request from '@containers/Request';
import useInterval from '@containers/useInterval';
import { withRequest } from '@containers/RequestContext';
import { classNames } from '@utils';
import { createImport, getImportStatus, updateImport } from '@utils/api/import';
import styles from './index.module.css';

const justAddedStatus = {
  import_added: true,
  import_complete: false,
  import_count: 0,
  import_report: null,
  import_valid: false,
  import_validated: false,
  importing_users: false
};

const shouldPollStatus = (status) =>
  status.import_added &&
  (!status.import_validated || (status.importing_users && !status.import_complete));
const shouldUploadFile = (status) =>
  (status.import_validated && !status.import_valid) ||
  status.import_complete ||
  !status.import_added;
const UserImportPage = withRequest(({ request: { data }, businessId, initialStatus }) => {
  const [file, setFile] = useState(),
    [status, setStatus] = useState(initialStatus),
    [modal, setModal] = useState(),
    openConfirmationModal = (text) => () => {
      setModal(text);
    },
    handleUploadFile = async () => {
      try {
        setStatus(justAddedStatus);
        await createImport(businessId, file);
        setFile();
        setStatus(await getImportStatus(businessId));
      } catch (e) {}
    },
    handleModalConfirmation = () => {
      setModal(false);
      handleUploadFile();
    },
    handleConfirmUpload = async () => {
      try {
        await updateImport(businessId);
        setStatus(await getImportStatus(businessId));
      } catch (e) {}
    },
    closeModal = () => {
      setModal(false);
    },
    makeStatusRequest = useCallback(async () => {
      setStatus(await getImportStatus(businessId));
    }, [businessId]);
  useInterval(makeStatusRequest, shouldPollStatus(status) ? 10000 : null);
  return (
    <>
      <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
        <Section title="File Upload Manager" collapsible={false} initialIsOpen border>
          <div className={`${styles.label} text-gray-700 dark:text-gray-500`}>
            Please select the Business File to upload
          </div>
          <div className="lg:flex lg:items-center">
            <div>
              <input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </div>
            <Button
              type="button"
              className="ml-5 lg:my-2 lg:ml-0"
              primary
              disabled={!file}
              onClick={
                shouldUploadFile(status)
                  ? data.organization_subscription?.subscription_status === 'ACTIVE'
                    ? handleUploadFile
                    : openConfirmationModal(
                        "This business doesn't have a subscription. Are you sure you want to import users without a subscription?"
                      )
                  : openConfirmationModal('Importing a new file will cancel any incomplete imports')
              }
            >
              Import File & Review
            </Button>
            {/*<div>Download File Template Here</div>*/}
          </div>
        </Section>
        {status.import_added && (
          <Section title="Upload Results" collapsible={false} initialIsOpen border>
            {status.import_validated ? (
              <>
                <div>
                  {status.import_complete ? (
                    <div className="alert alert_success">
                      <b>SUCCESS</b>&nbsp;&nbsp;Choose a file and press the 'IMPORT FILE & REVIEW'
                      button to import another file.
                    </div>
                  ) : status.import_valid ? (
                    <div className="alert alert_success mt-5">
                      <b>SUCCESS</b>&nbsp;&nbsp;Your file has been imported and validated. Press the
                      'UPLOAD USERS' button at the bottom of the page to begin uploading your users.
                    </div>
                  ) : (
                    <div className="alert alert_danger mt-5">
                      <b>INVALID</b>&nbsp;&nbsp;Your file has been imported but was found to be
                      invalid. Choose a valid import file and press the 'IMPORT FILE & REVIEW'
                      button to continue.
                    </div>
                  )}
                  <div
                    className={`${styles.report} card scrollbar max-h-48 p-5 text-gray-700 dark:text-gray-500 my-5 report`}
                  >
                    {status.import_report}
                  </div>
                  {/*<table className="table w-full mt-3">
                      <thead>
                          <tr>
                              <th className="text-left uppercase">Description</th>
                              <th className="text-center uppercase">Number Of</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr className="tr" >
                          <td className="td text-left">Total Number of Users Processed</td>
                          <td className="td text-center">{status?.import_count || "0"}</td>
                        </tr>
                      </tbody>
                  </table>*/}
                </div>

                {status.import_valid && !status.import_complete && (
                  <div className="w-full flex items-center flex-col lg:justify-start lg:flex-row-reverse  mt-4">
                    <button
                      onClick={handleConfirmUpload}
                      disabled={status.importing_users}
                      type="button"
                      className="btn btn_primary uppercase"
                    >
                      Upload Users
                    </button>
                    {status.importing_users && (
                      <div
                        className={`${styles.uploadingUsers} flex flex-col items-center text-gray-700 dark:text-gray-500 mt-2 lg:mt-0 lg:mr-2`}
                      >
                        <Loader className="mb-3" />
                        Uploading Users...
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <RequestLoader>
                <div className={`${styles.loadingText} text-gray-700 dark:text-gray-500 mt-2`}>
                  {status.import_added ? 'Validating File...' : 'Importing File...'}
                </div>
              </RequestLoader>
            )}
          </Section>
        )}
      </div>
      <Modal
        isOpen={modal ? true : false}
        title="Are you sure?"
        onContinueClick={handleModalConfirmation}
        continueText="Yes"
        cancelText="No"
        close={closeModal}
        cancel={closeModal}
      >
        {modal}
      </Modal>
    </>
  );
});

const UserImportPageContainer = withParams((props) => {
  const businessId = props.params.businessId ? props.params.businessId : props.businessId;
  return (
    <Request url={`/businesses/${businessId}/import.json`}>
      {(status) => <UserImportPage businessId={businessId} initialStatus={status} />}
    </Request>
  );
});

export default withPageTitle(UserImportPageContainer, 'File Upload Manager');
