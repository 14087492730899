import React, { useCallback, useEffect, useState } from 'react';
import { BUSINESS_ADMIN_SECTIONS, MONTH_NAMES } from './mock';
import Card from './card';
import { getDashBoadData } from '@utils/api/dashboad';
import { RequestLoader } from '@components/Loader';
import useEventListener from '@containers/useEventListener';

const MIN_CARD_WIDTH = 340;
const BusinessLayout = ({ businessId }) => {
  const [sections, setSections] = useState(null);
  const [isShowLoadingBar, setIsShowLoadingBar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const handleSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEventListener('resize', handleSizeChange);

  useEffect(() => {
    if (width < 980) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const setData = (sectionsData, data) => {
    if (data) {
      const tempSections = [];
      for (const section of sectionsData) {
        const tempCards = [];
        for (const card of section.cards) {
          switch (card.type) {
            case 'board_user_count': {
              card.count = data.active_user_count;
              break;
            }
            case 'board_new_user_count': {
              card.count = data.new_user_count;
              break;
            }
            case 'board_incident_count': {
              card.count = data.incident_count;
              break;
            }
            case 'column_chart_active_users': {
              card.data.series[0].data = [];
              card.data.options.xaxis.categories = [];
              for (const item of data.monthly_active_users) {
                card.data.options.xaxis.categories.push(item.month);
                card.data.series[0].data.push(item.count);
              }
              break;
            }
            case 'column_chart_new_users': {
              card.data.series[0].data = [];
              card.data.options.xaxis.categories = [];
              for (const item of data.monthly_new_users) {
                card.data.options.xaxis.categories.push(item.month);
                card.data.series[0].data.push(item.count);
              }
              break;
            }
            case 'column_chart_incidents': {
              card.data.series = [];
              card.data.options.xaxis.categories = [];
              const chartData = [];
              const newArray = [];
              for (const item of data.monthly_incidents) {
                const index = MONTH_NAMES.indexOf(item.month);
                newArray.splice(index, 1, 0);
              }

              for (const item of data.monthly_incidents) {
                if (item.types?.length > 0) {
                  for (let i = 0; i < item.types.length; i++) {
                    const tempArray = [...newArray];
                    if (chartData.find((el) => el.name === item.types[i].type)) continue;
                    chartData.push({
                      name: item.types[i].type,
                      data: tempArray
                    });
                  }
                }
              }
              let index = 0;
              for (const item of data.monthly_incidents) {
                card.data.options.xaxis.categories.push(item.month);
                if (item.types?.length > 0) {
                  for (let i = 0; i < item.types.length; i++) {
                    const currentChartData = chartData.find((el) => el.name === item.types[i].type);
                    if (currentChartData) {
                      currentChartData.data.splice(index, 1, item.types[i].count);
                    }
                  }
                }

                index++;
              }
              card.data.series = chartData;
              break;
            }
            case 'pie_chart_state': {
              card.data.series = [];
              card.data.options.labels = [];
              for (const item of data.users_by_state) {
                card.data.series.push(item.count);
                card.data.options.labels.push(item.state);
              }
              break;
            }
            case 'pie_chart_incident_state': {
              card.data.series = [];
              card.data.options.labels = [];
              for (const item of data.incidents_by_state) {
                card.data.series.push(item.count);
                card.data.options.labels.push(item.state);
              }
              break;
            }
            case 'pie_chart_incident_type': {
              card.data.series = [];
              card.data.options.labels = [];
              for (const item of data.incidents_by_type) {
                card.data.series.push(item.count);
                card.data.options.labels.push(item.type);
              }
              break;
            }
            default: {
              break;
            }
          }
          tempCards.push(card);
        }
        tempSections.push(section);
      }
      setSections(tempSections);
    }
  };

  const getData = async (id, cardSections = BUSINESS_ADMIN_SECTIONS) => {
    try {
      setIsShowLoadingBar(true);
      const endpoint = `/businesses/${id}/stats.json`;
      const data = await getDashBoadData(endpoint);
      setData(cardSections, data);
      setIsShowLoadingBar(false);
    } catch (error) {}
  };

  useEffect(() => {
    getData(businessId);
  }, [businessId]);

  return (
    <React.Fragment>
      <section className="mb-10">
        <h1>Dashboard</h1>
      </section>
      {isShowLoadingBar ? (
        <RequestLoader />
      ) : (
        <div>
          {sections &&
            (!isMobile ? (
              <React.Fragment>
                <div className="border-b border-gray-300">
                  <div className="flex bg-transparent justify-around">
                    <Card
                      businessId={businessId}
                      type="title"
                      width={width / 3.5}
                      currentSection={sections[0]}
                    />
                  </div>
                </div>
                <div className="border-b-2 border-gray-300">
                  <div className="flex bg-transparent justify-around">
                    <Card
                      businessId={businessId}
                      type="value"
                      width={width / 3.5}
                      currentSection={sections[0]}
                    />
                  </div>
                </div>
                <div className="flex bg-transparent justify-around mt-12">
                  <Card
                    businessId={businessId}
                    type=""
                    width={width / 3.5}
                    currentSection={sections[1]}
                  />
                </div>
                <div className="flex bg-transparent justify-around mt-8">
                  <Card
                    businessId={businessId}
                    type=""
                    width={width / 3.5}
                    currentSection={sections[2]}
                  />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="grid bg-transparent justify-center">
                  <Card
                    businessId={businessId}
                    type="title"
                    width={MIN_CARD_WIDTH}
                    isMobile={isMobile}
                    currentSection={sections[0]}
                  />
                </div>
                <div className="grid bg-transparent justify-center mt-5">
                  <Card
                    businessId={businessId}
                    type=""
                    width={MIN_CARD_WIDTH}
                    currentSection={sections[1]}
                  />
                </div>
                <div className="grid bg-transparent justify-center">
                  <Card
                    businessId={businessId}
                    type=""
                    width={MIN_CARD_WIDTH}
                    currentSection={sections[2]}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default BusinessLayout;
