import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

export const SelectComponent = forwardRef(({ options, placeholder, ...props }, ref) => (
  <div className="custom-select">
    <select {...props} className="form-control" ref={ref}>
      {placeholder && (
        <option value="" disabled={props.unclearable}>
          {placeholder}
        </option>
      )}
      {options?.length > 0 &&
        options.map((option, i) => (
          <option key={option.value || i} value={option.value}>
            {option.label}
          </option>
        ))}
    </select>
    <div className="custom-select-icon la la-caret-down"></div>
  </div>
));

const SelectContainer = withField(({ name, ...props }) => (
  <Controller name={name} render={({ field }) => <SelectComponent {...field} {...props} />} />
));

export default SelectContainer;
