import { useState, useCallback, useEffect, useRef } from 'react';
import Tippy from '@tippyjs/react';
import LeadList from '@components/Lead/List';
import FilterRow from '@components/Filter/Row';
import Loader from '@components/Loader';
import Button from '@components/Button';
import StateFilter, { useStateFilter, stateFilterAllOption } from '@components/Filter/State';
import { useAppInfoState } from '@containers/AppInfo';
import withPageTitle from '@containers/withPageTitle';
import usePaginationRequest from '@containers/usePaginationRequest';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import { exportLeads } from '@utils/api/user';
import { classNames } from '@utils';
import styles from './index.module.css';

export const ExportDataButton = () => {
  const [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    handleClick = async () => {
      setLoading(true);
      const csvFile = await exportLeads();
      setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      setLoading(false);
    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
      setFileLink('');
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleClick} type="button" secondary outlined className="ml-2 my-1">
        Export
      </Button>
      <a href={fileLink} ref={linkRef} download="turnsignl-leads.csv" className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};


const LeadListPage = ({ pageSize = 50, title = 'Leads', url = '/leads.json' }) => {
  const request = usePaginationRequest('leads', url, pageSize),
    [state, setState] = useStateFilter(),
    makeQuery = useCallback(() => {
      const newQuery = {};
      if (state.id !== stateFilterAllOption.id) {
        newQuery.state_ids = state.id;
      }
      return newQuery;
    }, [state]),
    makeQueryRequest = useCallback(() => {
      request.setQuery(makeQuery());
    }, [makeQuery]);

  useEffectAfterMount(makeQueryRequest);
  return (
    <>
      <section className="breadcrumb lg:flex items-start">
        {title && <h1>{title}</h1>}
        <FilterRow className="ml-auto">
          <div className="flex mt-5 lg:mt-0 items-center">
            <StateFilter state={state} setState={setState} />
          </div>
	  <ExportDataButton />
        </FilterRow>
      </section>
      <div className={classNames('card p-5 flex-shrink-0 overflow-x-auto', styles.listWrapper)}>
        <LeadList {...request} query={makeQuery()} noLink />
      </div>
    </>
  );
};

export default withPageTitle(LeadListPage, 'Leads');
