import Form from '@components/Form';
import { stateSchema } from '@utils/validation/state';

export const StateFormWrapper = ({ children, ...props }) => (
  <Form validationSchema={stateSchema} {...props}>
    {children}
  </Form>
);
const StateForm = ({ children, ...props }) => (
  <StateFormWrapper {...props}>{children}</StateFormWrapper>
);

export default StateForm;
