import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import useAppInfoOptions from '@containers/useAppInfoOptions';

export const businessTypeFilterAllOption = { id: 'all', name: 'All Types' };
export const useBusinessTypeFilter = (initialState = businessTypeFilterAllOption) => {
  return useState(initialState);
};

const initialOptions = [businessTypeFilterAllOption];
export default function BusinessTypeFilter({ type, setType }) {
  const options = useAppInfoOptions('business_types', initialOptions);
  return (
    <Dropdown
      options={options}
      value={type}
      setValue={setType}
      valueAccessor="id"
      labelAccessor="name"
    />
  );
}
