import AppInfoSelect from '@containers/AppInfoSelect';
import { useAuthState } from '@containers/Auth';

const useValidStates = (states) => {
  const user = useAuthState(),
    isJmg = user.email.includes('@jmg.rocks');

  return states.filter((state) => isJmg || !state.jmg_only || user?.state?.id === state.id);
};

const StateSelect = ({
  name = 'state_id',
  labelAccessor = 'abbrev',
  valueAccessor = 'id',
  root,
  ...props
}) => (
  <AppInfoSelect
    {...props}
    resource={({ states }) => states}
    labelAccessor={labelAccessor}
    valueAccessor={valueAccessor}
    name={root ? `${root}[${name}]` : name}
  />
);

export default StateSelect;
