import { classNames } from '@utils';
import { useFormContext, Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';

const Checkbox = ({ onChange, ...props }) => (
  <input
    {...props}
    checked={props.value}
    onChange={(e) => {
      onChange(e.target.checked);
    }}
    type="checkbox"
  />
);

const ControlledCheckbox = ({ as: Component = 'input', ...props }) => {
  const { register } = useFormContext();
  return <input {...props} type="checkbox" {...register(props.name)} />;
};
export const Switch = ({ className, as: WrappedComponent = Checkbox, ...props }) => (
  <div className={classNames('switch', props.disabled && 'cursor-default', className)}>
    <WrappedComponent {...props} />
    <span />
  </div>
);
export const ControlledSwitch = withField((props) => (
  <Controller
    {...props}
    render={({ onChange, ...f }) => (
      <Switch
        {...f}
        checked={f.value}
        onChange={(e) => {
          onChange(e.target.checked ? true : false);
        }}
        as="input"
        type="checkbox"
      />
    )}
  />
));

const RegisteredSwitch = withField((props) => (
  <Switch {...props} checked={props.value} as={ControlledCheckbox} />
));

export default RegisteredSwitch;
