import { useEffect } from 'react';
import Animate from '@components/Animate';
import Portal from '@components/Portal';
import KeyPressListener from '@components/KeyPressListener';
import { classNames } from '@utils';
import styles from './index.module.css';

export const Header = ({ title, close, locked }) => (
  <div className="modal-header">
    <h2 className="modal-title">{title}</h2>
    {!locked && (
      <button
        onClick={close}
        type="button"
        className="close la la-times"
        data-dismiss="modal"
      ></button>
    )}
  </div>
);

export const Footer = ({
  form,
  continueText = 'Continue',
  cancelText = 'Close',
  onContinueClick,
  onCancelClick
}) => (
  <div className="modal-footer">
    <div className="flex ml-auto">
      <button
        type="button"
        onClick={onCancelClick}
        className="btn btn_secondary uppercase"
        data-dismiss="modal"
      >
        {cancelText}
      </button>
      {(onContinueClick || form) && (
        <button
          onClick={onContinueClick}
          type={form ? 'submit' : 'button'}
          className="btn btn_primary ml-2 uppercase"
        >
          {continueText}
        </button>
      )}
    </div>
  </div>
);

export const Body = ({ children, className, ...props }) => (
  <div {...props} className={classNames('modal-body scrollbar', className)}>
    {children}
  </div>
);

export const ModalWrapper = ({
  component: WrapperComponent = 'div',
  pinTop,
  className,
  contentClassName,
  locked,
  close,
  children,
  ...props
}) => (
  <WrapperComponent
    {...props}
    className={classNames('overlay', pinTop && styles.top, className)}
    onClick={locked ? undefined : close}
  >
    <div
      className="modal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={classNames('modal-dialog modal-dialog_centered max-w-2xl', contentClassName)}>
        <div className="modal-content w-full">{children}</div>
      </div>
    </div>
  </WrapperComponent>
);
export const Modal = ({
  title,
  children,
  onContinueClick,
  continueText,
  cancelText,
  form,
  ...props
}) => (
  <ModalWrapper {...props}>
    <Header title={title} close={props.close} locked={props.locked} />
    {children && <Body>{children}</Body>}
    <Footer
      onCancelClick={props.close}
      form={form}
      onContinueClick={onContinueClick}
      continueText={continueText}
      cancelText={cancelText}
    />
  </ModalWrapper>
);

const InertApp = ({ id = '#root' }) => {
  useEffect(() => {
    document.activeElement.blur();
    const appEl = document?.querySelector(id);
    appEl?.setAttribute('inert', 'true');
    return () => appEl?.removeAttribute('inert');
  }, []);
  return null;
};

const ModalContainer = ({ isOpen = false, children, as = Modal, ...props }) => (
  <Portal>
    <Animate {...props} as={as} isShowing={isOpen} className="inactive" animatedClassName="active">
      {children}
    </Animate>
    {isOpen && (
      <>
        <InertApp />
        {!props.locked && <KeyPressListener keys={[27]} onPress={props.close} />}
      </>
    )}
  </Portal>
);

export default ModalContainer;
