import React from 'react';

import TableauReport from 'tableau-react';

const AdminPage = () => {
  const options = {
    height: 900,
    width: 1296,
    hideTabs: false,
    margin: 'auto'
  };

  const filters = {
    Colors: ['Blue', 'Red'],
    Sizes: ['Small', 'Medium']
  };

  const parameters = {
    embed_code_version: 3
  };

  return (
    <React.Fragment>
      <div className="lg:flex lg:-mx-4 lg:px-4">
        <TableauReport
          url="https://prod-useast-b.online.tableau.com/t/turnsignl/views/TurnSignlAdminDashboard/Users2?:display_count=n&:showVizHome=n&:origin=viz_share_link"
          filters={filters}
          parameters={parameters}
          options={options}
          query="?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes&showAppBanner=false"
        />
      </div>
    </React.Fragment>
  );
};

export default AdminPage;
