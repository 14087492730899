import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import { classNames } from '@utils';

export const incidentTypeOptions = [
  { value: 'all', label: 'All Types' },
  { value: 'accident', label: 'Accident' },
  { value: 'traffic_stop', label: 'Traffic Stop' }
];
export const useIncidentTypeFilter = (initialState = incidentTypeOptions[0]) => {
  return useState(initialState);
};
const IncidentTypeFilter = ({ incidentType, setIncidentType }) => (
  <Dropdown options={incidentTypeOptions} value={incidentType} setValue={setIncidentType} />
);

export default IncidentTypeFilter;
