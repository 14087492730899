import { string, object, mixed } from 'yup';

export const businessSchema = object().shape({
  address1: string().required('Address is required'),
  business_subtype_id: mixed().required('Business subtype is required'),
  business_type_id: mixed().required('Business type is required'),
  city: string().required('City is required'),
  name: string().required('Name is required'),
  state_id: string().required('State is required'),
  zip_code: string().required('ZIP Code is required')
});
export const businessMarketsSchema = object().shape({});
