import useRequest from '@containers/useRequest';
import React, { useEffect, useRef, useState } from 'react';
import { RequestLoader } from '@components/Loader';
import AdminLayout from '@components/Dashboard/AdminLayout';
import { userSchema } from '@utils/validation/user';
import Form from '@components/Form';
import RequestSelect from '@containers/RequestSelect';
import ResourceSelect from '@containers/ResourceSelect';

const BusinessAdminPage = ({ setTreeData, setChecked, checked, ...props }) => {
  const { status, data } = useRequest('/business/names.json');
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [requestStatus, setRequestStatus] = useState(true);
  const selectRef = useRef(null);

  const options = [
    {
      name: 'All',
      id: 'all'
    },
    {
      name: 'B2C',
      id: 'b2c'
    },
    {
      name: 'B2B',
      id: 'b2b'
    }
  ];

  useEffect(() => {
    setRequestStatus(true);
    if (status === 'loading') {
      setTimeout(function () {
        setRequestStatus(false);
      }, 10000);
    }

    if (status === 'success' && data) {
      for (const business of data.business_names) {
        options.push({
          name: business.name,
          id: business.id
        });
      }
      if (options && options.length > 0) {
        setSelectedBusinessId(options[0].id);
      }
      setBusinesses(options);
    }
  }, [status]);

  return (
    <React.Fragment>
      {status === 'loading' && requestStatus && <RequestLoader />}
      {status === 'success' && businesses && (
        <div ref={selectRef} className="custom-select">
          <Form validationSchema={userSchema} {...props}>
            <ResourceSelect
              name="Business"
              placeholder="Select Business..."
              data={businesses}
              value={selectedBusinessId ? selectedBusinessId : 'all'}
              onChange={(e) => setSelectedBusinessId(e.target.value)}
              required
            />
          </Form>
        </div>
      )}
      {selectedBusinessId && (
        <AdminLayout businessId={selectedBusinessId === 'all' ? '' : selectedBusinessId} />
      )}
    </React.Fragment>
  );
};

export default BusinessAdminPage;
