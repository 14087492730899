import { useCallback, useEffect, useReducer } from 'react';
import { stringify } from 'query-string';
import { getRequest } from '@utils/api';

const REQUEST = 'REQUEST';
const REQUEST__SUCCESS = 'REQUEST__SUCCESS';
const REQUEST__FAILURE = 'REQUEST__FAILURE';
const UPDATE_DATA = 'UPDATE_DATA';
const RESET = 'RESET';

const initialState = { status: 'loading', data: null, error: '' };

function requestReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST:
      return {
        ...state,
        status: 'loading',
        error: ''
      };
    case REQUEST__SUCCESS:
      return {
        status: 'success',
        data: payload,
        error: ''
      };
    case REQUEST__FAILURE:
      return {
        status: 'error',
        data: null,
        error: payload || 'An unexpected error has occurred'
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: payload
      };
    case RESET:
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

const makeRequestAction = () => ({ type: REQUEST });
const makeRequestSuccessAction = (payload) => ({ type: REQUEST__SUCCESS, payload });
const makeRequestFailureAction = (payload) => ({ type: REQUEST__FAILURE, payload });
const makeUpdateDataAction = (payload) => ({ type: UPDATE_DATA, payload });
const makeResetAction = () => ({ type: RESET });

export default function useRequest(url, query = {}, blockAutoRequest) {
  const [state, dispatch] = useReducer(requestReducer, initialState),
    makeRequest = async (newQuery = {}) => {
      dispatch(makeRequestAction());
      try {
        const requestQuery = stringify({ ...query, ...newQuery }),
          response = await getRequest(`${url}${requestQuery.length > 0 ? `?${requestQuery}` : ''}`);
        dispatch(makeRequestSuccessAction(response));
      } catch (e) {
        dispatch(makeRequestFailureAction(e.message));
      }
    },
    setQuery = (newQuery) => {
      dispatch(makeResetAction());
      makeRequest(newQuery);
    },
    updateData = useCallback((data) => dispatch(makeUpdateDataAction(data)), []);

  useEffect(() => {
    if (!blockAutoRequest) {
      makeRequest();
    }
  }, [url]);
  return { ...state, setQuery, makeRequest, updateData };
}
