import { ErrorMessage } from '@hookform/error-message';
import styles from './index.module.css';
import { classNames } from '@utils';

const ErrorText = ({ className, children }) => (
  <div className={classNames(styles.error, className)}>{children}</div>
);

const FormError = ({ name, className }) => (
  <ErrorMessage name={name} as={ErrorText} className={className} />
);
export default FormError;
