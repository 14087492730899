import { string, object, mixed } from 'yup';

export const userSchema = object().shape({
  role: mixed().required('Role is required'),
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required'),
  business_id: mixed().test(
    'is not admin and has no business_id',
    'Business is required',
    function () {
      return this.parent.role === 'Admin' ? true : this.parent.business_id;
    }
  ),
  phone: string()
    .test('is attorney and has no phone', 'Phone is required', function () {
      return this.parent.role === 'Attorney' ? this.parent.phone : true;
    })
    .nullable(),
  state_id: mixed()
    .test('is not admin and has no state_id', 'State is required', function () {
      return this.parent.role !== 'Admin' ? this.parent.state_id : true;
    })
    .nullable(),
  'lawyer_attributes[practice_type_id]': mixed()
    .test('is attorney and has no practice_type_id', 'Practice type is required', function () {
      return this.parent.role === 'Attorney'
        ? this.parent.lawyer_attributes?.practice_type_id
        : true;
    })
    .nullable(),
  'lawyer_attributes[state_ids]': mixed()
    .test('is attorney and has no state_ids', 'States are required', function () {
      return this.parent.role === 'Attorney'
        ? this.parent.lawyer_attributes?.state_ids?.length > 0
        : true;
    })
    .nullable()
});

export const updateUserSchema = object().shape({
  role: mixed().required('Role is required'),
  first_name: string().required('First name is required'),
  last_name: string().required('Last name is required'),
  email: string().email('Invalid email').required('Email is required'),
  business_id: mixed().test(
    'is not admin and has no business_id',
    'Business is required',
    function () {
      return this.parent.role === 'Admin' || this.parent.role === 'Client'
        ? true
        : this.parent.business_id;
    }
  ),
  phone: string()
    .test('is attorney and has no phone', 'Phone is required', function () {
      return this.parent.role === 'Attorney' ? this.parent.phone : true;
    })
    .nullable(),
  state_id: mixed()
    .test('is not admin and has no state_id', 'State is required', function () {
      return this.parent.role !== 'Admin' ? this.parent.state_id : true;
    })
    .nullable(),
  'lawyer_attributes[practice_type_id]': mixed()
    .test('is attorney and has no practice_type_id', 'Practice type is required', function () {
      return this.parent.role === 'Attorney'
        ? this.parent.lawyer_attributes?.practice_type_id
        : true;
    })
    .nullable(),
  'lawyer_attributes[state_ids]': mixed()
    .test('is attorney and has no state_ids', 'States are required', function () {
      return this.parent.role === 'Attorney'
        ? this.parent.lawyer_attributes?.state_ids?.length > 0
        : true;
    })
    .nullable()
});
