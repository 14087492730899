const loopworker = () => {
  var idleTimer = null;

  onmessage = (e) => {
    postMessage("Test OK");
    clearTimeout(idleTimer);

    const user = e.data.user;
    idleTimer = setTimeout(() => {
      if (user) {
        postMessage("setOpen")
      }
      else {
        console.log("No user on IdleCheck");
        console.log(user);
      }
    }, 1500000); //25 minutes
   //  }, 180000); //3 minutes
    // }, 6000); //3 seconds
  }
}

let code = loopworker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: 'application/javascriptssky' })
const workerScript = URL.createObjectURL(blob)
module.exports = workerScript;
