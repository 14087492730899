import { Input } from '@components/Form/Fields/Input';
import withField from '@components/Form/Fields/withField';
import { classNames } from '@utils';

export const CheckboxComponent = ({ as: InputComponent = Input, checkboxLabel, ...props }) => (
  <div className={classNames('flex items-center justify-center', !noMargin && 'mb-5 py-2')}>
    <label className={classNames('custom-checkbox', props.disabled && 'checkbox-disabled')}>
      <InputComponent {...props} type="checkbox" />
      <span></span>
      {checkboxLabel && <span className="whitespace-nowrap">{checkboxLabel}</span>}
    </label>
  </div>
);

export default withField(CheckboxComponent);
