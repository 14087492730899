import { hot } from 'react-hot-loader/root';
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from '@components/Layouts/App';
import ErrorBoundary from '@components/ErrorBoundary';
import Login from '@components/Auth/Login';
import RegistrationPage from '@components/Auth/Registration';
import BestBuyPage from '@components/Auth/BestBuy';
import PasswordForgotPage from '@components/Auth/ForgotPassword';
import PasswordUpdatePage from '@components/Auth/UpdatePassword';
import InvitationPage from '@components/Auth/InvitationPage';
import ConfirmationPage from '@components/Auth/ConfirmationPage';
import UserViewPage from '@components/User/View';
import UserLayout from '@components/User/Layout';
import UserListLayout from '@components/User/ListLayout';
import Dashboard from '@components/Dashboard';
import AdminPage from '@components/Dashboard/AdminPage';
import BusinessAdminPage from '@components/Dashboard/BusinessAdminPage';
import UserIncidentsPage from '@components/User/IncidentPage';
import BusinessViewPage, { UserBusinessPage } from '@components/Business/View';
import BusinessMarketsPage from '@components/Business/Markets';
import BusinessMarketPage from '@components/Business/Market';
import BusinessLayout, { BusinessDetails } from '@components/Business/Layout';
import BusinessListLayout from '@components/Business/ListLayout';
import BusinessUsersPage from '@components/Business/UserPage';
import BusinessEventsPage from '@components/Business/EventsPage';
import BusinessFilesPage from '@components/Business/Files';
import CampaignListPage from '@components/Campaign/ListPage';
import CampaignDetailsLayout from '@components/Campaign/Layout';
import { UserCampaignPage } from '@components/Campaign/View';
import CampaignUsersPage from '@components/Campaign/UserPage';
import UserImportPage from '@components/User/Import';
import UserEventsPage from '@components/User/EventsPage';
import AttorneyListLayout from '@components/AttorneyMonitor/ListLayout';
import AttorneyIncidentsPage from '@components/AttorneyMonitor/IncidentPage';
import IncidentsPage from '@components/Incident/ListPage';
import IncidentViewPage from '@components/Incident/View';
import LeadsPage from '@components/Lead/ListPage';
import StateListLayout from '@components/State/ListLayout';
import StateProfilePage from '@components/State/View';
import GetAppPage from '@components/GetAppPage';
import DownloadAppPage from '@components/DownloadAppPage';
import IdleCheckModal from '@components/IdleCheckModal';
import { LoaderProvider } from '@containers/Loader';
import { ToastProvider } from '@containers/ToastContext';
import { AppInfoProvider } from '@containers/AppInfo';
import { ErrorModalProvider } from '@containers/ErrorModal';
import { WidthProvider } from '@containers/withIsMobile';
import { useAuthState, AuthProvider, logoutAction, useAuthDispatch } from '@containers/Auth';

const AutoLogout = () => {
  const dispatch = useAuthDispatch();
  useEffect(() => {
    dispatch(logoutAction());
  }, [dispatch]);
  return null;
};

const Pages = () => {
  const user = useAuthState();

  if (user) {
    if (user.is_admin) {
      return (
        <Layout>
          <IdleCheckModal />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="all" element={<AdminPage />} />
              <Route path="businesses" element={<BusinessAdminPage />} />
              <Route path="" element={<Navigate to="all" replace />} />
            </Route>
            <Route path="/users/invitation/accept" element={<AutoLogout />} />
            <Route path="/users/confirmation" element={<AutoLogout />} />
            <Route path="/users/password/edit" element={<AutoLogout />} />
            <Route path="/users" element={<UserListLayout />}>
              <Route path=":userId" element={<UserLayout />}>
                <Route path="incidents" element={<UserIncidentsPage />} />
                <Route path="profile" element={<UserViewPage />} />
                <Route path="events" element={<UserEventsPage />} />
                <Route path="" element={<Navigate to="profile" replace />} />
              </Route>
            </Route>
            <Route path="/attorneys" element={<AttorneyListLayout />}>
              <Route path=":lawyerId" element={<AttorneyIncidentsPage />} />
            </Route>
            <Route path="/markets" element={<StateListLayout />}>
              <Route path=":stateId" element={<StateProfilePage />} />
            </Route>
            <Route path="/campaigns" element={<CampaignListPage />}>
              <Route path=":campaignId" element={<CampaignDetailsLayout />} >
                <Route path="profile" element={<UserCampaignPage />} />
                <Route path="users" element={<CampaignUsersPage />} />
                <Route path="" element={<Navigate to="profile" replace />} />
              </Route>
            </Route>
            <Route path="/businesses" element={<BusinessListLayout />}>
              <Route path=":businessId" element={<BusinessLayout />}>
                <Route path="users" element={<BusinessUsersPage />} />
                <Route path="markets" element={<BusinessMarketsPage />}>
                  <Route path=":marketId" element={<BusinessMarketPage />} />
                </Route>
                <Route path="import" element={<UserImportPage />} />
                <Route path="profile" element={<BusinessViewPage />} />
                <Route path="events" element={<BusinessEventsPage />} />
                <Route path="files" element={<BusinessFilesPage />} />
                <Route path="" element={<Navigate to="profile" replace />} />
              </Route>
            </Route>
            <Route path="/leads" element={<LeadsPage />} />
            <Route path="/incidents/:id" element={<IncidentViewPage />} />
            <Route path="/incidents" element={<IncidentsPage />} />
            <Route path="*" element={<Navigate to="/users" />} />
          </Routes>
        </Layout>
      );
    }
    if (user.is_org_admin) {
      return (
        <Layout>
          <IdleCheckModal />
          <Routes>
            <Route path="/users/invitation/accept" element={<AutoLogout />} />
            <Route path="/users/confirmation" element={<AutoLogout />} />
            <Route path="/users/password/edit" element={<AutoLogout />} />
            <Route path="/users" element={<UserListLayout businessId={user.business.id} />}>
              <Route path=":userId" element={<UserLayout businessId={user.business.id} />}>
                <Route path="profile" element={<UserViewPage businessId={user.business.id} />} />
                <Route path="events" element={<UserEventsPage />} />
                <Route path="" element={<Navigate to="profile" replace />} />
              </Route>
            </Route>
            <Route
              path="/business"
              element={<BusinessDetails businessId={user.business.id} isBusinessAdmin />}
            >
              <Route path="markets" element={<BusinessMarketsPage />}>
                <Route path=":marketId" element={<BusinessMarketPage />} />
              </Route>
              <Route path="profile" element={<UserBusinessPage />} />
              <Route path="import" element={<UserImportPage businessId={user.business.id} />} />
              <Route path="events" element={<BusinessEventsPage />} />
              <Route path="" element={<Navigate to="profile" replace />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Layout>
      );
    }
    return (
      <Layout>
        <Routes>
          <Route path="/users/invitation/accept" element={<AutoLogout />} />
          <Route path="/users/confirmation" element={<AutoLogout />} />
          <Route path="/users/password/edit" element={<AutoLogout />} />
          <Route path="*" element={<GetAppPage logout />} />
        </Routes>
      </Layout>
    );
  }
  return (
    <Routes>
      <Route path="/get_app" element={<Layout><GetAppPage /></Layout>} />
      <Route path="/download_app" element={<Layout><DownloadAppPage /></Layout>} />
      <Route path="/users/invitation/accept" element={<Layout><InvitationPage /></Layout>} />
      <Route path="/users/confirmation" element={<Layout><ConfirmationPage /></Layout>} />
      <Route path="/users/password/forgot" element={<Layout><PasswordForgotPage /></Layout>} />
      <Route path="/users/password/edit" element={<Layout><PasswordUpdatePage /></Layout>} />
      <Route path="/login" element={<Layout><Login /></Layout>} />
      <Route path="/registration" element={<Layout><RegistrationPage /></Layout>} />
      <Route path="/bestBuy" element={<BestBuyPage />}>
        <Route path="*" element={<BestBuyPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
const TITLE = process.env.REACT_APP_HTML_TITLE ? process.env.REACT_APP_HTML_TITLE : 'TurnSignl';
const App = () => (
  <Router>
    <Helmet titleTemplate={`%s | ${TITLE}`} defaultTitle={TITLE}>
      <meta name="description" content="TurnSignl Dashboard" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <ErrorBoundary text="There was a problem please refresh the browser">
      <WidthProvider>
        <ErrorModalProvider>
          <ToastProvider>
            <LoaderProvider>
              <AuthProvider>
                <AppInfoProvider>
                  <Pages />
                </AppInfoProvider>
              </AuthProvider>
            </LoaderProvider>
          </ToastProvider>
        </ErrorModalProvider>
      </WidthProvider>
    </ErrorBoundary>
  </Router>
);

export default hot(App);
