import { useState, useEffect, useRef } from 'react';
import { CardElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import EditableValue, { Value } from '@components/Form/Fields/EditableValue';
import Radio, { StatelessRadioComponent, _Radio as UnWrappedRadio } from '@components/Form/Fields/Radio';
import { Input } from '@components/Form/Fields/Input';
import ToggleForm from '@components/Form/ToggleForm';
import LogoField from '@components/Form/Fields/LogoCropper';
import Section from '@components/Section';
import PhoneNumberInput from '@components/Form/Fields/PhoneNumberInput';
import Loader from '@components/Loader';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import Form from '@components/Form';
import Modal from '@components/Modal';
import Button from '@components/Button';
import FilterRow from '@components/Filter/Row';
import BusinessFormWrapper from '@components/Business/Form';
import { UserInviteModalButton } from '@components/User/ListPage';
import { BusinessSubtypeSelect } from '@components/Business/ListLayout';
import Request from '@containers/Request';
import { useRequestState } from '@containers/RequestContext';
import { usePaginationRequestState } from '@containers/PaginationContext';
import AppInfoSelect from '@containers/AppInfoSelect';
import { useAppInfoState } from '@containers/AppInfo';
import StateSelect from '@containers/StateSelect';
import { useToastAddAction } from '@containers/ToastContext';
import { useLoaderDispatch } from '@containers/Loader';
import { useErrorModalDispatch } from '@containers/ErrorModal';
import withPageTitle from '@containers/withPageTitle';
import { updateBusiness, generateSignupLink, deactiveSignupLink, importBusinessSignUpLinkData } from '@utils/api/business';
import { updateSubscription, cancelSubscription } from '@utils/api/subscription';
import { getRequest, makeRequest } from '@utils/api';
import { addCardSchema } from '@utils/validation/card';
import { subscriptionSchema } from '@utils/validation/subscription';
import { phoneFormat, classNames, cashFormat, getFormattedDate, wait } from '@utils';
import { useAuthState } from '@containers/Auth';
import stripePromise from '@utils/stripe';
import styles from './index.module.css';
// import { Switch } from '@components/Form/Fields/Switch';
import withField from '@components/Form/Fields/withField';
import { Switch } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DateInputField from '@components/Form/Fields/Date';

const SwitchField = withField(Switch, undefined, false, true);

export const AddCardModalButton = ({
  url,
  pollUrl,
  isUpdating,
  onSuccess = () => { },
  ...props
}) => {
  const user = useAuthState();
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    info = useAppInfoState(),
    stripe = useStripe(),
    elements = useElements(),
    handleSubmit = (values) =>
      new Promise(async (resolve, reject) => {
        try {
          const card = elements.getElement(CardElement),
            { setup_intent_token } = await getRequest(url),
            payload = await stripe.confirmCardSetup(setup_intent_token, {
              payment_method: {
                card
              }
            });
          let result = await getRequest(pollUrl);
          while (result.payment_methods.length === 0) {
            await wait();
            result = await getRequest(pollUrl);
          }

          resolve(result);
        } catch (e) {
          resolve(e);
        }
      }),
    handleSuccess = () => {
      handleClose();
      onSuccess();
    };
  return (
    <>
      {!user.is_org_admin && (
        <Button {...props} type="button" onClick={handleOpen} primary>
          {isUpdating ? 'Update Card' : 'Add Card'}
        </Button>
      )}
      <Modal
        component={Form}
        isOpen={isOpen}
        title={isUpdating ? 'Update Card' : 'Add Card'}
        close={handleClose}
        url={url}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        validationSchema={addCardSchema}
        toast="Card added"
        to={false}
        form
      >
        <CardElement />
      </Modal>
    </>
  );
};

const PlanCard = ({ plan, name, isBusiness }) => (
  <div className="border-b py-5 border-gray-300 dark:border-gray-900">
    <label className="w-full flex items-center justify-between">
      <h3>{plan.name}</h3>
      <UnWrappedRadio name={name} value={plan.plan_id} grow={false} />
    </label>
    {isBusiness ? (
      plan.tiers?.length > 0 ? (
        <table className="table w-full mt-3">
          <thead>
            <tr>
              <th className="text-left uppercase">User Count</th>
              <th className="text-left uppercase">Flat Amount</th>
              <th className="text-left uppercase">Unit Amount</th>
            </tr>
          </thead>
          <tbody>
            {plan.tiers.map((tier) => (
              <tr key={tier.unit_amount}>
                <td>
                  {tier.starting_at}
                  {tier.up_to ? ` - ${tier.up_to}` : '+'}
                </td>
                <td>{tier.flat_amount ? cashFormat(tier.flat_amount) : 'N/A'}</td>
                <td>{tier.unit_amount ? cashFormat(tier.unit_amount) : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null
    ) : (
      <div>
        {cashFormat(plan.amount)} {plan.billed}
      </div>
    )}
  </div>
);
export const SubscriptionModalButton = ({
  url,
  isBusiness,
  planId,
  onSuccess = () => { },
  ...props
}) => {
  const user = useAuthState();
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    handleOpen = () => setIsOpen(true),
    handleSuccess = () => {
      handleClose();
      onSuccess();
    };
  return (
    <>
      {!user.is_org_admin && (
        <Button {...props} type="button" onClick={handleOpen} primary>
          {planId ? 'Update Subscription' : 'Add Subscription'}
        </Button>
      )}
      <Modal
        component={Form}
        isOpen={isOpen}
        title={planId ? 'Update Subscription' : 'Add Subscription'}
        close={handleClose}
        url={`${url}/subscription.json`}
        onSubmit={updateSubscription}
        onSuccess={handleSuccess}
        defaultValues={{ subscription_plan_id: planId }}
        validationSchema={subscriptionSchema}
        toast="Subscription updated"
        to={false}
        form
      >
        <div
          className={
            styles.couponHeader +
            'bg-white dark:bg-gray-800 border-b pb-5 border-gray-300 dark:border-gray-900'
          }
        >
          <div className={styles.couponLabel}>Coupon Code: </div>
          <Input name="coupon_code" className="form-control" />
        </div>
        <Request url={`${url}/plans.json`}>
          {({ plans }) =>
            plans.map((plan) => (
              <PlanCard
                key={plan.plan_id}
                isBusiness={isBusiness}
                plan={plan}
                name="subscription_plan_id"
              />
            ))
          }
        </Request>
      </Modal>
    </>
  );
};

export const BusinessSubscriptionInfo = ({ business, className }) => (
  <div className={className}>
    <Value label="Plan">
      {business.organization_subscription?.plan?.name}
    </Value>
    {business.organization_subscription.coupon_code && (
      <Value label="Coupon Code">{business.organization_subscription.coupon_code}</Value>
    )}
    <Value label="Subscription Payment Status">
      {business.organization_subscription?.subscription_status}
    </Value>
    <Value label="Sign Up Link">
      {business?.sign_up_links?.length > 0 && getActiveSignupLink(business?.sign_up_links)}
    </Value>
    <Value label="Family Plan">
      {business?.family_plan_active ? 'Active' : 'Inactive'}
    </Value>
    <Value label="Family Members/User">
      {business?.family_member_count}
    </Value>
  </div>
);

export const getActiveSignupLink = (data = []) => {
  const [link, setLink] = useState('')
  const addToast = useToastAddAction()
  useEffect(() => {
    const activeIndex = data ? data.findIndex(item => item.active === true) : -1
    activeIndex > -1 && setLink(data[activeIndex]?.short_link)
  }, [data])

  return <span style={{ cursor: 'pointer' }} onClick={() => {
    navigator.clipboard.writeText(link)
    addToast('Link copied successfully');
  }} >{link}</span>
}

export const CancelSubscriptionButton = ({ url, onSuccess = () => { }, ...props }) => {
  const loaderDispatch = useLoaderDispatch(),
    errorDispatch = useErrorModalDispatch(),
    addToast = useToastAddAction(),
    [modal, setModal] = useState(),
    handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setModal(true);
    },
    handleConfirmClick = async () => {
      loaderDispatch({ type: 'START_LOADING' });
      try {
        const response = await cancelSubscription(`${url}/subscription.json`);
        if (response?.error) {
          loaderDispatch({ type: 'STOP_LOADING' });
          setModal();
          errorDispatch(response.error_description || response.error);
        } else {
          loaderDispatch({ type: 'STOP_LOADING' });
          setModal();
          addToast('Subscription cancelled');
          onSuccess();
        }
      } catch (e) {
        loaderDispatch({ type: 'STOP_LOADING' });
        errorDispatch('An unexpected error has occurred');
        setModal();
      }
    },
    closeModal = () => setModal();
  return (
    <>
      <Button type="button" secondary className="mb-3" onClick={handleClick}>
        Cancel Subscription
      </Button>
      <Modal
        isOpen={modal ? true : false}
        title="Are you sure?"
        onContinueClick={handleConfirmClick}
        continueText="Continue"
        cancelText="Cancel"
        close={closeModal}
        cancel={closeModal}
      />
    </>
  );
};

export const GenerateSignupLinkButton = ({ url, onSuccess = () => { }, ...props }) => {
  const user = useAuthState();
  const loaderDispatch = useLoaderDispatch();
  const errorDispatch = useErrorModalDispatch();
  const addToast = useToastAddAction();
  const [isOpen, setIsOpen] = useState();
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleConfirmClick = async (e) => {
    if (!e.data_field_title) {
      addToast('Data collection field is required.');
      return
    }
    loaderDispatch({ type: 'START_LOADING' });
    handleClose()
    const data = {
      business_sign_up_link: {
        data_field_title: e.data_field_title
      }
    }
    try {
      const response = await generateSignupLink(data, `${url}/sign_up.json`,);
      if (response?.error) {
        loaderDispatch({ type: 'STOP_LOADING' });
        errorDispatch(response.error_description || response.error);
      } else {
        loaderDispatch({ type: 'STOP_LOADING' });
        addToast('Signup link generated');
        onSuccess();
      }
    } catch (e) {
      loaderDispatch({ type: 'STOP_LOADING' });
      errorDispatch('An unexpected error has occurred');
    }
  }
  return (
    <>
      {user.is_admin && (
        <Button {...props} type="button" onClick={handleOpen} primary>
          Generate Link
        </Button>
      )}
      <Modal
        component={Form}
        isOpen={isOpen}
        title={'Generate Signup Link'}
        close={handleClose}
        onSubmit={handleConfirmClick}
        defaultValues={{ data_field_title: '' }}
        toast="Signup link generated"
        to={false}
        form
      >
        <div
          className={
            styles.couponHeader +
            'bg-white dark:bg-gray-800 border-b pb-5 border-gray-300 dark:border-gray-900'
          }
        >
          <div className={styles.couponLabel}>Data Collection Field: </div>
          <Input name="data_field_title" className="form-control" />
        </div>
      </Modal>
    </>
  );
};

export const DeactivateSignupLinkButton = ({ url, onSuccess = () => { }, ...props }) => {
  const user = useAuthState();
  const loaderDispatch = useLoaderDispatch();
  const errorDispatch = useErrorModalDispatch();
  const addToast = useToastAddAction();
  const handleConfirmClick = async () => {
    loaderDispatch({ type: 'START_LOADING' });
    const data = {
      business_sign_up_link: {
        active: false
      }
    }


    try {
      const response = await deactiveSignupLink(data, url);
      if (response?.error) {
        loaderDispatch({ type: 'STOP_LOADING' });
        errorDispatch(response.error_description || response.error);
      } else {
        loaderDispatch({ type: 'STOP_LOADING' });
        addToast('link deactivated');
        onSuccess();
      }
    } catch (e) {
      loaderDispatch({ type: 'STOP_LOADING' });
      errorDispatch('An unexpected error has occurred');
    }
  }
  return (
    <>
      {user.is_admin && (
        <Button type="button" secondary className="mb-3" onClick={handleConfirmClick}>
          Deactivate
        </Button>
      )}
    </>
  );
};


export const ImportButton = ({ url, onSuccess = () => { }, ...props }) => {
  const user = useAuthState();
  const loaderDispatch = useLoaderDispatch();
  const errorDispatch = useErrorModalDispatch();
  const addToast = useToastAddAction();
  const [isOpen, setIsOpen] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [file, setFile] = useState();
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleConfirmClick = async () => {
    if (!file) {
      addToast('File is required.');
      return
    }
    loaderDispatch({ type: 'START_LOADING' });
    handleClose()

    const formData = new FormData();
    formData.append('business[data_import]', file);
    formData.append('business[unique_values]', isChecked);
    try {
      const response = await importBusinessSignUpLinkData(formData, `${url}/sign_up_import.json`);

      if (response?.error) {
        loaderDispatch({ type: 'STOP_LOADING' });
        errorDispatch(response.error_description || response.error);
      } else {
        loaderDispatch({ type: 'STOP_LOADING' });
        addToast('Signup link generated');
        onSuccess();
      }
    } catch (e) {
      loaderDispatch({ type: 'STOP_LOADING' });
      errorDispatch(e.message ? e.message : 'An unexpected error has occurred');
    }
  }
  return (
    <>
      {user.is_admin && (
        <Button {...props} type="button" className="mb-3" onClick={handleOpen} primary>
          Import
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        title={'Import Business SignUp Link Data'}
        close={handleClose}
        onContinueClick={handleConfirmClick}
        toast="File imported successfully"
        continueText="Upload"
        cancelText="Cancel"
      >
        <div
          className={
            styles.couponHeader +
            'bg-white dark:bg-gray-800 border-b pb-5 border-gray-300 dark:border-gray-900'
          }
          style={{ borderStyle: 'none' }}
        >
          <div className="ml-1 lg:ml-2 my-2">
            <label className="switch text-secondary">
              <input type="checkbox" {...props} value={isChecked} onChange={() => setIsChecked(!isChecked)} />
              <span></span>
              <span className="whitespace-nowrap">1 Account per value</span>
            </label>
          </div>
          <div className={styles.couponLabel}>Please select the File to upload:
            <input
              className='ml-2'
              type="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            /> </div>

        </div>
      </Modal>
    </>
  );
};

export const GetSignupLinkButton = ({ url, onSuccess = () => { }, data }) => {
  const [link, setLink] = useState(null)
  useEffect(() => {
    const activeIndex = data ? data.findIndex(item => item.active === true) : -1
    activeIndex > -1 && setLink(data[activeIndex])
  }, [data])

  if (link) {
    return <>
      <div className="lg:flex"> <ImportButton url={url} onSuccess={onSuccess} /> </div>
      <div className="lg:flex"> <DeactivateSignupLinkButton url={`/business/sign_up/${link?.id}.json`} onSuccess={onSuccess} /> </div>
    </>
  }
  else {
    return <div className="lg:flex"><GenerateSignupLinkButton url={url} onSuccess={onSuccess} /></div>
  }

}

export const CardModalButtonContainer = ({ url = '/user/payments.json', ...props }) => (
  <Elements stripe={stripePromise}>
    <AddCardModalButton url={url} {...props} />
  </Elements>
);


export const ManageFamilyPlanButton = ({ url, onSuccess = () => { }, data, ...props }) => {
  const user = useAuthState();
  const loaderDispatch = useLoaderDispatch();
  const errorDispatch = useErrorModalDispatch();
  const addToast = useToastAddAction();
  const [isOpen, setIsOpen] = useState();
  const [isPlanActive, setIsPlanActive] = useState(data?.family_plan_active);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleConfirmClick = async (e) => {
    loaderDispatch({ type: 'START_LOADING' });
    handleClose()
    const tempData = {
      family_member_count: e.family_member_count,
      family_plan_active: isPlanActive
    }
    try {
      const response = await updateBusiness(tempData, url);
      if (response?.error) {
        loaderDispatch({ type: 'STOP_LOADING' });
        errorDispatch(response.error_description || response.error);
      } else {
        loaderDispatch({ type: 'STOP_LOADING' });
        addToast('Family Plan Updated');
        onSuccess();
      }
    } catch (e) {
      loaderDispatch({ type: 'STOP_LOADING' });
      errorDispatch('An unexpected error has occurred');
    }
  }
  return (
    <>
      {/* {user.is_admin && ( */}
      <Button {...props} type="button" onClick={handleOpen} className="mt-16" primary>
        Manage Family Plan
      </Button>
      {/* )} */}

      <Modal
        component={Form}
        isOpen={isOpen}
        title={'Manage Family Plan'}
        close={handleClose}
        onSubmit={handleConfirmClick}
        defaultValues={{ family_member_count: data?.family_member_count }}
        toast="Family Plan Updated"
        to={false}
        form
      >
        <div
          className={
            styles.couponHeader +
            'bg-white dark:bg-gray-800 border-b pb-5 border-gray-300 dark:border-gray-900'
          }
        >
          <div className={styles.couponLabel}>Family Member/User: </div>
          <Input name="family_member_count" className="form-control mb-4" />
          <div className={styles.couponLabel}>Family Plan Active:
            <input type="checkbox" name className='ml-2 mt-1' value={isPlanActive} checked={isPlanActive} onChange={(e) => setIsPlanActive(e.target.checked)} />
          </div>
        </div>
      </Modal>
    </>
  );
};


export const PaymentButtons = ({
  className,
  pollUrl,
  paymentUrl,
  isUpdating,
  makeRequest,
  isBusiness,
  url,
  planId,
  showSubscriptionButton,
  showCancelSubscriptionButton,
  business
}) => (
  <div className={className}>
    <div className="lg:flex">
      <CardModalButtonContainer
        pollUrl={pollUrl}
        url={paymentUrl}
        isUpdating={isUpdating}
        onSuccess={makeRequest}
      />
    </div>
    {showSubscriptionButton && (
      <div className="lg:flex">
        <SubscriptionModalButton
          isBusiness={isBusiness}
          url={url}
          planId={planId}
          onSuccess={makeRequest}
          className="my-3"
        />
      </div>
    )}
    {showCancelSubscriptionButton && (
      <div className="lg:flex">
        <CancelSubscriptionButton url={url} onSuccess={makeRequest} />
      </div>
    )}
    {business && showCancelSubscriptionButton && <GetSignupLinkButton url={url} onSuccess={makeRequest} data={business?.sign_up_links} />}
    <div className="lg:flex">
      <ManageFamilyPlanButton
        url={pollUrl}
        onSuccess={makeRequest}
        data={business} />
    </div>
  </div>
);

const LogoFieldContainer = ({ businessId, toast, src, onSuccess = () => { }, ...props }) => {
  const [logo, setLogo] = useState(),
    [file, setFile] = useState(),
    [srcUrl, setSrcUrl] = useState(src),
    [isLoading, setIsLoading] = useState(),
    errorDispatch = useErrorModalDispatch(),
    addToast = useToastAddAction(),
    dropzoneRef = useRef(),
    handleButtonClick = async (e) => {
      setIsLoading(true);
      try {
        const response = await makeRequest('PATCH', `/businesses/${businessId}.json`, {
          business: {
            logo
          }
        });
        addToast('Uploaded logo successfully');
        onSuccess(response);
      } catch (e) {
        errorDispatch('An unexpected error has occurred');
      } finally {
        setFile();
        setLogo(null);
        setIsLoading();
      }
    },
    handleDeleteClick = async (e) => {
      setIsLoading(true);
      try {
        const response = await makeRequest('PATCH', `/businesses/${businessId}.json`, {
          business: {
            logo: null
          }
        });
        addToast('Deleted logo successfully');
        onSuccess(response);
      } catch (e) {
        errorDispatch('An unexpected error has occurred');
      } finally {
        setFile();
        setIsLoading();
        setLogo(null);
        setSrcUrl(null);
      }
    };
  useEffect(() => {
    setSrcUrl(src);
  }, [src]);
  return (
    <>
      <LogoField
        {...props}
        src={srcUrl}
        dropzoneRef={dropzoneRef}
        file={file}
        setFile={setFile}
        onClick={handleButtonClick}
        onDelete={handleDeleteClick}
        value={logo}
        onCrop={setLogo}
      />
      {isLoading && <Loader />}
    </>
  );
};

const makeBusinessFormValues = ({
  state,
  business_type,
  account_active,
  business_subtype,
  admins,
  organization_subscription,
  payment_methods,
  legalease_partner,
  ...business
}) => ({
  legalease_partner: legalease_partner ? 'true' : 'false',
  account_active: account_active ? 'true' : 'false',
  state_id: state?.id,
  business_subtype_id: business_subtype?.id,
  business_type_id: business_type?.id,
  ...business,
});
const BusinessPage = ({ onSuccess = () => { } }) => {
  const { data, updateData, makeRequest } = useRequestState(),
    handleSuccess = ({ response }) => {
      onSuccess(response);
      updateData(response);
    };
  const user = useAuthState();
  return (
    <ToggleForm
      form={BusinessFormWrapper}
      formProps={{
        url: `/businesses/${data.id}.json`,
        defaultValues: makeBusinessFormValues(data),
        onSubmit: updateBusiness,
        onSuccess: handleSuccess,
        to: false,
        toast: 'Business updated'
      }}
    >
      {(isEditing) => (
        <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
          <Section title="Profile Information" collapsible={false} initialIsOpen>
            <div className="lg:flex">
              <div className="lg:w-1/2 lg:pr-2">
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.business_type?.name}
                      isEditing={user.is_org_admin ? false : isEditing}
                      name="business_type_id"
                      as={AppInfoSelect}
                      resource="business_types"
                      label="Business Type"
                      placeholder={false}
                    />
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <EditableValue
                      value={data.business_subtype?.name}
                      isEditing={user.is_org_admin ? false : isEditing}
                      name="business_subtype_id"
                      as={BusinessSubtypeSelect}
                      label="Business Sub-Type"
                      placeholder={false}
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <EditableValue
                      value={data.name}
                      isEditing={user.is_org_admin ? false : isEditing}
                      name="name"
                      label="Business Name"
                      placeholder="Business Name"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <EditableValue
                      value={data.offer_expiration_date}
                      isEditing={user.is_org_admin ? false : isEditing}
                      name="offer_expiration_date"
                      label="Offer Expiration Date"
                      placeholder="Offer Expiration Date"
                      as={DateInputField}
                    />
                  </div>
                </div>

                <EditableValue
                  value={data.description}
                  isEditing={isEditing}
                  name="description"
                  label="Description"
                  placeholder="Description"
                />
                <EditableValue
                  value={data.phone && phoneFormat(data.phone)}
                  as={PhoneNumberInput}
                  isEditing={isEditing}
                  name="phone"
                  label="Phone"
                  placeholder="Phone"
                />
                <EditableValue
                  value={data.address1}
                  isEditing={isEditing}
                  name="address1"
                  label="Address"
                  placeholder="Address 1"
                />
                <EditableValue
                  value={data.address2}
                  hideIfEmpty
                  isEditing={isEditing}
                  name="address2"
                  placeholder="Address 2"
                />

                <div className="lg:flex">
                  <div className="lg:w-1/3 lg:pr-2">
                    <EditableValue
                      value={data.city}
                      isEditing={isEditing}
                      name="city"
                      placeholder="City"
                      label="City"
                    />
                  </div>
                  <div className="lg:w-1/3 lg:px-2">
                    <EditableValue
                      value={data.state?.abbrev}
                      as={StateSelect}
                      isEditing={isEditing}
                      name="state_id"
                      placeholder="State"
                      label="State"
                    />
                  </div>
                  <div className="lg:w-1/3 lg:px-2">
                    <EditableValue
                      value={data.zip_code}
                      isEditing={isEditing}
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      inputMode="numeric"
                      name="zip_code"
                      placeholder="Zip Code"
                      label="Zip Code"
                    />
                  </div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/3 lg:pr-2">
                    <Value label="Time Zone">{data.time_zone}</Value>
                  </div>
                  {/*<div className="lg:w-1/3 lg:px-2">
                    <EditableValue defaultValue={data.account_active} value={<StatelessRadioComponent as="input" checked={data.account_active} noMargin radioLabel="Active" radioLabelTwo="Inactive" disabled />} label="Status" as={RadioComponent} isEditing={isEditing} name="account_active" radioLabel="Active" radioLabelTwo="Inactive" />
                  </div>*/}
                  <div className="lg:w-1/3 lg:pr-2">
                    <EditableValue
                      defaultValue={data.legalease_partner}
                      value={<StatelessRadioComponent
                        as="input"
                        checked={data.legalease_partner}
                        radioLabel="Active"
                        radioLabelTwo="Inactive"
                        disabled
                      />}
                      label="Legalease Partner"
                      as={Radio}
                      isEditing={isEditing}
                      name="legalease_partner"
                      radioLabel="True"
                      radioLabelTwo="False"
                    />
                  </div>
                </div>
                {data.legalease_partner && (
                  <div className="lg:flex">
                    <Value label="Legalease Link">
                      <Link to={data.legalease_link}>
                        {data.legalease_link}
                      </Link>
                    </Value>
                  </div>
                )}
              </div>
              <div className="lg:w-1/2 lg:px-4 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
                <LogoFieldContainer
                  businessId={data.id}
                  src={data.logo_url}
                  onSuccess={updateData}
                />
              </div>
            </div>
          </Section>
          <Section title="Account Status" initialIsOpen border>
            <div className="lg:flex">
              <div className="lg:w-1/3">
                <div className="lg:flex">
                  <Value label="Status">{data.status}</Value>
                  {/*<Value label="Sub-Status">{data.organization_subscription?.subscription_status || "N/A"}</Value>*/}

                  <div className="lg:w-1/2 lg:pr-2"></div>
                  <div className="lg:w-1/2 lg:px-2"></div>
                </div>
                <div className="lg:flex">
                  <div className="lg:w-1/2 lg:pr-2">
                    <Value label="Subscribe Date">
                      {data.organization_subscription?.created_at &&
                        getFormattedDate(data.organization_subscription.created_at)}
                    </Value>
                    <Value label="Last Invoice Date">
                      {data.organization_subscription?.invoice_dates &&
                        data.organization_subscription.invoice_dates?.last_invoice_date
                        ? getFormattedDate(
                          data.organization_subscription.invoice_dates.last_invoice_date
                        )
                        : 'N/A'}
                    </Value>
                  </div>
                  <div className="lg:w-1/2 lg:px-2">
                    <Value label="Unsubscribed Date">N/A</Value>
                    <Value label="Next Invoice Date">
                      {data.organization_subscription?.invoice_dates &&
                        (data.organization_subscription.invoice_dates?.next_invoice_date
                          ? getFormattedDate(
                            data.organization_subscription.invoice_dates.next_invoice_date
                          )
                          : 'N/A')}
                    </Value>
                  </div>
                </div>
              </div>
              {data.organization_subscription?.subscription_status !== 'PENDING' && (
                <BusinessSubscriptionInfo
                  business={data}
                  className="lg:w-1/5 lg:px-4 lg:border-l border-gray-300 dark:border-gray-900"
                />
              )}
              {user.is_org_admin ? null : (
                <PaymentButtons
                  className="lg:w-1/3 lg:px-4"
                  url={`/businesses/${data.id}`}
                  isBusiness
                  pollUrl={`/businesses/${data.id}.json`}
                  paymentUrl={`/businesses/${data.id}/payments.json`}
                  planId={data.organization_subscription.subscription_plan_id}
                  makeRequest={makeRequest}
                  isUpdating={data.payment_methods?.length > 0}
                  showSubscriptionButton={data.payment_methods?.length > 0}
                  showCancelSubscriptionButton={
                    data.organization_subscription?.subscription_status === 'ACTIVE'
                  }
                  business={data}
                />
              )}
            </div >
          </Section >
          <Section title="Business Administrator" initialIsOpen border>
            <h1></h1>
            <section className="breadcrumb lg:flex items-start">
              <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
                <FilterRow>
                  <UserInviteModalButton
                    businessId={data.id}
                    role={data.is_law_firm ? 'Attorney Admin' : 'Business Admin'}
                    to={false}
                  />
                </FilterRow>
              </div>
            </section>
            {data?.admins?.length > 0 ? (
              <div className={classNames(styles.tableWrapper, 'scrollbar')}>
                <table className="table w-full mt-3">
                  <thead>
                    <tr>
                      <th className="text-left uppercase">Email</th>
                      <th className="text-left uppercase">First Name</th>
                      <th className="text-left uppercase">Last Name</th>
                      <th className="text-left uppercase">State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.admins.map((admin) => (
                      <tr key={admin.id}>
                        <td className="text-left">{admin.email}</td>
                        <td className="text-left">{admin.first_name}</td>
                        <td className="text-left">{admin.last_name}</td>
                        <td className="text-left">{admin.state?.abbrev}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <EmptyResourcePlaceholder text="There is nothing here" />
            )}
          </Section>
        </div >
      )}
    </ToggleForm >
  );
};

export const UserBusinessPage = withPageTitle(BusinessPage, 'Business Profile');

const BusinessPageContainer = () => {
  const pagination = usePaginationRequestState(),
    handleSuccess = (response) => {
      pagination.updateData({
        ...pagination.data,
        businesses: pagination.data.businesses.map((u) => {
          if (u.id === response.id) return Object.assign({}, u, response);
          return u;
        })
      });
    };
  return <BusinessPage onSuccess={handleSuccess} />;
};

export default withPageTitle(BusinessPageContainer, 'Business Profile');
