import { useState } from 'react';
import { debounce } from '@utils';

export const useFollowUpFilter = (initialState) => {
  return useState(initialState);
};

const FollowUpFilter = ({ followUp, setFollowUp, ...props }) => {
  const handleChange = debounce((e) => {
    setFollowUp(e.target.checked);
  }, 1000);

  return (
    <div className="ml-1 lg:ml-2 my-2">
      <label className="switch text-secondary">
        <input type="checkbox" {...props} value={followUp} onChange={handleChange} />
        <span></span>
        <span className="whitespace-nowrap">Needs Follow-Up</span>
      </label>
    </div>
  );
};

export default FollowUpFilter;
