import { useState, useCallback, useEffect, useRef } from 'react';
import FilterRow from '@components/Filter/Row';
import StateFilter, { useStateFilter, stateFilterAllOption } from '@components/Filter/State';
import IncidentTypeFilter, {
  useIncidentTypeFilter,
  incidentTypeOptions
} from '@components/Filter/IncidentType';
import FollowUpFilter, { useFollowUpFilter } from '@components/Filter/FollowUp';
import TextFilter from '@components/Filter/Text';
import IncidentsList from '@components/Incident/List';
import usePaginationRequest from '@containers/usePaginationRequest';
import withPageTitle from '@containers/withPageTitle';
import Button from '@components/Button';
import Loader from '@components/Loader';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import { exportIncidents } from '@utils/api/incident';
import { classNames } from '@utils';
import styles from './index.module.css';
import ReactSlider from 'react-slider';
var timeout;
export const ExportDataButton = ({ makeQuery }) => {
  const [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    handleClick = async () => {
      setLoading(true);
      const csvFile = await exportIncidents(makeQuery());
      setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      setLoading(false);
    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
      setFileLink('');
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleClick} type="button" secondary outlined className="ml-2 my-1">
        Export
      </Button>
      <a href={fileLink} ref={linkRef} download="turnsignl-incidents.csv" className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};

export const IncidentListPage = ({
  pageSize = 50,
  url = '/incidents.json',
  title,
  subtitle,
  className,
  lawyerId,
  wrapperClassName,
  query = {},
  ...props
}) => {
  const request = usePaginationRequest('incidents', url, pageSize, query),
    [state, setState] = useStateFilter(),
    [incidentType, setIncidentType] = useIncidentTypeFilter(),
    [followUpFilter, setFollowUpFilter] = useFollowUpFilter(),
    [textFilter, setTextFilter] = useState(''),
    [durations, setDurations] = useState(0),
    makeQuery = useCallback(() => {
      const newQuery = {};
      if (incidentType.value !== incidentTypeOptions[0].value) {
        newQuery.type = incidentType.value;
      }
      if (state.id !== stateFilterAllOption.id) {
        newQuery.state = state.abbrev;
      }
      if (followUpFilter) {
        newQuery.followup = true;
      }
      if (textFilter) {
        newQuery.search = textFilter;
      }
      newQuery.duration = durations;
      return newQuery;
    }, [state, incidentType, followUpFilter, textFilter, lawyerId, durations]),
    makeQueryRequest = useCallback(() => {
      request.setQuery(makeQuery());
    }, [makeQuery]);
  const handleDurationSliderChange = (v) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setDurations(v)
    }, 300)
  }
  useEffectAfterMount(makeQueryRequest);
  return (
    <>
      <section className={classNames('breadcrumb lg:flex items-start', wrapperClassName)}>
        {title && <h1>{title}</h1>}
        <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
          <TextFilter value={textFilter} onChange={setTextFilter} />
          <FilterRow>
            <StateFilter state={state} setState={setState} />
            <IncidentTypeFilter incidentType={incidentType} setIncidentType={setIncidentType} />
            <FollowUpFilter followUp={followUpFilter} setFollowUp={setFollowUpFilter} />
            <ExportDataButton makeQuery={makeQuery} />
            <div className="ml-2">
              <span>{"Duration(seconds)"}</span>
              <ReactSlider
                min={0}
                max={240}
                step={30}
                marks
                onChange={handleDurationSliderChange}
                className={styles.slider}
                thumbClassName={styles.thumb}
                trackClassName={styles.track}
                renderMark={props => <div {...props} />}
                renderThumb={(props, state) => <div {...props}>{state.valueNow === 0 ? 'All' : state.valueNow}</div>}
              />
            </div>

          </FilterRow>
        </div>
      </section>
      <div className={classNames('card p-5 flex-shrink-0', className)}>
        {subtitle}
        <div className="h-full scrollbar">
          <IncidentsList {...request} query={makeQuery()} {...props} noLink />
        </div>
      </div>
    </>
  );
};

const IncidentListPageContainer = ({ title = 'Incidents' }) => (
  <IncidentListPage title={title} className={styles.listWrapper} />
);

export default withPageTitle(IncidentListPageContainer, 'Calls');
