import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import useAppInfoOptions from '@containers/useAppInfoOptions';

export const stateFilterAllOption = { id: 'all', abbrev: 'All States' };
export const useStateFilter = (initialState = stateFilterAllOption) => {
  return useState(initialState);
};
const initialOptions = [stateFilterAllOption];
export default function StateFilter({ state, setState }) {
  const options = useAppInfoOptions('states', initialOptions);
  return (
    <Dropdown
      options={options}
      value={state}
      setValue={setState}
      valueAccessor="id"
      labelAccessor="abbrev"
    />
  );
}
