import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import { useAuthState } from '@containers/Auth';

export const roleOptions = [
  { value: 'all', label: 'All Roles' },
  { value: 'admin', label: 'Admin - TurnSignl' },
  { value: 'org_admin', label: 'Admin - Business' },
  { value: 'lawyer', label: 'Attorney' },
  { value: 'client', label: 'Client' }
];

export const businessAdminRoleOptions = [
  { value: 'all', label: 'All Roles' },
  { value: 'org_admin', label: 'Admin - Business' },
  { value: 'client', label: 'Client' }
];

export const attorneyAdminRoleOptions = [
  { value: 'all', label: 'All Roles' },
  { value: 'lawyer', label: 'Attorney' }
];

export const useRoleFilter = (initialState = roleOptions[0]) => {
  return useState(initialState);
};
const RoleFilter = ({ role, setRole }) => {
  const user = useAuthState();
  return (
    <>
      {user.is_org_admin && !user.business.is_law_firm ? (
        <Dropdown options={businessAdminRoleOptions} value={role} setValue={setRole} />
      ) : null}
      {user.is_org_admin && user.business.is_law_firm ? (
        <Dropdown options={attorneyAdminRoleOptions} value={role} setValue={setRole} />
      ) : null}
      {!user.is_org_admin ? (
        <Dropdown options={roleOptions} value={role} setValue={setRole} />
      ) : null}
    </>
  );
};

export default RoleFilter;
