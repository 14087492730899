import { createContext, useReducer, useContext } from 'react';
import Loader from '@components/Loader';
const LoaderDispatchContext = createContext();

function loadingReducer(state = 0, action) {
  switch (action.type) {
    case 'START_LOADING':
      return state + 1;
    case 'STOP_LOADING':
      return state - 1;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function LoaderProvider({ children }) {
  const [state, dispatch] = useReducer(loadingReducer, 0);
  return (
    <LoaderDispatchContext.Provider value={dispatch}>
      {children}
      {state ? <Loader /> : null}
    </LoaderDispatchContext.Provider>
  );
}

function useLoaderDispatch() {
  const context = useContext(LoaderDispatchContext);
  if (context === undefined) {
    throw new Error('useLoaderDispatch must be used within a LoaderProvider');
  }
  return context;
}

export { LoaderProvider, useLoaderDispatch };
