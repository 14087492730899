import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';
import usePaginationRequest from '@containers/usePaginationRequest';
import useLoadedOptions from '@containers/useLoadedOptions';
import { classNames } from '@utils';

export const businessFilterAllOption = { id: 'all', name: 'All Businesses' };
export const useBusinessFilter = (initialState = businessFilterAllOption) => {
  return useState(initialState);
};
const initialOptions = [businessFilterAllOption];
const BusinessFilter = ({ business, setBusiness }) => {
  const request = usePaginationRequest('businesses', '/business/names.json', 100),
    options = useLoadedOptions(request.data?.business_names, initialOptions);
  return (
    <Dropdown
      options={options}
      value={business}
      setValue={setBusiness}
      valueAccessor="id"
      labelAccessor="name"
    />
  );
};

export default BusinessFilter;
