import Header from './Header';

const Layout = ({ children }) => (
  <>
    <Header />
    <main className="workspace overflow-hidden">{children}</main>
  </>
);

export default Layout;
