import EditableValue from '@components/Form/Fields/EditableValue';
import Radio, { StatelessRadioComponent } from '@components/Form/Fields/Radio';
import ToggleForm from '@components/Form/ToggleForm';
import Table from '@components/Table';
import Section from '@components/Section';
import { StateFormWrapper } from '@components/State/Form';
import { withRequest } from '@containers/RequestContext';
import StateSelect from '@containers/StateSelect';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';
import useScrollTo from '@containers/useScrollTo';
import { updateState } from '@utils/api/state';
import { replaceAt } from '@utils';

const countyTableCols = [
  // { label: 'ID', cellDataGetter: (cell) => (cell?.rowData?.id ? cell.rowData.id : ''), key: 'id' },
  {
    label: 'County',
    cellDataGetter: (cell) => (cell?.rowData?.name ? cell.rowData.name : ''),
    key: 'name'
  }
];

const makeStateDefaultValues = ({ active, counties, ...state }) => ({
  active: active ? 'true' : 'false',
  //counties_attributes: counties,
  ...state
});
export const MarketProfilePage = ({ state, onStateUpdate }) => (
  <ToggleForm
    form={StateFormWrapper}
    formProps={{
      url: `/states/${state.id}.json`,
      defaultValues: makeStateDefaultValues(state),
      onSubmit: updateState,
      onSuccess: ({ response }) => {
        onStateUpdate(response);
      },
      to: false,
      toast: 'Market updated'
    }}
  >
    {(isEditing) => (
      <div className="accordion border border-gray-300 dark:border-gray-900 rounded-xl mt-2">
        <Section title="Profile Details" collapsible={false} initialIsOpen>
          <div className="lg:flex">
            <div className="lg:w-1/3 lg:pr-2 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
              <EditableValue
                value={state.name}
                isEditing={isEditing}
                name="name"
                label="Market Name"
                placeholder="Market Name"
              />
            </div>
            <div className="lg:w-1/3 lg:px-4 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
              <EditableValue
                value={state.abbrev}
                isEditing={isEditing}
                name="abbrev"
                label="Abbreviation"
                placeholder="Abbreviation"
              />
            </div>
            <div className="lg:w-1/3 lg:px-4">
              <EditableValue
                defaultValue={state.active}
                value={
                  <StatelessRadioComponent
                    as="input"
                    checked={state.active}
                    radioLabel="Active"
                    radioLabelTwo="Inactive"
                    disabled
                  />
                }
                label="Status"
                as={Radio}
                isEditing={isEditing}
                name="active"
                radioLabel="Active"
                radioLabelTwo="Inactive"
              />
            </div>
          </div>
          {/*<div className="lg:flex">
            <div className="lg:w-1/2 lg:pr-2 lg:border-r lg:border-gray-300 lg:dark:border-gray-900">
              <div className="lg:pr-2">
                <EditableValue value={state.name} isEditing={isEditing} name="name" label="Market Name" placeholder="Market Name" />
                <EditableValue value={state.description} isEditing={isEditing} name="description" label="Description" placeholder="Description" />
                <EditableValue value={state.address1} isEditing={isEditing} name="address" label="Address" placeholder="Address 1" />
                <EditableValue value={state.address2} isEditing={isEditing} name="address" placeholder="Address 2" />
              </div>
              <div className="lg:flex">
                <div className="lg:w-1/3 lg:pr-2">
                  <EditableValue value={state.city} isEditing={isEditing} name="city" placeholder="City" label="City" />
                </div>
                <div className="lg:w-1/3 lg:px-2">
                  <EditableValue value={state.state_id} as={StateSelect} isEditing={isEditing} name="state_id" placeholder="State" label="State" />
                </div>
                <div className="lg:w-1/3 lg:px-2">
                  <EditableValue value={state.zip_code} isEditing={isEditing} type="number" pattern="[0-9]*" min={0} inputmode="numeric" name="zipcode" placeholder="Zip Code" label="Zip Code" />
                </div>
              </div>
              <div className="lg:flex">
                <div className="lg:w-1/2 lg:pr-2">
                  <EditableValue value={state.phone && phoneFormat(state.phone)} as={PhoneNumberInput} isEditing={isEditing} name="phone" label="Phone" placeholder="Phone" />
                </div>
                <div className="lg:w-1/2 lg:px-2">
                  <EditableValue value={state.email} isEditing={isEditing} name="email" type="email" label="Email" placeholder="Email" />
                </div>
              </div>
              <div className="lg:flex">
                <div className="lg:w-1/2 lg:pr-2">
                  <EditableValue value="N/A" isEditing={isEditing} name="contact" label="Contact" placeholder="Contact" />
                </div>
                <div className="lg:w-1/2 lg:px-2">
                  <EditableValue value="N/A" isEditing={isEditing} name="time_zone" label="Time Zone" placeholder="Time Zone" />
                </div>
              </div>
              <div className="lg:flex">
                <div className="lg:w-1/2 lg:pr-2">
                  <EditableValue defaultValue={state.active} value={<StatelessRadioComponent as="input" checked={state.active} noMargin radioLabel="Active" radioLabelTwo="Inactive" disabled />} label="Status" as={Radio} isEditing={isEditing} name="active" radioLabel="Active" radioLabelTwo="Inactive" />
                </div>
              </div>
            </div>*/}
        </Section>
        {/*<Section title="County Availability" initialIsOpen border >
          <div className="h-96 flex-shrink-0 overflow-x-auto">
            <Table
              rows={state.counties}
              cols={countyTableCols}
              noLink
            />
          </div>
        </Section>*/}
      </div>
    )}
  </ToggleForm>
);

const MarketPage = withParams(
  withRequest(({ params: { stateId }, request: { data, updateData } }) => {
    const id = Number(stateId),
      idx = data.findIndex((s) => s.id === id),
      handleStateUpdate = (state) => {
        updateData(replaceAt(data, idx, state));
      };
    return <MarketProfilePage state={data[idx]} onStateUpdate={handleStateUpdate} />;
  })
);
export const Scroller = ({ children, check, delay, offset = 60 }) => {
  const ref = useScrollTo(check, offset, delay);
  return (
    <div style={{ minHeight: '100vh' }} ref={ref}>
      {children}
    </div>
  );
};
const MarketPageLayout = withParams(({ params: { stateId } }) => (
  <Scroller check={stateId}>
    <section className="breadcrumb mt-5">
      <h1>Market Profile</h1>
    </section>
    <div className="lg:flex lg:-mx-4">
      <div className="w-full lg:px-4">
        <div className="card p-5">
          <MarketPage />
        </div>
      </div>
    </div>
  </Scroller>
));
export default withPageTitle(MarketPageLayout, 'Market Details');
