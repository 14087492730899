import logo from '@assets/images/TurnSignl_TurnSignl-Icon-Primary.svg';
import styles from './index.module.css';

const Shell = () => (
  <div className={styles.container}>
    <img alt="TurnSignl logo" src={logo} className={styles.logo} />
  </div>
);

export default Shell;
