import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.createElement('div');
document.body.appendChild(modalRoot);

export default function Portal({ children }) {
  const el = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    if (modalRoot) {
      modalRoot.appendChild(el);
      return () => {
        modalRoot.removeChild(el);
      };
    }
  }, [el]);

  return createPortal(children, el);
}
