import { RequestLoader } from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import PaginationButtons from '@components/PaginationButtons';

export default function Pagination({ status, error, data, query, children }) {
  if (status === 'loading') {
    return <RequestLoader />;
  }
  if (status === 'error') {
    return <ErrorDisplay error={error} />;
  }
  return (
    <div>
      <div className="card p-5">{children}</div>
      <div className="mt-5">
        <PaginationButtons
          itemCount={data.result_count}
          pageSize={data.page_size}
          page={data.page}
          pageCount={data.page_count}
          query={query}
        />
      </div>
    </div>
  );
}
