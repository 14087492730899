import { IncidentListPage } from '@components/Incident/ListPage';
import { Scroller } from '@components/State/View';
import withPageTitle from '@containers/withPageTitle';
import useRequest from '@containers/useRequest';
import withParams from '@containers/withParams';
import { getFormattedDate, getDuration } from '@utils';

const recentActivitiesTableCols = [
  {
    label: 'Date',
    cellDataGetter: (cell) => (cell?.rowData ? getFormattedDate(cell.rowData.set_active_at) : ''),
    key: 'set_active_at'
  },
  {
    label: 'Total Calls',
    cellDataGetter: (cell) =>
      cell?.rowData?.attempted_call_count ? cell.rowData.attempted_call_count : '',
    key: 'attempted_call_count'
  },
  {
    label: 'Received',
    cellDataGetter: (cell) =>
      cell?.rowData?.connected_call_count ? cell.rowData.connected_call_count : '',
    key: 'connected_call_count'
  },
  {
    label: 'Accepted',
    cellDataGetter: (cell) =>
      cell?.rowData?.accepted_call_count ? cell.rowData.accepted_call_count : '',
    key: 'accepted_call_count'
  },
  {
    label: 'Declined',
    cellDataGetter: (cell) =>
      cell?.rowData?.declined_call_count ? cell.rowData.declined_call_count : '',
    key: 'declined_call_count'
  },
  {
    label: 'Missed',
    cellDataGetter: (cell) =>
      cell?.rowData?.attempted_call_count
        ? cell.rowData.connected_call_count
          ? cell.rowData.attempted_call_count - cell.rowData.connected_call_count
          : cell.rowData.attempted_call_count
        : '',
    key: 'missed_call_count'
  },
  {
    label: 'Available Hours',
    cellDataGetter: (cell) =>
      cell?.rowData?.set_active_at
        ? getDuration(
            cell.rowData.set_active_at,
            cell.rowData.set_inactive_at ? cell.rowData.set_inactive_at : new Date().getTime()
          )
        : '',
    key: 'duration'
  }
];

const AttorneySubTitle = ({ id }) => {
  const { data } = useRequest(`/lawyers/${id}.json`);
  return (
    <div className="lg:flex mb-5 text-lg">
      <div>
        <b>Name:</b> {data?.user?.first_name} {data?.user?.last_name}
      </div>
      <div className="my-3 lg:ml-5 lg:my-0">
        <b>Specialty:</b> {data?.practice_type?.name}
      </div>
    </div>
  );
};
const UserIncidentListPageLayout = withParams(({ params: { lawyerId }, pageSize = 25 }) => (
  <Scroller check={lawyerId}>
    <IncidentListPage
      query={{ lawyer_id: lawyerId }}
      lawyerId={lawyerId}
      pageSize={pageSize}
      showClientOnly
      placeholder="There are no incidents to view"
      className="h-96"
      wrapperClassName="mt-5"
      title="Incident Monitor"
      subtitle={<AttorneySubTitle id={lawyerId} />}
    />
  </Scroller>
));

export default withPageTitle(UserIncidentListPageLayout, 'Incident Monitor');
