import { RequestLoader } from '@components/Loader';
import ErrorDisplay from '@components/ErrorDisplay';
import useRequest from '@containers/useRequest';

export default function Request({ url, children }) {
  const { status, data, error, makeRequest } = useRequest(url);
  if (status === 'loading') {
    return <RequestLoader />;
  }
  if (status === 'error') {
    return <ErrorDisplay error={error} />;
  }
  return children(data, makeRequest);
}
