import { Link } from 'react-router-dom';
import Button from '@components/Button';
import Request from '@containers/Request';
import withParams from '@containers/withParams';
import withPageTitle from '@containers/withPageTitle';
import { makeDateStr, getTime, getDuration, classNames } from '@utils';

const IncidentPage = withParams(({ params: { id, userId }, title = 'Call Details' }) => (
  <Request url={`/incidents/${id}.json`}>
    {(response) => (
      <>
        <section className="breadcrumb lg:flex items-start">
          <div>
            <h1>{title}</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {userId ? (
                <>
                  <li className="divider la la-arrow-right"></li>
                  <li>
                    <Link to="/users">Users</Link>
                  </li>
                  <li className="divider la la-arrow-right"></li>
                  <li>
                    <Link to={`/users/${userId}`}>User Details</Link>
                  </li>
                  <li className="divider la la-arrow-right"></li>
                  <li>User Calls</li>
                </>
              ) : (
                <>
                  <li className="divider la la-arrow-right"></li>
                  <li>Calls</li>
                </>
              )}

              <li className="divider la la-arrow-right"></li>
              <li>{title}</li>
            </ul>
          </div>
          <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
            {response.video_url && (
              <Button as="a" href={response.video_url} download secondary outlined>
                Download Video
              </Button>
            )}
          </div>
        </section>
        <div className="lg:flex lg:-mx-4">
          {response.video_url && (
            <div className="lg:w-3/5 lg:px-4">
              <div className="card card_column">
                <video width="100%" height="auto" controls>
                  <source src={response.video_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
          <div className="lg:w-2/5 lg:px-4">
            <div className="card text-center">
              <div className="p-5 flex items-center justify-between">
                <h3>Incident Information</h3>
                <label className="switch text-secondary">
                  Active:
                  <input
                    type="checkbox"
                    disabled
                    value={response.active}
                    checked={response.active}
                  />
                  <span className="ml-2"></span>
                </label>
              </div>
              <hr />
              <div className="p-5 bg-gray-100 dark:bg-gray-900 bg-opacity-50 dark:bg-opacity-50 lg:flex lg:justify-around">
                <div className="mx-10">
                  <strong className="whitespace-nowrap">Start</strong>
                  <div className="text-primary mt-5 text-3xl leading-none">
                    {getTime(response.started_at)}
                  </div>
                </div>
                {response.ended_at && (
                  <div className="mx-10 mt-5 lg:mt-0">
                    <strong className="whitespace-nowrap">End</strong>
                    <div className="text-primary mt-5 text-3xl leading-none">
                      {getTime(response.ended_at)}
                    </div>
                  </div>
                )}
              </div>
              <hr />
              <div className="p-5 flex flex-wrap justify-around">
                <div>
                  <strong>Incident Type:</strong>{' '}
                  {response.incident_type === 'accident' ? 'Accident' : 'Traffic Stop'}
                </div>
                <div>
                  <strong>Date:</strong> {makeDateStr(response.started_at)}
                </div>
                <div>
                  <strong>State:</strong> {response.state.abbrev}
                </div>
              </div>
            </div>
            <div className="lg:flex lg:-mx-4">
              {response.user_id && (
                <div className="lg:w-1/2 lg:px-4 mt-5">
                  <Link
                    to={`/users/${response.user_id}`}
                    className="w-full text-gray-700 dark:text-gray-500 block card card_hoverable p-5"
                  >
                    <h4 className="text-gray-700 dark:text-gray-500">Client</h4>
                    <div className="flex items-center w-full">
                      <div className="avatar">
                        <div
                          className={classNames(
                            'status',
                            response.user_connected ? 'bg-green' : 'bg-red'
                          )}
                        ></div>
                        {response.user_name
                          .split(' ')
                          .map((word) => word[0])
                          .join('')}
                      </div>
                      <h3 className="ml-2 text-gray-700 dark:text-gray-500">
                        {response.user_name}
                      </h3>
                    </div>
                  </Link>
                </div>
              )}

              {response.lawyer_name && (
                <div className="lg:w-1/2 lg:px-4 mt-5">
                  <div className="card p-5">
                    <h4 className="text-gray-700 dark:text-gray-500">Lawyer</h4>
                    <div className="flex items-center w-full">
                      <div className="avatar">
                        <div
                          className={classNames(
                            'status',
                            response.lawyer_connected ? 'bg-green' : 'bg-red'
                          )}
                        ></div>
                        {response.lawyer_name
                          .split(' ')
                          .map((word) => word[0])
                          .join('')}
                      </div>
                      <h3 className="ml-2 text-gray-700 dark:text-gray-500">
                        {response.lawyer_name}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )}
  </Request>
));

export default withPageTitle(IncidentPage, 'Call Details');
