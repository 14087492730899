export const MENU_ADMIN = [
  { title: 'Dashboard', link: '/dashboard', icon: 'la-home' },
  { title: 'Business', link: '/businesses', icon: 'la-building' },
  { title: 'Users', link: '/users', icon: 'la-users' },
  { title: 'Leads', link: '/leads', icon: 'la-binoculars' },
  { title: 'Incidents', link: '/incidents', icon: 'la-phone-volume' },
  { title: 'Attorney Monitor', link: '/attorneys', icon: 'la-tv' },
  { title: 'Market Profile', link: '/markets', icon: 'la-globe' },
  { title: 'Campaigns', link: '/campaigns', icon: 'la-globe' }
];

export const MENU_ORG_ADMIN = [
  { title: 'Dashboard', link: '/dashboard', icon: 'la-home' },
  { title: 'Business', link: '/business', icon: 'la-building' },
  { title: 'Users', link: '/users', icon: 'la-users' }
];
