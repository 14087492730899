import axios from 'axios';
import { makeRequest, getRequest } from '@utils/api';
import handleError from '@utils/api/error';
import { stringifyQuery, difference } from '@utils';

const makeLawyerAttributes = (lawyer_attributes) =>
  lawyer_attributes &&
    Object.keys(lawyer_attributes).length !== 0 &&
    Object.keys(lawyer_attributes).filter((att) => lawyer_attributes[att]).length !== 0
    ? {
      lawyer_attributes: {
        ...lawyer_attributes,
        state_ids: lawyer_attributes.state_ids?.map(({ value }) => value)
      }
    }
    : {};

const makeRole = (role, isCreating) => {
  const obj = isCreating ? {} : { is_lawyer: false, is_admin: false, is_org_admin: false };
  if (role === 'Attorney') {
    obj.is_lawyer = true;
    obj.lawyer_attributes = {};
  } else if (role === 'Admin') {
    obj.is_admin = true;
  } else if (role === 'Business Admin' || role === 'Attorney Admin') {
    obj.is_org_admin = true;
  }
  return obj;
};
const makeUser = (
  {
    free_subscription,
    has_payment_method,
    id,
    payment_methods,
    subscription,
    subscription_active,
    subscription_is_good,
    url,
    lawyer,
    business,
    vehicle,
    has_business_subscription,
    has_individual_subscription,
    needs_payment_method,
    needs_subscription,
    subscription_type,
    checked_qualify_for_free,
    confirmed,
    status,
    created_at,
    active,
    business_active,
    lawyer_attributes,
    is_lawyer,
    is_org_admin,
    is_admin,
    role,
    ...user
  },
  isCreating
) => ({
  user: {
    ...user,
    ...makeRole(role, isCreating),
    ...(role === 'Attorney' || role === 'Attorney Admin'
      ? makeLawyerAttributes(lawyer_attributes)
      : {}),
    ...(user.business_id ? { business_active: business_active === 'true' } : {}),
    ...(isCreating ? {} : { active: active === 'true' })
  }
});

const makeLead = (user) => {
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    state_id: user.state_id,
    is_lawyer: user.is_lawyer
  };
};

const makeUserUrl = (role) => {
  if (role === 'Attorney') {
    return '/user/lawyer.json';
  } else if (role === 'Admin') {
    return '/user/admin.json';
  }
  return '/user/client.json';
};
export const createUser = (user) =>
  new Promise(async (resolve, reject) => {
    try {
      resolve(await makeRequest('POST', makeUserUrl(user.role), makeUser(user, true)));
    } catch (e) {
      reject(e);
    }
  });

export const createLead = (user) =>
  new Promise(async (resolve, reject) => {
    try {
      resolve(await makeRequest('POST', `/leads.json`, makeLead(user)));
    } catch (e) {
      reject(e);
    }
  });
export const updateUser = (user, url) => makeRequest('PATCH', url, makeUser(user))
export const updateUserLawyer = (user, url) => makeRequest('PATCH', url, user);

export const sendInvitation = (id) => makeRequest('POST', `/users/${id}/invite.json`);

export const exportUsers = (query) =>
  axios(
    `${process.env.REACT_APP_API_URL}/users.csv${query ? `?${stringifyQuery(query)}` : ''}`
  ).then(({ data }) => data, handleError);


export const exportLeads = () =>
  axios(
    `${process.env.REACT_APP_API_URL}/lead/export.csv`
  ).then(({ data }) => data, handleError);

export const exportAttorneys = (query) =>
  axios(
    `${process.env.REACT_APP_API_URL}/lawyers.csv${query ? `?${stringifyQuery(query)}` : ''}`
  ).then(({ data }) => data, handleError);
