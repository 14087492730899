import { useState, useCallback, useEffect, useRef } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Button from '@components/Button';
import Label from '@components/Form/Label';
import Portal from '@components/Portal';
import { classNames, getFileNameFromUrl, uniq, getBase64 } from '@utils';
import styles from './index.module.css';

const getExtReqStr = (str) =>
  uniq(
    str.split(', ').map((img) => {
      switch (img) {
        case 'image/png':
          return 'PNG';
        case 'image/jpg':
        case 'image/jpeg':
          return 'JPG';
        case 'image/gif':
          return 'GIF';
        default:
          return 'PNG';
      }
    })
  ).join(', ');

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob(async (file) => {
      resolve(await getBase64(file));
    }, 'image/png');
  });
}

const GalleryHeaderButton = ({
  src,
  as: WrapperComponent = 'button',
  component: Component,
  projectId,
  className,
  white,
  ...props
}) => <WrapperComponent {...props} type="button" className={className}></WrapperComponent>;
const ImageCrop = ({ src, onCrop, close, name }) => {
  const [rotation, setRotation] = useState(0),
    [zoom, setZoom] = useState(1),
    [crop, setCrop] = useState({ x: 0, y: 0 }),
    [croppedAreaPixels, setCroppedAreaPixels] = useState(null),
    onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
    }, []),
    handleCrop = useCallback(async () => {
      try {
        const croppedImage = await getCroppedImg(src, croppedAreaPixels, rotation);
        onCrop(croppedImage);
      } catch (e) {
        console.error(e);
      }
    }, [src, croppedAreaPixels, rotation, onCrop]);
  return (
    <Portal>
      <div
        className={classNames(
          styles.cropContainer,
          'flex flex-col fixed w-full h-full top-0 left-0'
        )}
      >
        <div className={classNames(styles.cropContent, 'flex-grow')}>
          <Cropper
            image={src}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <GalleryHeaderButton
          onClick={close}
          className="text-2xl w-10 h-10 rounded-full la la-times fixed top-4 right-4 text-white bg-black"
        />

        <div className="flex items-center bg-white dark:bg-gray-900 justify-center h-20">
          <GalleryHeaderButton
            className={classNames(styles.flip, 'text-4xl mx-4 la la-undo-alt')}
            onClick={() => {
              setRotation((prevRotation) => prevRotation + 90);
            }}
          />
          <GalleryHeaderButton
            className="text-4xl mx-4 la la-search-plus"
            onClick={() => {
              if (zoom < 3) setZoom((prevZoom) => prevZoom + 1);
            }}
          />
          <GalleryHeaderButton
            className="text-4xl mx-4 la la-search-minus"
            onClick={() => {
              if (zoom > 1) setZoom((prevZoom) => prevZoom - 1);
            }}
          />
          <Button type="button" className="mx-4" primary onClick={handleCrop}>
            SAVE
          </Button>
        </div>
      </div>
    </Portal>
  );
};
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    if (file && file.type?.match('image.*')) {
      reader.readAsDataURL(file);
    }
  });
}
export const useImageCropper = (file, onCrop) => {
  const [image, setImage] = useState(),
    [isOpen, setIsOpen] = useState(),
    prevFile = useRef(file),
    convertFileToImg = useCallback(async (f) => {
      setImage();
      setImage(await readFile(f));
    }, []),
    handleClose = () => {
      setIsOpen();
    },
    handleCrop = (croppedImage) => {
      onCrop(croppedImage);
      handleClose();
    };
  useEffect(() => {
    if (file && file !== prevFile.current) {
      convertFileToImg(file);
      setIsOpen(true);
    }
    prevFile.current = file;
  }, [file, setIsOpen, convertFileToImg]);

  return {
    image,
    setImage,
    isOpen,
    close: handleClose,
    onCrop: handleCrop
  };
};

const isFile = (file) => file?.name && typeof file.name === 'string';
const getFileName = (file) => (isFile(file) ? file.name : getFileNameFromUrl(file));

const FileInput = ({ name = 'logo', value, image, onClick, onDelete, src }) => (
  <div className="lg:flex lg:justify-between">
    <div className="flex-grow lg:mr-5 flex">
      <label className="input-group text-base font-normal" htmlFor={name}>
        <div className="file-name input-addon input-addon-prepend input-group-item w-full overflow-x-hidden">
          <input
            type="text"
            style={{ textOverflow: 'ellipsis', background: 'transparent', width: '100%' }}
            disabled
            readOnly
            value={value ? getFileName(value) : 'No file chosen'}
          />
        </div>
        <div className="input-group-item btn btn_secondary uppercase">Choose File</div>
      </label>
    </div>
    <div className="lg:min-w-60 flex justify-center hidden xl:flex">
      <Button className="ml-3" primary disabled={!image} onClick={!image ? undefined : onClick}>
        Upload {name}
      </Button>
      {src && (
        <Button className="ml-3" outlined secondary onClick={onDelete}>
          Delete {name}
        </Button>
      )}
    </div>
  </div>
);

const ImageDisplay = ({ src }) => {
  const [image, setImage] = useState();
  useEffect(() => {
    if (isFile(src)) {
      setImage();
      var reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(src);
    } else {
      setImage(src);
    }
  }, [src]);
  return <img src={image} />;
};

const DropzoneContainer = ({
  onChange,
  name = 'logo',
  value,
  onCrop,
  src,
  file,
  setFile,
  onClick,
  onDelete
}) => {
  const { image, isOpen, close, onCrop: handleCrop } = useImageCropper(file, onCrop);
  const onDrop = useCallback(
    (droppedFiles) => {
      if (droppedFiles && droppedFiles.length) {
        setFile(droppedFiles[0]);
      }
    },
    [setFile]
  );
  return (
    <>
      <label className="label block mb-2">Please select the logo to upload</label>
      <FileInput
        value={value ? file : undefined}
        image={value}
        src={src}
        onClick={onClick}
        onDelete={onDelete}
      />
      <div className="flex justify-center py-4 px-1">
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              className={classNames(
                'w-40 h-40 lg:w-80 lg:h-80',
                src ? 'flex items-center justify-center p-5' : 'dropzone',
                isDragActive && 'active'
              )}
              {...getRootProps()}
            >
              <input
                {...getInputProps({
                  onChange: (e) => {
                    setFile(e.target.value[0]);
                  }
                })}
                id={name}
                name={name}
                className="hidden"
              />
              {value || src ? <ImageDisplay src={value || src} /> : <h3>Drop {name} file here</h3>}
            </div>
          )}
        </Dropzone>
      </div>
      <div className="lg:min-w-60 flex justify-center md:flex lg:flex xl:hidden sm:flex">
        <Button className="" primary disabled={!value} onClick={!value ? undefined : onClick}>
          Upload {name}
        </Button>
        {src && (
          <Button className="ml-7" outlined secondary onClick={onDelete}>
            Delete {name}
          </Button>
        )}
      </div>
      {isOpen && <ImageCrop src={image} onCrop={handleCrop} close={close} name={name} />}
    </>
  );
};
export default DropzoneContainer;
