import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import Table, { Column } from 'react-virtualized/dist/commonjs/Table';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import EmptyResourcePlaceholder from '@components/EmptyResourcePlaceholder';
import { classNames } from '@utils';
import 'react-virtualized/styles.css';

const TableComponent = forwardRef(
  (
    {
      cols,
      rows = [],
      emptyText = 'There is nothing here',
      noLink,
      list,
      minWidth = 1000,
      className: tableClassName,
      hasNextPage,
      to,
      onRowClick,
      ...props
    },
    ref
  ) => {
    const rowCount = hasNextPage ? rows.length + 1 : rows.length,
      isRowLoaded = ({ index }) => !hasNextPage || index < rows.length,
      RowWrapperComponent = noLink ? 'div' : NavLink;
    const rowRenderer = ({ className, index, key, rowData, style, columns }) => {
      const rowLoaded = isRowLoaded({ index });
      return rowLoaded ? (
        <RowWrapperComponent
          to={noLink ? undefined : to ? to(rowData) : rowData?.id ? `${rowData?.id}` : ''}
          onClick={
            onRowClick
              ? () => {
                onRowClick(rowData);
              }
              : undefined
          }
          key={key}
          style={style}
          className={classNames(className, 'tr')}
          activeclassname="row_selected"
        >
          {columns}
        </RowWrapperComponent>
      ) : (
        <div key={key} style={style} className={classNames(className, 'tr', 'text-center')}>
          Loading...
        </div>
      );
    };
    return (
      <AutoSizer>
        {(sizer) => {
          const width = minWidth ? (sizer.width < minWidth ? minWidth : sizer.width) : sizer.width;
          return (
            <Table
              className={classNames(
                'table w-full',
                (!noLink || onRowClick) && 'table_hoverable',
                list && 'table_list',
                tableClassName
              )}
              headerHeight={30}
              noRowsRenderer={() => <div>{emptyText}</div>}
              rowHeight={50}
              rowGetter={({ index }) => rows[index] ?? {}}
              rowCount={rowCount}
              rowRenderer={rowRenderer}
              gridClassName="scrollbar"
              ref={ref}
              {...props}
              width={width}
              height={sizer.height}
            >
              {cols.map((col) => (
                <Column
                  {...col}
                  className="td"
                  headerClassName="th"
                  dataKey={col.key}
                  width={col?.width ? col?.width : width}
                />
              ))}
            </Table>
          );
        }}
      </AutoSizer>
    );
  }
);

const TableContainer = forwardRef(
  ({ rows, placeholder = 'There is nothing here', ...props }, ref) =>
    rows?.length > 0 ? (
      <TableComponent {...props} rows={rows} ref={ref} />
    ) : (
      <EmptyResourcePlaceholder text={placeholder} />
    )
);

export default TableContainer;
