import { Outlet, NavLink } from 'react-router-dom';
import { Scroller } from '@components/State/View';
import RequestProvider from '@containers/RequestContext';
import withParams from '@containers/withParams';
import { withRequest } from '@containers/RequestContext';

const MarketsLink = withRequest(({ request: { data } }) => (
  <NavLink to="markets" className="nav-link h5 uppercase">
    Markets
  </NavLink>
));

const FilesLink = withRequest(({ request: { data } }) => (
  data?.has_bucket && <NavLink to="files" className="nav-link h5 uppercase">
    Files
  </NavLink>
));

export const BusinessDetails = ({ businessId, isBusinessAdmin }) => {
  // const { data } = useRequestState();
  return (
    <>
      <section className="breadcrumb mt-5">
        <h1>Business Details</h1>
      </section>
      <RequestProvider url={`/businesses/${businessId}.json`}>
        <div className="lg:flex lg:-mx-4">
          <div className="w-full lg:px-4">
            <div className="card p-5">
              <div className="tabs">
                <nav className="tab-nav mt-5">
                  <NavLink to="profile" className="nav-link h5 uppercase" activeclassname="active">
                    Profile
                  </NavLink>
                  {!isBusinessAdmin && (
                    <>
                      <NavLink to="users" className="nav-link h5 uppercase">
                        Users
                      </NavLink>
                      <MarketsLink />
                    </>
                  )}
                  <NavLink to="import" className="nav-link h5 uppercase" activeclassname="active">
                    Import
                  </NavLink>
                  <NavLink to="events" className="nav-link h5 uppercase">
                    Events
                  </NavLink>
                  <FilesLink />
                </nav>
                <div className="tab-content mt-2">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </RequestProvider>
    </>
  );
}

const BusinessDetailsLayout = withParams(({ params: { businessId } }) => (
  <Scroller check={businessId}>
    <BusinessDetails businessId={businessId} />
  </Scroller>
));

export default BusinessDetailsLayout;
