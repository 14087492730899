export class AuthorizationError extends Error {
  constructor(status) {
    super(status);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError);
    }
    this.name = 'AuthorizationError';
  }
}

export class TokenError extends Error {
  constructor() {
    super();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, TokenError);
    }
    this.name = 'TokenError';
  }
}
const makeErrorMessage = (errors) => errors?.[0] || 'An unexpected error has occurred';
export class RequestError extends Error {
  constructor(status, errors = {}) {
    super(status);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }
    this.name = 'RequestError';
    this.status = status;
    //this.fieldErrors = makeFieldErrorsObj(errors);
    this.message = makeErrorMessage(errors);
  }
}

export default function handleError(e) {
  if (e.response.status === 401) {
    throw new AuthorizationError(e.response.status);
  } else if (e.response.status === 400 && e.response.data?.error === 'invalid_grant') {
    throw new TokenError();
  } else {
    throw new RequestError(e.response.status, e.response.data?.errors);
  }
}
