import { useRef, useEffect, forwardRef } from 'react';
import useAccordion from '@containers/useAccordion';
import useRequest from '@containers/useRequest';
import { classNames } from '@utils';
import styles from './index.module.css';

const Header = ({ children, collapsible, isOpen, border, ...props }) => (
  <h5
    className={classNames('p-5', border && 'border-t border-gray-300 dark:border-gray-900')}
    role={collapsible ? 'sectionHeader' : undefined}
    data-toggle={collapsible ? 'collapse' : undefined}
    {...props}
  >
    {children}
    {collapsible && (
      <span
        className={classNames(
          'collapse-indicator la',
          isOpen ? 'la-arrow-circle-up' : 'la-arrow-circle-down'
        )}
      ></span>
    )}
  </h5>
);

const Content = forwardRef(({ children, className, isOpen, ...props }, ref) => (
  <div
    {...props}
    role="content"
    className={classNames('collapse', className, isOpen && 'open')}
    ref={ref}
  >
    <div className="p-5 pt-0">{children}</div>
  </div>
));

const Accordion = ({
  title,
  collapsible = true,
  className,
  children,
  wrapperClassName,
  initialIsOpen,
  border
}) => {
  const accordion = useAccordion(collapsible ? initialIsOpen : true);
  return (
    <>
      <Header
        onClick={collapsible ? accordion.toggle : undefined}
        border={border}
        collapsible={collapsible}
        isOpen={accordion.state.isOpen}
      >
        {title}
      </Header>
      <Content
        style={collapsible ? { maxHeight: accordion.state.maxHeight } : undefined}
        isOpen={accordion.state.isOpen}
        className={wrapperClassName}
        ref={accordion.ref}
      >
        {typeof children === 'function' ? children(accordion) : children}
      </Content>
    </>
  );
};

function useAccordionRequestHandler(isOpen, status, setHeight = () => {}, makeRequest = () => {}) {
  const requestMade = useRef(false);

  useEffect(() => {
    if (isOpen && !requestMade.current) {
      makeRequest();
      requestMade.current = true;
    }
  }, [isOpen, makeRequest]);

  useEffect(() => {
    if (isOpen) {
      setHeight();
    }
  }, [isOpen, status]);

  return null;
}

export const RequestAccordion = ({ url, query, children, ...props }) => (
  <Accordion {...props}>
    {(accordion) => {
      const request = useRequest(url, query, true);
      useAccordionRequestHandler(
        accordion.state.isOpen,
        request.status,
        accordion.setHeight,
        request.makeRequest
      );
      return children(request.data, request.makeRequest, request.updateData);
    }}
  </Accordion>
);

export default Accordion;
