export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const BOARD_CARDS_DATA = [
  {
    id: 1,
    label: 'Active Users',
    value: 'Active Users',
    title: 'No. Of Active Users (Total)',
    count: 0,
    type: 'board_user_count',
    isShow: true,
    isControl: false,
    createdAt: new Date()
  },
  {
    id: 2,
    label: 'New Users',
    value: 'New Users',
    title: 'No. Of New Users (Current Month)',
    count: 0,
    type: 'board_new_user_count',
    isShow: true,
    isControl: false,
    createdAt: new Date()
  },
  {
    id: 3,
    label: 'Incidents',
    value: 'Incidents',
    title: 'No. Of Incidents (Current Month)',
    count: 0,
    type: 'board_incident_count',
    isShow: true,
    isControl: false,
    createdAt: new Date()
  }
];

export const CHART_COLUMN_CARDS_DATA = [
  {
    id: 1,
    label: 'Monthly Active Users',
    value: 'Monthly Active Users',
    type: 'column_chart_active_users',
    isShow: true,
    isControl: false,
    data: {
      series: [
        {
          name: 'Count',
          data: []
        }
      ],
      options: {
        chart: {
          height: 350,
          width: 34,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: ['#4279ab']
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        floating: false,
        dataLabels: {
          show: true,
          enabled: true,
          formatter: function (val) {
            return isNaN(val) || val === 0 ? '' : val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#555555']
          }
        },

        xaxis: {
          categories: [],
          position: 'bottom',
          floating: false,
          labels: {
            show: true,
            rotate: -90,
            offsetX: -1
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          style: {
            fontSize: '12px',
            colors: ['#aaaaaa']
          }
        },
        yaxis: {
          title: {
            text: 'Count of Active Users'
          },
          floating: false,
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    createdAt: new Date()
  },
  {
    id: 2,
    label: 'Monthly New Users',
    value: 'Monthly New Users',
    type: 'column_chart_new_users',
    isShow: true,
    isControl: false,
    data: {
      series: [
        {
          name: 'Count',
          data: []
        }
      ],
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        fill: {
          colors: [
            '#4279ab',
            '#26e7a6',
            '#febc3b',
            '#ff6178',
            '#8b75d7',
            '#6d848e',
            '#46b3a9',
            '#d830eb',
            '#54abd6',
            '#323a3e',
            '#498929',
            '#4a6fd7'
          ]
        },
        plotOptions: {
          bar: {
            dataLabels: {
              offsetY: -20,
              position: 'top' // top, center, bottom
            }
          }
        },
        floating: false,
        dataLabels: {
          show: true,
          enabled: true,
          formatter: function (val) {
            return isNaN(val) || val === 0 ? '' : val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#555555']
          }
        },

        xaxis: {
          categories: [],
          position: 'bottom',
          floating: false,
          labels: {
            show: true,
            rotate: -90,
            offsetX: -1
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          },
          style: {
            fontSize: '12px',
            colors: ['#aaaaaa']
          }
        },
        yaxis: {
          title: {
            text: 'Count of New Users'
          },
          floating: false,
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        }
      }
    },
    createdAt: new Date()
  },
  {
    id: 3,
    label: 'Monthly Incidents',
    value: 'Monthly Incidents',
    type: 'column_chart_incidents',
    isShow: true,
    isControl: false,
    data: {
      series: [],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'top',
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          floating: false,
          categories: [],
          labels: {
            show: true,
            rotate: -90,
            offsetX: -1
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          floating: false,
          title: {
            text: 'Count of Incidents'
          }
        },
        legend: {
          show: true,
          position: 'right',
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          opacity: 1
        }
      }
    },
    createdAt: new Date()
  }
];

export const CHART_CIRCLE_CARDS_DATA = [
  {
    id: 1,
    label: 'Users By State',
    value: 'Users By State',
    description: '% of Subscribers by State',
    type: 'pie_chart_state',
    isShow: true,
    isControl: false,
    data: {
      series: [],
      options: {
        chart: {
          type: 'pie'
        },
        legend: {
          show: true,
          position: 'right',
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        dataLabels: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                show: true
              }
            }
          }
        ]
      }
    },
    createdAt: new Date()
  },
  {
    id: 2,
    label: 'Incidents By State',
    value: 'Incidents By State',
    description: '% of Incidents by State',
    type: 'pie_chart_incident_state',
    isShow: true,
    isControl: false,
    data: {
      series: [],
      options: {
        chart: {
          type: 'pie'
        },
        legend: {
          show: true,
          position: 'right',
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        dataLabels: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                show: true
              }
            }
          }
        ]
      }
    },
    createdAt: new Date()
  },
  {
    id: 3,
    label: 'Incidents By Types',
    value: 'Incidents By Types',
    description: '% of Incidents by Type',
    type: 'pie_chart_incident_type',
    isShow: true,
    isControl: false,
    data: {
      series: [],
      options: {
        chart: {
          type: 'pie'
        },
        legend: {
          show: true,
          position: 'right',
          markers: {
            width: 16,
            height: 16,
            strokeWidth: 0,
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        dataLabels: {
          enabled: true
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                show: true
              }
            }
          }
        ]
      }
    },
    createdAt: new Date()
  }
];

export const BUSINESS_ADMIN_SECTIONS = [
  {
    id: 1,
    value: 'Section 1',
    label: 'Board',
    isShow: true,
    isControl: false,
    cards: BOARD_CARDS_DATA
  },
  {
    id: 2,
    value: 'Section 2',
    label: 'Bar Chart',
    isShow: true,
    isControl: false,
    cards: CHART_COLUMN_CARDS_DATA
  },
  {
    id: 3,
    value: 'Section 3',
    label: 'Pie Chart',
    isShow: true,
    isControl: false,
    cards: CHART_CIRCLE_CARDS_DATA
  }
];
