import { useAuthDispatch, logoutAction } from '@containers/Auth';
import { classNames } from '@utils';

const LogoutButton = ({ children, ...props }) => {
  const dispatch = useAuthDispatch(),
    handleClick = () => {
      localStorage.clear();
      dispatch(logoutAction());
    };
  return (
    <button {...props} type="button" onClick={handleClick}>
      {children || 'Logout'}
    </button>
  );
};

export default LogoutButton;
