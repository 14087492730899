import { useCallback, useState, useRef, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { format } from "date-fns";
import FilterRow from '@components/Filter/Row';
import Button from '@components/Button';
import Modal from '@components/Modal';
import Loader from '@components/Loader';
import ExportFormWrapper from '@components/AttorneyMonitor/Form';
import BusinessFilter, {
  useBusinessFilter,
  businessFilterAllOption
} from '@components/Filter/Business';
import ActiveFilter, { useActiveFilter, activeOptions } from '@components/Filter/Active';
import StateFilter, { useStateFilter, stateFilterAllOption } from '@components/Filter/State';
import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';
import usePaginationRequest from '@containers/usePaginationRequest';
import useSort from '@containers/useSort';
import useEffectAfterMount from '@containers/useEffectAfterMount';
import withPageTitle from '@containers/withPageTitle';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { classNames } from '@utils';
import { exportAttorneys } from '@utils/api/user'
import styles from './index.module.css';

export const AddExportModalButton = () => {
  const [isOpen, setIsOpen] = useState(),
    handleClose = () => setIsOpen(false),
    [monthFilter, setMonthFilter] = useState(new Date()),
    [fileLink, setFileLink] = useState(),
    [loading, setLoading] = useState(),
    linkRef = useRef(),
    notFuture = (date) => {
      return date < new Date();
    },
    handleOpen = () => setIsOpen(true),
    handleClick = async () => {
      setLoading(true);
      const csvFile = await exportAttorneys({ date: format(monthFilter, "MM-yyyy") });
      setFileLink('data:text/csv;charset=UTF-8,' + encodeURIComponent(csvFile));
      setLoading(false);
    },
    handleSuccess = () => {
      handleClose();
      handleClick();
    };
  useEffect(() => {
    if (fileLink && linkRef?.current) {
      linkRef.current.click();
      setFileLink('');
    }
  }, [fileLink]);
  return (
    <>
      <Button onClick={handleOpen} primary className="ml-2">
        Export
      </Button>
      <Modal
        component={ExportFormWrapper}
        isOpen={isOpen}
        title="Attorney Health"
        onContinueClick={handleSuccess}
        close={handleClose}
        continueText="Export"
        form
      >
        <div className="flex items-center">
          <div className={styles.w25}>Select Month:</div>
          <DatePicker
            selected={monthFilter}
            onChange={setMonthFilter}
            placeholderText="MM/YYYY"
            dateFormat="MM/yyyy"
            filterDate={notFuture}
            showMonthYearPicker
            showFullMonthYearPicker
            ShowTwoColumnMonthYearPicker
          />
        </div>
      </Modal>
      <a href={fileLink} ref={linkRef} download={"turnsignl-attorney-monitor-" + format(monthFilter, "MM-yyyy") + ".csv"} className="hidden"></a>
      {loading && <Loader />}
    </>
  );
};

const userTableCols = [
  {
    label: 'Name',
    cellDataGetter: (cell) => `${cell?.rowData?.user?.first_name} ${cell?.rowData?.user?.last_name}`,
    key: 'first_name'
  },
  {
    label: 'Times',
    cellDataGetter: (cell) => cell?.rowData?.activity_ranges?.length > 0 ? cell.rowData.activity_ranges.join(', ') : '',
    key: 'activity_ranges',
    width: 2000
  },
  {
    label: 'States',
    cellDataGetter: (cell) =>
      cell?.rowData?.states?.length > 0
        ? cell.rowData.states.map(({ abbrev }) => abbrev).join(', ')
        : '',
    key: 'state_id',
    disableSort: true
  },
  {
    label: 'Accepted',
    cellDataGetter: (cell) => (cell?.rowData?.accepted_calls),
    key: 'accepted_calls'
  },
  {
    label: 'Potential',
    cellDataGetter: (cell) => (cell?.rowData?.potential_calls),
    key: 'potential_calls'
  },
  {
    label: 'Practice Type',
    cellDataGetter: (cell) =>
      cell?.rowData?.practice_type?.name ? cell.rowData.practice_type.name : '',
    key: 'practice',
    disableSort: true
  }
];
const listAccessor = ({ lawyers }) => lawyers;
const UserDetailsLayout = ({ pageSize = 25, businessId, url = '/lawyers.json' }) => {
  const request = usePaginationRequest('lawyers', url, pageSize),
    [activeFilter, setActiveFilter] = useActiveFilter(activeOptions[1]),
    [businessFilter, setBusinessFilter] = useBusinessFilter(),
    [stateFilter, setStateFilter] = useStateFilter(),
    { order, ...sort } = useSort(),
    [filterDate, setFilterDate] = useState(new Date()),
    makeQuery = useCallback(() => {
      const query = { order };
      if (activeFilter.value !== activeOptions[0].value) {
        query.active = activeFilter.value;
      }
      if (businessFilter.id !== businessFilterAllOption.id) {
        query.business_id = businessFilter.id;
      }
      if (stateFilter.id !== stateFilterAllOption.id) {
        query.state_id = stateFilter.id;
      }
      if (filterDate) {
        query.date = format(filterDate, "dd-MM-yyyy");
      }
      return query;
    }, [order, businessFilter, activeFilter, stateFilter, filterDate]),
    makeQueryRequest = useCallback(() => {
      request.setQuery(makeQuery());
    }, [makeQuery]);

  useEffectAfterMount(makeQueryRequest);
  return (
    <>
      <section className="breadcrumb lg:flex items-start">
        <h1>Availability Monitor</h1>
        <div className="lg:flex items-center ml-auto mt-5 lg:mt-0">
          <FilterRow>
            {!businessId && (
              <BusinessFilter business={businessFilter} setBusiness={setBusinessFilter} />
            )}
            {/* <ActiveFilter active={activeFilter} setActive={setActiveFilter} /> */}
            <div className="ml-2">
              <DatePicker
                selected={filterDate}
                onChange={setFilterDate}
                placeholderText="MM-DD-YYYY"
                dateFormat="MM-dd-yyyy"
                className={styles.date}
              />
            </div>
            <StateFilter state={stateFilter} setState={setStateFilter} />
            <AddExportModalButton />
          </FilterRow>
        </div>
      </section>
      <div className={classNames(styles.hv70 + " card p-5 flex-shrink-0 overflow-x-auto")}>
        <InfiniteLoaderContainer
          {...request}
          query={makeQuery()}
          listAccessor={listAccessor}
          cols={userTableCols}
          {...sort}
        />
      </div>
      <Outlet />
    </>
  );
};

export default withPageTitle(UserDetailsLayout, 'Availability Monitor');
