import { UserDetailsLayout } from '@components/User/ListLayout';
import { ProfileDetailsLayout } from '@components/Profile/ListLayout';
import Section from '@components/Section';
import withPageTitle from '@containers/withPageTitle';
import withParams from '@containers/withParams';
import { withRequest } from '@containers/RequestContext';
import styles from './index.module.css';
import Button from '@components/Button';
import { useState } from 'react';
import { reinviteUsers } from '@utils/api/business';
import { useToastAddAction } from '@containers/ToastContext';

const UserPage = ({ pageSize, businessId, business }) => {
  const [isDisabled, setDisabled] = useState(false);
  const addToast = useToastAddAction();
  return (
    <>
      <div className="accordion rounded-xl mt-2">
        <Section
          title={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <div>User Management</div>
                {
                  business.legalease_partner ?
                    <div className={styles.userCount}>
                      Active User Count: <b>{business.organization_subscription?.user_count || '0'}</b>
                      <br></br>
                      Lives: <b>{business.profile_count || '0'}</b>
                    </div>
                    :
                    <div className={styles.userCount}>
                      Active User Count: <b>{business.organization_subscription?.user_count || '0'}</b>
                    </div>
                }
              </div>
              <Button onClick={() => {
                setDisabled(true);
                reinviteUsers(`/businesses/${businessId}/reinvite.json`).then((response) => {
                  addToast(response.mssg);
                })
              }} primary disabled={isDisabled} className="ml-2 my-1">
                {'Reinvite Users'}
              </Button>
            </div>
          }
          collapsible={false}
          initialIsOpen
          border
        >
          {
            business.legalease_partner ?
              <ProfileDetailsLayout
                pageSize={pageSize}
                className="h-96"
                businessId={businessId}
                to={(user) => `/users/${user?.user_id}`}
              />
              :
              <UserDetailsLayout
                pageSize={pageSize}
                className="h-96"
                businessId={businessId}
                to={(user) => `/users/${user?.id}`}
              />
          }
        </Section>
      </div>
    </>
  )
}

const BusinessUsersPage = withParams(
  withRequest(({ pageSize = 25, params: { businessId }, request: { data: business } }) => (
    <UserPage pageSize={pageSize} businessId={businessId} business={business} />
  ))
);

export default withPageTitle(BusinessUsersPage, 'User Management');
