import { forwardRef, useLayoutEffect, useRef, useEffect, useState, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import Tippy from '@tippyjs/react';
import withField from '@components/Form/Fields/withField';
import { classNames } from '@utils';

const Selection = ({ selection, removeSelection }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    removeSelection(selection);
  };
  return (
    <span className="badge badge_primary mr-2">
      {selection.label}
      <button type="button" className="ml-1 la la-times" onClick={handleClick}></button>
    </span>
  );
};
const emptyFunc = () => {};
const Option = ({
  removeOption = emptyFunc,
  idx,
  setSelectionIdx,
  addOption = emptyFunc,
  active,
  option,
  selected,
  placeholder
}) => {
  const handleClick = () => {
      if (active) {
        removeOption(option);
      } else {
        addOption(option);
      }
    },
    handleMouseEnter = () => setSelectionIdx(idx);
  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      className={classNames(
        'item flex align-center justify-between',
        active && 'active',
        selected && 'selected',
        placeholder && 'placeholder'
      )}
    >
      <div>{placeholder ? 'No matching options' : option.label}</div>
      {active && <span className="la la-check" />}
    </div>
  );
};
export const SelectComponent = forwardRef(
  (
    { options = [], placeholder, onChange, name, value = [], placement = 'bottom-start', ...props },
    ref
  ) => {
    const [textFilter, setTextFilter] = useState(),
      [filteredOptions, setFilteredOptions] = useState(options),
      [isMenuOpen, setIsMenuOpen] = useState(),
      [selectionIdx, setSelectionIdx] = useState(0),
      addOption = (option, idx) => {
        setSelectionIdx(idx);
        onChange([...value, option]);
      },
      removeOption = (option) => {
        const idx = value.findIndex((o) => o.value === option.value);
        onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
      },
      handleTextFilterChange = (e) => setTextFilter(e.target.value),
      open = () => {
        setIsMenuOpen(true);
      },
      close = () => {
        setIsMenuOpen(false);
      },
      handleKeyPress = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          return false;
        }
      },
      handleKeyUp = (e) => {
        if (e.keyCode === 13) {
          const idx = value.find((o) => o.value === filteredOptions[selectionIdx].value);
          if (!value.find((o) => o.value === filteredOptions[selectionIdx].value)) {
            addOption(filteredOptions[selectionIdx], selectionIdx);
            close();
            setTextFilter('');
          }
          return handleKeyPress(e);
        } else if (e.keyCode === 38) {
          setSelectionIdx((prevIdx) => (prevIdx === 0 ? 0 : prevIdx - 1));
          e.stopPropagation();
          e.preventDefault();
        } else if (e.keyCode === 40) {
          setSelectionIdx((prevIdx) =>
            prevIdx < filteredOptions.length - 1 ? prevIdx + 1 : prevIdx
          );
          e.stopPropagation();
          e.preventDefault();
        }
      };

    useLayoutEffect(() => {
      setSelectionIdx(0);
      const lcTextFilter = textFilter?.toLowerCase();
      setFilteredOptions((prevFilteredOptions) =>
        textFilter
          ? prevFilteredOptions.filter(
              (option) =>
                option.label.substring(0, lcTextFilter.length).toLowerCase() === lcTextFilter
            )
          : options
      );
    }, [textFilter, options]);

    return (
      <div className="search-select">
        <Tippy
          content={
            <div className="search-select-menu">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, i) => (
                  <Option
                    addOption={addOption}
                    idx={i}
                    removeOption={removeOption}
                    setSelectionIdx={setSelectionIdx}
                    active={value?.find((o) => o.value === option.value) ? true : false}
                    selected={selectionIdx === i}
                    key={option.value || i}
                    option={option}
                  />
                ))
              ) : (
                <Option placeholder />
              )}
            </div>
          }
          theme="light-border"
          offset={[0, 8]}
          maxWidth="none"
          arrow={false}
          placement={placement}
          interactive
          allowHTML
          animation="shift-toward-extreme"
          trigger="manual"
          visible={isMenuOpen}
          onClickOutside={close}
        >
          <label className="form-control-addon-within flex-row-reverse" data-toggle="search-select">
            <input
              type="text"
              className="form-control pl-0 border-none w-full"
              placeholder={placeholder}
              value={textFilter}
              onChange={handleTextFilterChange}
              onFocus={open}
              onKeyUp={isMenuOpen ? handleKeyUp : undefined}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyPress}
            />
            <span className="flex items-center pl-4">
              {value?.map((selection) => (
                <Selection
                  key={selection.value}
                  selection={selection}
                  removeSelection={removeOption}
                />
              ))}
            </span>
          </label>
        </Tippy>
        <select value={value} ref={ref} className="hidden" name={name} />
      </div>
    );
  }
);

const SelectContainer = withField(({ name, ...props }) => (
  <Controller name={name} render={({ field }) => <SelectComponent {...field} {...props} />} />
));

export default SelectContainer;
