import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import withField from '@components/Form/Fields/withField';

const PhoneInput = forwardRef(({ onChange, ...props }, ref) => (
  <MaskedInput
    {...props}
    ref={ref}
    mask="(999) 999-9999"
    onChange={(e) => {
      e.persist();
      onChange(e.target.value.replace(/[^0-9]/g, ''));
    }}
  />
));

export default withField(({ as: PassedInputComponent = PhoneInput, name, ...props }) => (
  <Controller name={name} render={({ field }) => <PassedInputComponent {...field} {...props} />} />
));
