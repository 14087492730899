import { useState, useEffect } from 'react';
import { classNames } from '@utils';

export default function Animate({
  isShowing,
  className,
  animatedClassName,
  children,
  as: WrapperComponent = 'div',
  ...props
}) {
  const [show, setShow] = useState(false),
    [animated, setAnimated] = useState(false),
    handleTransitionEnd = () => {
      if (!isShowing) {
        setShow(false);
      }
    };

  useEffect(() => {
    if (isShowing) {
      setShow(true);
      setTimeout(() => {
        setAnimated(true);
      }, 20);
    } else {
      setAnimated(false);
    }
  }, [isShowing]);

  return (
    <>
      {show && (
        <WrapperComponent
          {...props}
          className={classNames(className, animated && animatedClassName)}
          onTransitionEnd={handleTransitionEnd}
        >
          {children}
        </WrapperComponent>
      )}
    </>
  );
}
