import { useEffect, useCallback } from 'react';
import styles from './index.module.css';
import closeIcon from '@assets/images/close-24px.svg';

const Toast = ({ delay, toast, remove }) => {
  const handleClose = useCallback(() => remove(toast.id), [toast, remove]);
  useEffect(() => {
    var timer = setTimeout(handleClose, delay);
    return () => clearTimeout(timer);
  }, [handleClose, delay]);
  return (
    <div className={styles.container} onClick={toast.onClick}>
      <div className={styles.text}>{toast.text}</div>
      <img src={closeIcon} alt="Close" onClick={handleClose} className={styles.closeIcon} />
    </div>
  );
};

export default Toast;
