import { forwardRef } from 'react';
import Table, { Column } from 'react-virtualized/dist/commonjs/Table';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import styles from './index.module.css';
import { classNames } from '@utils';
import 'react-virtualized/styles.css';

const LargeTable = forwardRef(
  (
    {
      cols,
      rows = [],
      rowCount = rows.length,
      emptyText = 'There is nothing here',
      noLink,
      rowRenderer = ({ className, index, key, rowData, style, columns }) => (
        <div key={key} style={style} className={classNames(className, 'tr')}>
          {columns}
        </div>
      ),
      list,
      minWidth = 1000,
      className,
      ...props
    },
    ref
  ) => (
    <AutoSizer>
      {(sizer) => {
        const width = sizer.width < minWidth ? minWidth : sizer.width;
        return (
          <Table
            className={classNames(
              'table w-full overflow-x-auto',
              !noLink && 'table_hoverable',
              list && 'table_list',
              className
            )}
            headerHeight={30}
            noRowsRenderer={() => <div>{emptyText}</div>}
            rowHeight={50}
            rowGetter={({ index }) => rows[index]}
            rowCount={rowCount}
            rowRenderer={rowRenderer}
            gridClassName="scrollbar"
            ref={ref}
            {...props}
            width={width}
            height={sizer.height}
          >
            {cols.map((col) => (
              <Column
                {...col}
                className="td"
                headerClassName="th"
                dataKey={col.key}
                width={width}
              />
            ))}
          </Table>
        );
      }}
    </AutoSizer>
  )
);

export default LargeTable;
