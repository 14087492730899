import { useState, useCallback, createContext, useContext } from 'react';
import useEventListener from '@containers/useEventListener';

const WidthStateContext = createContext();
export function WidthProvider({ children }) {
  const [width, setWidth] = useState(window.innerWidth);
  const handleSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);
  useEventListener('resize', handleSizeChange);
  return <WidthStateContext.Provider value={width}>{children}</WidthStateContext.Provider>;
}

export function useWidthState() {
  const context = useContext(WidthStateContext);
  if (context === undefined) {
    throw new Error('useWidthState must be used within a WidthProvider');
  }
  return context;
}

export default function withIsMobile(WrappedComponent, breakpoint) {
  return (props) => {
    const width = useWidthState();
    return <WrappedComponent {...props} isMobile={width < breakpoint} />;
  };
}
