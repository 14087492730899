import { classNames } from '@utils';
import Error from '@components/Form/Fields/Error';
import styles from './index.module.css';

const withField =
  (WrappedComponent, WrapperComponent = 'div', noForm, isSwitch) =>
  ({ label, className, onClick, secondary, ...props }) =>
    (
      <WrapperComponent
        className={classNames(
          !props.noMargin && 'mb-5 w-full',
          className,
          props.as === 'textarea' && styles.textarea
        )}
        onClick={onClick}
      >
        {label && (
          <label className="label block mb-2" htmlFor={props.name}>
            {label}
          </label>
        )}
        <div className={styles.inputWrapper}>
          <WrappedComponent {...props} className={isSwitch ? undefined : 'form-control'} />
          {!noForm && <Error name={props.name} className={styles.error} />}
        </div>
      </WrapperComponent>
    );

export default withField;
