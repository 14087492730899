import { useState } from 'react';
import Dropdown from '@components/Filter/Dropdown';

export const statusOptions = [
  { value: 'all', label: 'All Statuses' },
  { value: 'subscribed', label: 'Subscribed' },
  { value: 'signedup', label: 'Signed Up' },
  { value: 'inactive', label: 'Inactive' }
];
export const useStatusFilter = (initialState = statusOptions[0]) => {
  return useState(initialState);
};
const StatusFilter = ({ status, setStatus }) => (
  <Dropdown options={statusOptions} value={status} setValue={setStatus} />
);

export default StatusFilter;
