import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import withField from '@components/Form/Fields/withField';
import { capitalize } from '@utils';

const Input = forwardRef(({ onChange, ...props }, ref) => (
  <input
    {...props}
    ref={ref}
    onChange={(e) => {
      onChange(capitalize(e.target.value));
    }}
  />
));
export const AutocapitalizeInput = ({ name, ...props }) => (
  <Controller name={name} render={({ field }) => <Input {...field} {...props} />} />
);

const AutocapitalizeInputField = withField(AutocapitalizeInput);

export default AutocapitalizeInputField;
