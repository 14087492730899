import { InfiniteLoaderContainer } from '@components/Virtualized/InfiniteList';

const campaignTableCols = [
  {
    label: 'Name',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.name : ''),
    key: 'name'
  },
  {
    label: 'Short Link',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.short_url : ''),
    key: 'short_url'
  },
  {
    label: 'User Count',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.user_count : ''),
    key: 'user_count'
  },
  {
    label: 'Promotion Code',
    cellDataGetter: (cell) => (cell?.rowData ? cell.rowData.promo_code : ''),
    key: 'promo_code'
  }

];
const CampaignsList = ({ listAccessor = ({ campaigns }) => campaigns, ...props }) => (
  <InfiniteLoaderContainer {...props} listAccessor={listAccessor} cols={campaignTableCols} hideButton />
);

export default CampaignsList;
